import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { Step, Stepper } from "react-form-stepper";
import { getPermissionsFunc } from "../../../store/custom-actions/permissions-actions/GetPermissionsSlicer";
import faildImg from "../../../media/images/planet.png"
import { addRoleFunc } from "../../../store/custom-actions/roles-actions/AddRoleSlicer";
import { useNavigate, useParams } from "react-router-dom";
import PermissionCheckBox from "./PermissionCheckbox";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { getSingleRoleFunc } from "../../../store/custom-actions/roles-actions/GetSingleRoleSlicer";
import { updateRoleFunc } from "../../../store/custom-actions/roles-actions/UpdateRoleSlicer";

export default function UpdateRoleForm() {
    const {roleId} = useParams();
    const contextApi = useContext(ContextApi);
    const roles_api = `${contextApi.dashboard_URL_API}/roles`;
    const singleRole_api = `${contextApi.dashboard_URL_API}/roles/${roleId}`;
    const permissions_api = `${contextApi.dashboard_URL_API}/permissions`;
    const {register , handleSubmit , reset , formState : {errors} , setValue} = useForm();
    const dispatch = useDispatch();
    const {loading:getSingleRoleLoading , data:singleRoleData} = useSelector(
        state => state.getSingleRoleSlicer
    )
    const {loading:getPermissionsLoading , data:permissionsData} = useSelector(
        state => state.getPermissionsSlicer
    )
    const {loading:updateRoleLoading} = useSelector(
        state => state.updateRoleSlicer
    )
    const navigate = useNavigate();
    const [goSteps, setGoSteps] = useState(0);
    const [stepName , setStepName] = useState("Role");
    const [permissionsList , setPermissionsList] = useState([]);
    const [arrayWillSend , setarrayWillSend] = useState([])

    useEffect(() => {
        switch(goSteps) {
            case 0:
                setStepName("Role");
                break;
            case 1:
                setStepName("Role Permissions");
                break;
            default:
                setStepName("Role");
        }
    },[goSteps])
    
    useEffect(() => {
    const getSingleRole = async () => {
        dispatch(getSingleRoleFunc(singleRole_api));
    };
        getSingleRole();
    }, []);
    useEffect(() => {
        singleRoleData && setValue("name" , singleRoleData.name);
        const PermissionsIDList = singleRoleData && singleRoleData.permissions && singleRoleData.permissions.flatMap(permission => permission.id)
        singleRoleData && singleRoleData.permissions &&  setarrayWillSend(PermissionsIDList)
    }, [singleRoleData]);

    useEffect(() => {
        const getPermissions = async () => {
            dispatch(getPermissionsFunc(`${permissions_api}`));
        };
        getPermissions();
    }, []);

    useEffect(() => {
        permissionsData && setPermissionsList(permissionsData);
    }, [permissionsData]);


    const NameStep = () => {
        const [RoleName , setRoleName] = useState("");
        return (
            <>
                <div className={'row'}>
                    <div className="form-group mb-3 col-sm-12">
                        <label className="flex items-center">
                            <i className="fa-brands fa-cuttlefish mr-1"></i>
                            Role Name
                        </label>
                        {errors.name?.message && (
                        <p className="text-red-600">
                            {errors.name?.message}
                        </p>
                        )}
                        <input
                        {...register("name", {
                            required: "role name is required !"
                        })}
                        type="text"
                        className="form-control"
                        onChange={(e) => setRoleName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="text-end toolbar toolbar-bottom p-2">
                    <button 
                    className={`btn btn-primary sw-btn-next ms-1`} 
                    onClick={() => setGoSteps(1)}>Next</button>
                    {/* <button 
                    className={`btn btn-primary sw-btn-next ms-1 ${RoleName ? "" : "pointer-events-none opacity-50"}`} 
                    onClick={() => setGoSteps(1)}>Next</button> */}
                </div>
            </>
        )
    }

    const removingPermisssion = (arrayAfterRemoving) => {
        setarrayWillSend(arrayAfterRemoving)
      }
      const additionPermission = (permission) => {
        setarrayWillSend([...arrayWillSend , permission.id])
      }
    const handleSubmitData = async (data) => {
        if(data) {
            dispatch(updateRoleFunc(
                {
                    api : singleRole_api , 
                    data : {...data , permissions : [...arrayWillSend]}
                }
            ))
            .then (result => {
                if(result.payload.success === true) {
                    reset();
                    navigate("/roles")
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }

    useEffect(() => {
        console.log(arrayWillSend)
    },[arrayWillSend])
    return (
        <>
            <PageTitle motherMenu={"Roles"} activeMenu={"Update Role"} />
            {
                getSingleRoleLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">{stepName}</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-wizard ">
                                    <Stepper className="nav-wizard" activeStep={goSteps} label={false}>
                                        <Step className="nav-link" onClick={() => setGoSteps(0)} />
                                        <Step className="nav-link" onClick={() => setGoSteps(1)} />
                                    </Stepper>
                                    <form
                                        className="new-role-form"
                                        id="new-role-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitData(data);
                                        })}
                                    >
                                        {goSteps === 0 && (
                                            <>
                                                <NameStep/>
                                            </>
                                        )}
                                        {goSteps === 1 && (
                                            <>
                                                

                                        <div className='row mt-2'>
                                        {
                                            getPermissionsLoading
                                            ?
                                            <p className='my-1 text-center w-full relative'>Loading Permissions...</p>
                                            :
                                            (
                                                permissionsData
                                                ?
                                                (
                                                    permissionsList.length >= 1
                                                    ?
                                                    permissionsList.map((permission , index) => {
                                                    return (
                                                        <>
                                                        <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-3 flex items-center'>
                                                            <PermissionCheckBox
                                                                key={index}
                                                                permission = {permission}
                                                                arrayWillSend = {arrayWillSend}
                                                                removing = {removingPermisssion}
                                                                // additionDone = {additionPermission}
                                                                additionDone = {() => {
                                                                    setarrayWillSend([...arrayWillSend , permission.id]);
                                                                }}
                                                            />
                                                        </div>
                                                        </>
                                                    )
                                                    })
                                                    :
                                                    <p>There are no Permissions!</p>
                                                )
                                                :
                                                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                                    <img
                                                    src={faildImg}
                                                    alt="faild img"
                                                    className="w-[150px] mb-3"
                                                    />
                                                    <p>
                                                    Faild load Permissions!
                                                    <span
                                                        className="text-[#4d5fe3] cursor-pointer"
                                                        onClick={() =>
                                                            dispatch(getPermissionsFunc(`${permissions_api}`))
                                                        }
                                                    >
                                                        Try again
                                                    </span>
                                                    </p>
                                                </div>
                                            )
                                        }
                                        </div>


                                        <div className="text-end toolbar toolbar-bottom p-2">
                                            <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
                                            <button
                                            type="submit" 
                                            form="new-role-form"
                                            className={`btn btn-primary sw-btn-next ms-1`}>
                                                {
                                                    updateRoleLoading
                                                    ?
                                                    "loading.."
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </div>	
                                    </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}