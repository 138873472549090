import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getSubCategoriesFunc = createAsyncThunk(
    "get sub categories function",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.get(api , config);
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.subCategories;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
    }
)

const getSubCategoriesSlicer = createSlice({
    name : "get sub categories slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(getSubCategoriesFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(getSubCategoriesFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(getSubCategoriesFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default getSubCategoriesSlicer.reducer;