import React, { useState  , useEffect} from 'react';

const PermissionCheckBox = ({permission , arrayWillSend , removing , additionDone}) => {
  const [checked, setChecked] = useState();

  useEffect(() => {
    const already = arrayWillSend && arrayWillSend.find(oldId => oldId === permission.id);
    already ? setChecked(true) : setChecked(false)
  },[])
  useEffect(() => {
    console.log(arrayWillSend)
  },[arrayWillSend])
  const toggelChecked = (e) => {
    setChecked(!checked)
    if(e.checked) {
      console.log(permission.id)
      additionDone(permission.id)
    }else {
      const arrAfterRemoving = arrayWillSend && arrayWillSend.length >=1 && arrayWillSend.filter(oldId => oldId !== permission.id) 
      removing(arrAfterRemoving)
    }
  }
  return (
    <div className='cursor-pointer justify-start m-1 col-sm-12 flex items-center'>
      <input 
      checked = {checked}
      onChange={(e)=>{
        toggelChecked(e.target);
      }}
      type='checkbox' 
      className='cursor-pointer m-0' 
      id={permission.id}/>
      <label className='cursor-pointer flex items-center ml-1 mt-1' htmlFor={permission.id}>
        <p className='m-0'>{permission.name}</p>
      </label>
    </div>
  );
};

export default PermissionCheckBox;
