import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCouponsFunc } from "../../../store/custom-actions/coupons-actions/GetCouponsSlicer";
import { getSingleCouponFunc } from "../../../store/custom-actions/coupons-actions/GetSingleCouponSlicer";

export default function CouponUsersPage() {
    const {couponId} = useParams();
    const {loading:getSingleCouponLoading , data:singleCouponData} = useSelector(state => state.getSingleCouponSlicer);
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const singleCoupon_API_URL = `${contextApi.dashboard_URL_API}/coupons/${couponId}`;
    const dispatch = useDispatch();
    const nivigate = useNavigate();
    const[coupon , setCoupon] = useState({})
    const[couponUsers , setCouponUsers] = useState([])
    const[searchValue , setSearchValue] = useState("")

    useEffect(() => {
        const getCoupon = async () => {
          try {
            dispatch(getSingleCouponFunc(singleCoupon_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCoupon();
      }, []);
    useEffect(() => {
        singleCouponData && setCoupon(singleCouponData);
        singleCouponData && singleCouponData.coupon_usages && setCouponUsers(singleCouponData.coupon_usages);
    },[singleCouponData])
      const sort = 20;
      let paggination = Array(Math.ceil(couponUsers.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        couponUsers.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = couponUsers.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = couponUsers &&
          couponUsers.length >= 1 && couponUsers.filter((user) =>  user.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getSingleCouponFunc(singleCoupon_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getCouponsFunc(coupons_API_URL));
      },[searchValue])
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Coupon Users"} />
        {
            getSingleCouponLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                singleCouponData
                ?
                (
                    couponUsers && couponUsers.length >= 1
                    &&
                    <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Coupon Users</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Name</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>Limit</th>
                                  <th>Type</th>
                                  <th>Discount</th>
                                  <th>Users</th>
                                  {/* <th>Actions</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  jobData.current.map((coupon , index) => {
                                      return (
                                          <tr key={index}>
                                              <td>{coupon.id}</td>
                                              <td>{coupon.code}</td>
                                              <td>{coupon.start_date}</td>
                                              <td>{coupon.end_date}</td>
                                              <td>{coupon.count_used}</td>
                                              <td>{coupon.type}</td>
                                              <td>{coupon.discount}</td>
                                              <td>
                                                <Link
                                                      to="/coupon-users"
                                                      className="btn btn-danger shadow btn-xs sharp"
                                                  >
                                                    56
                                                </Link>
                                              </td>
                                              {/* <td>
                                                  <Link
                                                      to={`/update-coupon/${coupon.id}`}
                                                      className="btn btn-primary shadow btn-xs sharp me-1"
                                                  >
                                                      <i className="fas fa-pencil-alt"></i>
                                                  </Link>
                                              </td> */}
                                          </tr>
                                      )
                                  }) 
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {couponUsers.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : couponUsers.length}{" "}
                                of {couponUsers.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getSingleCouponFunc(singleCoupon_API_URL))}
                />
            )
        }
        </>
    )
}