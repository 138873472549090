import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ContextApi } from '../../../store/ContextApi';
import { getNewsLettersFunc } from '../../../store/custom-actions/news-letters-actions/GetNewsLettersSlicer';
import { addNewEmailFunc } from '../../../store/custom-actions/news-letters-actions/AddNewEmailSlicer';

function NewEmailForm({showForm , hideForm}) {
  const contextApi = useContext(ContextApi);
  const newsEmailsApi = `${contextApi.dashboard_URL_API}/newsletters`;
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:addNewEmailLoading} = useSelector(state => state.addNewEmailSlicer);
    const dispatch = useDispatch();
    const handleSubmitData = (data) => {
      dispatch(addNewEmailFunc({api : newsEmailsApi , data}))
      .then(result => {
          if(result.payload.success === true) {
            dispatch(getNewsLettersFunc(newsEmailsApi))
            hideForm();
            reset()
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
    // useEffect(() => {
    //   HandleErrorsListFunc(errors);
    // },[errors])
    
  return (
    <>
      <Modal show={showForm} onHide={hideForm}>
        <Modal.Header closeButton>
          <Modal.Title>New Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-email-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12">
                  <label>Person Email</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("email" , {required : "email should not be empty!"})}
                  type="email"
                  className="form-control"
                  />
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideForm}>
            Close
          </Button>
          <Button type="submit" form="new-email-form" className={`${addNewEmailLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!addNewEmailLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewEmailForm;