import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ContextApi } from "../../../store/ContextApi";

export default function UserOrdersTable({orders}) {
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    const sort = 10;
    let paggination = Array(Math.ceil(orders.length / sort))
      .fill()
      .map((_, i) => i + 1);
  
    const activePag = useRef(0);
    // const jobData = useRef(null)
    const [jobData , setJobData] = useState(null)
    useEffect(() => {
        if (orders) {
            setJobData(orders.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ))
        }
    }, [orders]);
    const onClick = (i) => {
      activePag.current = i;
      setJobData(orders.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      ))
    };
    const [searchValue , setSearchValue] = useState("")
    const search = () => {
      if(searchValue !== "") {
        const searchResult = orders &&
        orders.length >= 1 && orders.filter((order) =>  order.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
        console.log(searchResult);
        setJobData(searchResult)
      }else {
        orders && setJobData(orders);
      }
    }
    useEffect(() => {
      search()
    },[searchValue])

    const switchOrderStatus = (status) => {
        switch(status) {
          case 0:
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
          case 1:
            return (
              <span className="relative text-sm rounded-lg text-white bg-[#8bc34a] p-1">
                Active</span>
            );
          case 2:
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Delivery</span>
            );
          case 3:
            return (
              <span className="relative text-sm rounded-lg text-white bg-green-500 p-1">
                completed</span>
            );
          default :
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
            
        }
      }
    const switchPaymentStatus = (status) => {
        switch(status) {
          case "complete":
            return (
              <span className="relative text-sm rounded-lg text-white bg-green-500 p-1">
                completed</span>
            );
          case "pending":
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
          case "failed":
            return (
              <span className="relative text-sm rounded-lg text-white bg-red-500 p-1">
                Failed</span>
            );
          default :
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
            
        }
      }
    return (
        <>
            <div className="row">
            <div className="col-12">
                            <div className="card">
                            <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">User Orders</h4>
                            <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                    // onClick={() => search()
                                    // }
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                              <div className="w-100 table-responsive">
                                  <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                        <thead>
                                        <tr role="row">
                                            <th>Invoice No</th>
                                            <th>Total</th>
                                            <th>Payment Status</th>
                                            <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                jobData !== null && jobData.length >=1 ? jobData.map((order , index) => {
                                                
                                                return (
                                                    <tr key={index}>
                                                        <td>#{order.invoice_no}</td>
                                                        <td>{order.total_price} {site_global_currency}</td>
                                                        <td>{switchOrderStatus(order.stats)}</td>
                                                        <td>{switchPaymentStatus(order.payment_stats)}</td>
                                                    </tr>
                                                )
                                                }) 
                                                :
                                                <tr>
                                                  <td colSpan={4}>No Orders For This User</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                            
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                            <div className="dataTables_info">
                                            Showing {activePag.current * sort + 1} to{" "}
                                            {orders.length > (activePag.current + 1) * sort
                                                ? (activePag.current + 1) * sort
                                                : orders.length}{" "}
                                            of {orders.length} entries
                                            </div>
                                            <div
                                            className="dataTables_paginate paging_simple_numbers mb-0"
                                            id="example5_paginate"
                                            >
                                            <Link
                                                className="paginate_button previous disabled"
                                            //   to="/table-datatable-basic"
                                                to="#"
                                                onClick={() =>
                                                activePag.current > 0 && onClick(activePag.current - 1)
                                                }
                                            >
                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                            </Link>
                                            <span>
                                                {paggination.map((number, i) => (
                                                <Link
                                                    key={i}
                                                //   to="/table-datatable-basic"
                                                    to="#"
                                                    className={`paginate_button  ${
                                                    activePag.current === i ? "current" : ""
                                                    } `}
                                                    onClick={() => onClick(i)}
                                                >
                                                    {number}
                                                </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                            //   to="/table-datatable-basic"
                                                to="#"
                                                onClick={() =>
                                                activePag.current + 1 < paggination.length &&
                                                onClick(activePag.current + 1)
                                                }
                                            >
                                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                            </Link>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                </div>
                        </div>
            </div>
        
        </>
    )
}