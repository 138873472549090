import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { getSingleAdminFunc } from "../../../store/custom-actions/admins-actions/GetSingleAdminSlicer";
import { updateAdminImageFunc } from "../../../store/custom-actions/admins-actions/UpdateAdminImageSlicer";

export default function UpdateAdminImage() {
    const {adminId} = useParams();
    const contextApi = useContext(ContextApi);
    const singleAdminApi = `${contextApi.dashboard_URL_API}/admins/${adminId}/upload-image`;
    const { handleSubmit } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading:getSingleAdminLoading , data:singleAdminData} = useSelector(
        state => state.getSingleAdminSlicer
    )
    const {loading:updateAdminImageLoading} = useSelector(
        state => state.updateAdminImageSlicer
    )
    const [adminImage, setAdminImage] = useState("");
    const [imageViwer, setImageViwer] = useState("");
    useEffect(() => {
        const getSingleAdminData = async () => {
            dispatch(getSingleAdminFunc(singleAdminApi));
        };
        getSingleAdminData();
    }, []);
    useEffect(() => {
        if(singleAdminData) {
            setImageViwer(singleAdminData.image)
        }
      }, [singleAdminData]);

    const handleSubmitData = async (data) => {
        if(adminImage !== "") {
            const adminFormData = new FormData();
            adminFormData.append("image" , adminImage);
            adminFormData.append("_method" , "PUT");
            dispatch(updateAdminImageFunc(
                {
                    api : singleAdminApi , 
                    data : adminFormData
                }
            ))
            .then (result => {
                if(result.payload.success === true) {
                    // dispatch(getAdminsFunc(adminsApi));
                    navigate("/admins");
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            toast.error(`Admin Image Must Not be empty!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }


    return (
        <>
            <PageTitle motherMenu={"Admins"} activeMenu={"Update Admin Image"} />
            {
                getSingleAdminLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-wizard ">
                                    <form
                                        className="change-admin-image-form"
                                        id="change-admin-image-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitData(data);
                                        })}
                                    >
                                        {
                                            adminImage !== ""
                                            ?
                                            <div className="relative flex justify-center items-center mb-2">
                                                <div className="relative w-[240px] h-[200px] ">
                                                    <img src={imageViwer} alt="product img" className="absolute top-0 left-0 w-full h-full" />
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }
                                        <div className="w-full flex justify-center items-center mb-1">
                                            <span className="text-sm">best size (200px * 180px)</span>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <label for="file" className="file-upload-label">
                                                <div className="file-upload-design">
                                                <svg viewBox="0 0 640 512" height="1em">
                                                    <path
                                                    d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                                                    ></path>
                                                </svg>
                                                <span className="browse-button">Browse file</span>
                                                </div>
                                                <input 
                                                    id="file" 
                                                    type="file" 
                                                    accept="image/jpeg, image/png, image/jpg"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const imageUrl = URL.createObjectURL(file);
                                                            setImageViwer(imageUrl); 
                                                        }                        
                                                        setAdminImage(e.target.files[0]);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div className="text-end toolbar toolbar-bottom p-2">
                                            <button 
                                            type="submit"
                                            form="change-admin-image-form"
                                            className={`btn btn-primary sw-btn-next ms-1 ${updateAdminImageLoading || adminImage === "" ? "pointer-events-none opacity-50" : ""}`}
                                            >
                                                {
                                                    updateAdminImageLoading
                                                    ?
                                                    "loading.."
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </div>	
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}