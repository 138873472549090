import emptyImage from "../../../media/images/emptystate.svg";
export default function EmptyData({emptyText}) {
    return (
        <>
            <div className="relative flex flex-col justify-center items-center pt-2 mt-3">
                <img src={emptyImage} alt="empty" className="" />
                <p className="mt-2 text-slate-500">{emptyText ? emptyText : "No Data!"}</p>
            </div>
        </>
    )
}