import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {useContext, useEffect, useState} from 'react';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { toast } from 'react-toastify';
import { updateSubCategoryFunc } from '../../../store/custom-actions/sub-categories-actions/UpdateSubCategorySlicer';
import { getSubCategoriesFunc } from '../../../store/custom-actions/sub-categories-actions/GetSubCategoriesSlicer';
import { ContextApi } from '../../../store/ContextApi';
import { getLanguagesFunc } from '../../../store/custom-actions/language/GetLanguagesSlicer';

function UpdateSubCategoryForm({showUpdateCategoryForm , hideUpdateCategoryForm , subCategoriesApi , categoryName , categoryId , categoryActivaty , mainCateoryId , translations}) {
    const contextApi = useContext(ContextApi)
    const {register , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:updateSubCategoryloading} = useSelector(state => state.updateSubCategorySlicer);
    const dispatch = useDispatch();
    const [activaty , setActivaty] = useState();
    // const [categoryImage , setCategoryImage] = useState("");
    // const [imageViewr , setImageViewr] = useState("");
    const {loading:getLanguagesLoading , data:languagesData} = useSelector(state => state.getLanguageSlicer)
    const languages_API_URL = `${contextApi.dashboard_URL_API}/languages`;
    const [languagesList , setLanguagesList] = useState([]);

    useEffect(() => {
        const getLanguages = async () => {
          try {
            dispatch(getLanguagesFunc(languages_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        showUpdateCategoryForm && getLanguages();
      }, [showUpdateCategoryForm]);
      useEffect(() => {
        if(languagesData) {
          setLanguagesList(languagesData)
        }
      },[languagesData])
    useEffect(() => {
      // setValue("name" , categoryName);
      setActivaty(categoryActivaty);
      if(translations) {
        for(let i=0; i < translations.length; i++) {
          setValue(translations[i].locale , translations[i].name)
        }
      }
      // setCategoryImage(categoryImg);
      // setImageViewr(categoryImg);
    },[setValue , categoryName , categoryActivaty , translations])
    const handleSubmitData = (data) => {
      console.log(data)
      // const categoryForm = new FormData();
      // categoryImage !== ""  && categoryForm.append("image" , categoryImage);
      // categoryForm.append("name" , data.name);
      // categoryForm.append("active" , activaty);
      // categoryForm.append("category_id" , mainCateoryId);
      // categoryForm.append("_method" , "PUT");
      const objWillSend = {};
      for(const [key , value] of Object.entries(data)) {
        const langObj = {
          [key] : {
            name : value
          }
        }
        Object.assign(objWillSend , langObj)
      }
        dispatch(updateSubCategoryFunc({api : `${subCategoriesApi}/${categoryId}` , data :{...objWillSend , category_id: mainCateoryId , active: activaty}}))
        .then(result => {
            if(result.payload.success === true) {
            dispatch(getSubCategoriesFunc(subCategoriesApi));
            hideUpdateCategoryForm();
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
            }
        })
    }
  useEffect(() => {
    HandleErrorsListFunc(errors);
  },[errors])
  return (
    <>
      <Modal show={showUpdateCategoryForm} onHide={hideUpdateCategoryForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update On <span className='text-orange-700'>{categoryName}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form 
            id="update-category-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitData(data);
            })}
            >
              {
                getLanguagesLoading
                ?
                <p>Loading Languages..</p>
                :
                (
                  languagesData
                  ?
                  (
                    languagesList && languagesList.length >= 1
                    ?
                    languagesList.map((lang , index) => (
                    <div className="form-group mb-3 col-sm-12" key={index}>
                      <label>{`${lang.name} Name`}</label>
                      {errors[lang.slug]?.message && (
                      <p className="text-red-600">{errors[lang.slug]?.message}</p>
                      )}
                      <input
                        {...register(lang.slug , {required : `${lang.name} name should not be empty!`})}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    ))
                    :
                    <p>No Languages!</p>
                  )
                  :
                  <p>Faild Load Languages!</p>
                )
              }
              <div className='row'>
                  {/* <div className="form-group mb-3 col-sm-12">
                      <label>Category Name</label>
                      {errors.name?.message && (
                          <p className="text-red-600">{errors.name?.message}</p>
                      )}
                      <input
                      {...register("name", {required : "name should not be empty!"})}
                      type="text"
                      className="form-control"
                      />
                  </div> */}
                  {/* {
                      imageViewr !== ""
                      ?
                      <div className="relative flex justify-center items-center mb-2">
                          <div className="relative w-[240px] h-[200px]">
                              <img src={imageViewr} alt="ategory img" className="absolute top-0 left-0 w-full h-full" />
                          </div>
                      </div>
                      :
                      ""
                  }
                  <div className="form-group mb-3 col-sm-12">
                    <label>Category Image</label>
                    <input
                    type="file"
                    className="form-control"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      file && setCategoryImage(file);
                      const imageUrl = URL.createObjectURL(file);
                      setImageViewr(imageUrl)
                    }}
                    />
                  </div>*/}
                  <div className="form-group mb-3 col-sm-12">
                    <input
                    id="cat-active"
                    type="checkbox"
                    className="m-0 mr-2 translate-y-1"
                    value={activaty}
                    checked = {activaty ? true : false}
                    onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                    />
                    <label htmlFor='cat-active' className='cursor-pointer'>Activaty</label>
                  </div>
              </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideUpdateCategoryForm}>
            Close
          </Button>
          <Button type="submit" form="update-category-form" className={`${updateSubCategoryloading ? "pointer-events-none opacity-80" : ""}`}>
          {!updateSubCategoryloading 
          ? (
            "Update"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateSubCategoryForm;