import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getAppLanguageFunc = createAsyncThunk(
    "get app language function",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.get(api , config);
            return response.data.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const getAppLanguageSlicer = createSlice({
    name : "get app language slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getAppLanguageFunc.pending , (state) => {
            state.loading = true
            state.data = false
            state.error = false
        });
        builder.addCase(getAppLanguageFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        });
        builder.addCase(getAppLanguageFunc.fulfilled , (state , action) => {
            state.loading = null
            state.data = action.payload
            state.error = null
        });
    }
});

export default getAppLanguageSlicer.reducer;