import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext , useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';
import { updateUserFunc } from '../../../store/custom-actions/users-actions/UpdateUserSlicer';
import { getUsersFunc } from '../../../store/custom-actions/users-actions/GetUsersSlicer';

export default function ChangeUserPassForm({show , onHide , userId}) {
    const {register , reset , formState : {errors} , handleSubmit } = useForm();
    const {loading:changePassLoading} = useSelector(state => state.updateUserSlicer)
    const contextApi = useContext(ContextApi);
    const users_API_URL = `${contextApi.dashboard_URL_API}/users`;
    const user_API_URL = `${contextApi.dashboard_URL_API}/users/${userId}`;
    const dispatch = useDispatch();
    const [newPass , setNewPass] = useState("");
    const [confirmation , setConfirmation] = useState("");

    const handleSubmitData = (data) => {
      if(newPass !== confirmation) {
        toast.error(`Not Confirmation New Pass !`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
      })
      }else {
        dispatch(updateUserFunc({api : user_API_URL , data}))
        .then(result => {
            if(result.payload.success === true) {
              const successMessage = result.payload.message;
              dispatch(getUsersFunc(users_API_URL))
              onHide();
              reset();
              setNewPass("")
              setConfirmation("")
              toast.success(`${successMessage} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
          }
        })
      }
    }
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="change-user-pass"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
            <div className='row'>
              <div className="form-group mb-3 col-sm-12 col-md-6">
                  <label>New Password</label>
                  {errors.new_password?.message && (
                  <p className="text-red-600">{errors.new_password?.message}</p>
                  )}
                  <input
                  
                  {...register("new_password" , {required : "new password should not be empty!"})}
                  type="password"
                  className="form-control"
                  onChange={(e) => setNewPass(e.target.value)}
                  value={newPass}
                  />
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-6">
                  <label>confirm New Password</label>
                  {errors.new_password_confirmation?.message && (
                  <p className="text-red-600">{errors.new_password_confirmation?.message}</p>
                  )}
                  <input
                  
                  {...register("new_password_confirmation" , {required : "Confirm should not be empty!"})}
                  type="password"
                  className="form-control"
                  onChange={(e) => setConfirmation(e.target.value)}
                  value={confirmation}
                  />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="change-user-pass" className={`${changePassLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!changePassLoading ? (
            "Change"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}