import "./sliders-page.css"
import PageTitle from "../../layouts/PageTitle";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getSlidersFunc } from "../../../store/custom-actions/sliders/GetSlidersSlicer";
import { deleteSliderFunc } from "../../../store/custom-actions/sliders/DeleteSliderSlicer";
import { ContextApi } from "../../../store/ContextApi";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import UpdateSliderForm from "../../custom-forms/settings-pages/sliders-settings/UpdateSliderForm";
import ShowImageModal from "../../components/show-image/ShowImageModal";

function SlidersPage() {
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/sliders`;
    const dispatch = useDispatch();
    const [slidersList , setSlidersList] = useState([]);
    const [showImage , setShowImage] = useState();
    const [image , setImage] = useState();
    const [showUpdateSliderForm , setShowUpdateSliderForm] = useState();
    const [oldSliderData , setOldSliderData] = useState();
    const {loading:getSlidersLoading , data:slidersData} = useSelector(
        state => state.getSlidersSlicer
    );
    const {loading:deleteSliderLoading} = useSelector(
        state => state.deleteSliderSlicer
    );
    const getSliders = async () => {
        dispatch(getSlidersFunc(ui_settings_URL_APi))
    }
    useEffect(() => {
        getSliders();
    },[])
    useEffect(() => {
        slidersData && setSlidersList(slidersData)
    },[slidersData])

    const deleteSliderFunction = async (sliderId) => {
        swal({
          title: "Confirm Slider Deletion",
          text: `Once deleted, Slider will not be avilable in user intrface!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteSliderFunc(`${ui_settings_URL_APi}/${sliderId}`))
            .then((result) => {
              if(result.payload.success === true){
                getSliders();
                toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
              }
            })
          }
        });
    };

    return (
        <>
            <PageTitle motherMenu={"Home"} activeMenu={"Ui Sliders"} customClasses={"my-0"}/>
            <div className="new-slider-container relative flex items-center justify-end p-2 my-3">
                <Link to="/new-slider" className="btn btn-primary">New Slider</Link>
            </div>
            <div className="table-responsive">
                <div
                id="student_wrapper"
                className="dataTables_wrapper no-footer"
                >
                <table
                    className="table display mb-4 dataTablesCard order-table card-table text-black application "
                    id="application-tbl1_next"
                >
                    <thead>
                    <tr>
                        <th className="text-[#4d5fe3]">ID</th>
                        <th className="text-[#4d5fe3]">Title</th>
                        <th className="text-[#4d5fe3]">Description</th>
                        <th className="text-[#4d5fe3]">Image</th>
                        <th className="text-[#4d5fe3]">Mobile Image</th>
                        <th className="text-[#4d5fe3]">Creation</th>
                        <th className="text-[#4d5fe3]">Last Update</th>
                        <th className="text-[#4d5fe3] text-end">
                        Decision
                        </th>
                    </tr>
                    </thead>
                    {
                    getSlidersLoading 
                    ?
                    <>
                    <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                    </>
                    :
                    <tbody>
                        {slidersData &&
                        slidersList.length > 0 ?
                        slidersList.map((slider, index) => {
                            const creationDate = new Date(slider.created_at)
                            const creationYear = creationDate.getFullYear();
                            const creationMonth = creationDate.getMonth()+1;
                            const creationDay = creationDate.getDate();
                            const latestUpdateDate = new Date(slider.updated_at)
                            const latestUpdateYear = latestUpdateDate.getFullYear();
                            const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                            const latestUpdateDay = creationDate.getDate();
                            return (
                            <tr key={index}>
                                <td className="font-bold text-[#4d5fe3]">
                                #ID:{slider.id}
                                </td>
                                <td className="">
                                    {slider.title}
                                </td>
                                <td>
                                    {
                                        slider.description
                                        &&
                                        slider.description.length >= 20
                                        ?
                                        <div className="m-0 slider-desc" dangerouslySetInnerHTML={{__html : `${slider.description.slice(0,20)}...`}}/>
                                        :
                                        <div className="m-0 slider-desc" dangerouslySetInnerHTML={{__html : slider.description}}/>
                                    }
                                </td>
                                <td className="">
                                    <img src={slider.image_url} className="relative w-[50px] h-[30px] cursor-pointer rounded-md hover:scale-105 transition-all duration-500" alt="img" onClick={() => {
                                        setShowImage(true)
                                        setImage(slider.image_url)
                                    }}/>
                                </td>
                                <td className="">
                                    <img src={slider.mobile_image_url} className="relative w-[50px] h-[30px] cursor-pointer rounded-md hover:scale-105 transition-all duration-500" alt="img" onClick={() => {
                                        setShowImage(true)
                                        setImage(slider.mobile_image_url)
                                    }}/>
                                </td>
                                <td>
                                {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                </td>
                                <td className="">
                                {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                </td>
                                <td className="">
                                <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                    onClick={() => {
                                        deleteSliderFunction(slider.id)
                                    }}
                                >
                                    <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
                                    >
                                        <i className="fa-solid fa-trash text-white"></i>
                                        <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                        >Delete</span>
                                    </div>
                                </button>
                                <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                    onClick={() => {
                                        setOldSliderData(slider);
                                        setShowUpdateSliderForm(true)
                                    }}
                                >
                                    <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
                                    >
                                        <i className="fa-solid fa-pen-to-square text-white"></i>
                                    </div>
                                </button>
                            </td>
                            </tr>
                        );
                        })
                        :
                        <td colSpan={4} className="flex flex-col justify-center items-center">
                                {/* <img src={noSlidersImg} alt="no subscription" className="w-[50px] border-none"/> */}
                                <p className="m-0 border-none">No Sliders !</p> 
                        </td>
                        }
                    </tbody>
                    }
                </table>
                </div>
            </div>
            <UpdateSliderForm
                show = {showUpdateSliderForm}
                onHide = {() => setShowUpdateSliderForm(false)}
                old_slider_data = {oldSliderData}
                refetch_data = {() => getSliders()}
            />
            <ShowImageModal
                show = {showImage}
                onHide = {() => setShowImage(false)}
                image = {image}
            />
            <LoadingScreen loading={deleteSliderLoading}/>
        </>
    )
}


export default SlidersPage;