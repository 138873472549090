import { useForm } from "react-hook-form";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import { useContext } from "react";
import { getCouponsFunc } from "../../../store/custom-actions/coupons-actions/GetCouponsSlicer";
import { toast } from "react-toastify";
import { addCouponFunc } from "../../../store/custom-actions/coupons-actions/AddCouponSlicer";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NewCouponForm() {
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:addCouponLoading} = useSelector(state => state.addCouponSlicer);
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const dispatch = useDispatch();
    const nivigate = useNavigate();
    const handleSubmitData = (data) => {
        if(data.start_date > data.end_date) {
            toast.error(`End Date Must be More than Start Date!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }else {
            dispatch(addCouponFunc({api : coupons_API_URL , data : {...data}}))
            .then(result => {
                if(result.payload.success === true) {
                  const successMessage = result.payload.message;
                  dispatch(getCouponsFunc(coupons_API_URL));
                  reset();
                  nivigate("/coupons");
                  toast.success(`${successMessage}!`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                  })
              }
          })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Dashboard"} activeMenu={"New Coupon"} />
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <form
                            id="new-coupon-form"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitData(data)
                            })}
                        >
                            <div className='row'>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label>Code</label>
                                    {errors.code?.message && (
                                    <p className="text-red-600">{errors.code?.message}</p>
                                    )}
                                    <input
                                    
                                    {...register("code" , {required : "Code should not be empty!"})}
                                    type="text"
                                    className="form-control"
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label>Discount</label>
                                    {errors.discount?.message && (
                                    <p className="text-red-600">{errors.discount?.message}</p>
                                    )}
                                    <input
                                    
                                    {...register("discount" , {required : "Discount should not be empty!"})}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label>Usage Limit</label>
                                    {errors.count_used?.message && (
                                    <p className="text-red-600">{errors.count_used?.message}</p>
                                    )}
                                    <input
                                    
                                    {...register("count_used" , {required : "Usage limit should not be empty!"})}
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    />
                                </div>
                                <div
                                    className={`form-group mb-3 col-sm-12 col-md-6`}
                                >
                                    <label>Discount Type</label>
                                    {errors.type?.message && (
                                    <p className="text-red-600">{errors.type?.message}</p>
                                    )}
                                    <select
                                        className={`form-control col-sm-12`}
                                        {...register("type" , {required : "Discount Type limit should not be empty!"})}
                                    >
                                        <option value={"fixed"}>Fixed</option>
                                        <option value={"percentage"}>Percentage</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label>From :</label>
                                    {errors.start_date?.message && (
                                    <p className="text-red-600">{errors.start_date?.message}</p>
                                    )}
                                    <input
                                    
                                    {...register("start_date" , {required : "Start Date should not be empty!"})}
                                    type="date"
                                    className="form-control"
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label>To :</label>
                                    {errors.end_date?.message && (
                                    <p className="text-red-600">{errors.end_date?.message}</p>
                                    )}
                                    <input
                                    
                                    {...register("end_date" , {required : "End Date should not be empty!"})}
                                    type="date"
                                    className="form-control"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <Button type="submit" form="new-coupon-form" className={` form-control ${addCouponLoading ? "opacity-80 pointer-events-none" : ""} w-fit`} style={{width : "fit-content"}}>
                                    {!addCouponLoading ? (
                                    "Submit"
                                    ) : (
                                    <>
                                        <div className="relative flex justify-center items-center">
                                        loading..
                                        </div>
                                    </>
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </>
    )
}