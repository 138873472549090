import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
	const {data:mainData} = useSelector(
		state => state.getHomeDataSlicer
	  )
	return (
		<div className="footer">
			<div className="copyright">
				{/* <p>Copyright © Designed &amp; Developed by{" "}
					<a href="http://dexignzone.com/" target="_blank"  rel="noreferrer">
						Topgrowth
					</a>{" "}
					{d.getFullYear()}
				</p> */}
				<div className="flex justify-center" dangerouslySetInnerHTML={{__html : mainData ? mainData.site_footer_copyright : "No Data"}} />
			</div>
		</div>
	);
};

export default Footer;
