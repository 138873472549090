import ReactQuill from "react-quill";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import "./ui-settings-page.css"
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { ContextApi } from "../../../../store/ContextApi";
import { updateSliderFunc } from "../../../../store/custom-actions/sliders/UpdateSliderSlicer";
import { toast } from "react-toastify";
function UpdateSliderForm(props) {
    const {refetch_data , old_slider_data} = props;
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/sliders`;
    const dispatch = useDispatch();
    const [sliderId , setSliderId] = useState();
    const [sliderImg , setSliderImg] = useState();
    const [sliderMobileImg , setSliderMobileImg] = useState();
    const [sliderDescription , setSliderDescription] = useState();
    const {register , setValue , formState : {errors} , handleSubmit , reset} = useForm();
    const {loading:updateSliderLoading} = useSelector(
        state => state.updateSliderSlicer
    );

    useEffect(() => {
        if(old_slider_data) {
            setSliderDescription(old_slider_data.description)
            setSliderImg(old_slider_data.image);
            setSliderMobileImg(old_slider_data.mobile_image);
            setSliderId(old_slider_data.id);
            setValue("link" , old_slider_data.link)
            setValue("title" , old_slider_data.title)
        }
    },[old_slider_data , setValue])
  const handleUpdateSlider = async (data) => {
    if(data) {
        if(sliderDescription !== "") {
            const sliderFormData = new FormData();
            sliderImg && sliderFormData.append("image" , sliderImg);
            sliderMobileImg && sliderFormData.append("mobile_image" , sliderMobileImg);
            sliderFormData.append("title" , data.title);
            sliderFormData.append("description" , sliderDescription);
            sliderFormData.append("link" , data.link);
            sliderFormData.append("_method" , "PUT");
            dispatch(updateSliderFunc({api : `${ui_settings_URL_APi}/${sliderId}` , data : sliderFormData}))
            .then(result => {
                if(result.payload.success === true) {
                    reset();
                    setSliderDescription("");
                    setSliderImg("");
                    refetch_data();
                    props.onHide();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            Swal.fire({
                title: "Oops!",
                text: "Slider Description Shoud not be empty!",
                icon: "warning"
            });
        }
    }
}

const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
    '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
];
const modules = {
    toolbar: {
    container: [
    [{"color" : colors}],
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['clean']
    ],
}
};
    const imagesTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
    const checkImageSize = (e , setFunc) => {
        const image = e.target.files[0];
        const imageSize = image.size / 1024 / 1024;
        const maxSize = 100
        if(imageSize > maxSize) {
            Swal.fire({
                title: "Size Validation!",
                text: "Max Size Of Slider Image Is 100 megabytes!",
                icon: "warning"
            });
            // resetFunc();
            e.target.value = ''
        }else {
            if(!imagesTypes.includes(image.type)) {
                Swal.fire({
                    title: "Type Validation!",
                    text: "Please Choose a Valid Image Type!",
                    icon: "warning"
                });
            }else {
                setFunc();
            }
        }
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter flex items-center">
          <i className="fa-brands fa-discourse mr-1"></i>
          Update Slider
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="relative settings-form-content">
            <div className="new-lesson-form-div relative w-full ">
                <form 
                    className="update-slider-form"
                    id="update-slider-form"
                    onSubmit={handleSubmit((data) => {
                        handleUpdateSlider(data)
                    })}
                >
                    {
                        <>
                            <div className={'row'}>
                                <div className="form-group logo-input mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                        Slider Title
                                    </label>
                                    {errors.title?.message && (
                                    <p className="text-red-600">
                                        {errors.title?.message}
                                    </p>
                                    )}
                                    <input
                                        {...register("title" , {required : "titel is required"})}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-link mr-1"></i>
                                        Path Link
                                    </label>
                                    {errors.link?.message && (
                                    <p className="text-red-600">
                                        {errors.link?.message}
                                    </p>
                                    )}
                                    <input
                                    {...register("link" , {required : "path link is required"})}
                                    type="text"
                                    className="form-control"
                                    />
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                        Slider Image
                                    </label>
                                    <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                        checkImageSize(
                                            e , 
                                            () => setSliderImg(e.target.files[0]),
                                            // () => setSliderImg("")
                                        )
                                    }}
                                    // value = {sliderImg}
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                        Slider Mobile Image
                                    </label>
                                    <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                        checkImageSize(
                                            e , 
                                            () => setSliderMobileImg(e.target.files[0]),
                                            // () => setSliderMobileImg("")
                                        )
                                    }}
                                    // value = {sliderMobileImg}
                                    />
                                </div>
                            </div>
                            <div className="slider-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef]  after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <div className="row">
                                <ReactQuill 
                                modules = {modules}
                                className="p-0 border-none outline-none form-control bg-white mt-3" 
                                value={sliderDescription} theme="snow" 
                                onChange={(content) => setSliderDescription(content)} />
                            </div>
                            {/* <div className="more-about-platform-line relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['More About Platform'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <div className="row">
                                <ReactQuill 
                                modules = {modules}
                                className="p-0 border-none outline-none form-control bg-white mt-3" 
                                value={moreAbout} theme="snow" 
                                onChange={(content) => setMoreAbout(content)} />
                            </div> */}
                        </>
                    }
                </form>
            </div>
            </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
          reset();
        }}>Close</Button>
        <Button type="submit" form="update-slider-form">
          {!updateSliderLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateSliderForm;