import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addNewSubCategoryFunc = createAsyncThunk(
    "add new sub category function",
    async ({api , data}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                // "Content-Type" :  "multipart/form-data",
            }
        }
        try {
            const response = await axios.post(api , data , config);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const addSubCategorySlicer = createSlice({
    name : "add new sub category slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewSubCategoryFunc.pending , (state) => {
            state.loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(addNewSubCategoryFunc.rejected , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = true
        });
        builder.addCase(addNewSubCategoryFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default addSubCategorySlicer.reducer;