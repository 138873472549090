import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { getCountriesFunc } from '../../../store/custom-actions/countries-actions/GetContriesSlicer';
import { toast } from 'react-toastify';
import { updateCountryFunc } from '../../../store/custom-actions/countries-actions/UpdateCountrySlicer';

function UpdateCountryForm({show , onHide , countryId , oldName , oldCode , oldStatus}) {
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:updateCountryLoading} = useSelector(state => state.updateCountrySlicer);
    const contextApi = useContext(ContextApi);
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const country_API_URL = `${contextApi.dashboard_URL_API}/countries/${countryId}`;
    const[Activaty , setActivaty] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        setValue("name" , oldName);
        setValue("code" , oldCode);
        setActivaty(oldStatus)
      },[setValue , oldName , oldCode , oldStatus])
    const handleSubmitData = (data) => {
      dispatch(updateCountryFunc({api : country_API_URL , data : {...data , active : Activaty}}))
      .then(result => {
          if(result.payload.success === true) {
            const successMessage = result.payload.message;
            dispatch(getCountriesFunc(countries_API_URL))
            onHide();
            reset();
            toast.success(`✔️ ${successMessage} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
      })
    }
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Country</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="update-country-from"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12 col-md-8">
                  <label>Country</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("name" , {required : "name should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-4">
                  <label>Code</label>
                  {errors.code?.message && (
                  <p className="text-red-600">{errors.code?.message}</p>
                  )}
                  <input
                  
                  {...register("code" , {required : "Code should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
          </div>
          <div className='row'>
              <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                  <input
                //   id="cat-active"
                  type="checkbox"
                  id="customCheckBox5"
                  className="m-0 mr-3 form-check-input cursor-pointer"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  checked = {Activaty === 1 ? true : false}
                  />
                  <label htmlFor='customCheckBox5' className='cursor-pointer ml-1'>Activaty</label>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="update-country-from" className={`${updateCountryLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!updateCountryLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCountryForm;