export default function checkPermissions( targetPermission , element , insteadPage) {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const permissionsList = user && user.permissions;
    const userRoleName = user && user.roleName && user.roleName.toLowerCase();
    if(!permissionsList) {
        return element
    }else {
        const find = permissionsList.find(permission => permission.name.toLowerCase() === targetPermission);
        if(find || userRoleName === "super admin") {
            return element;
        }else {
            if(insteadPage){
                return insteadPage;
            } 
        }
    }
}