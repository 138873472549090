import MessageRow from "./MessageRow";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import noMessagesImg from "../../../media/images/no-message.png"
import swal from "sweetalert";
import { getContactsFunc } from "../../../store/custom-actions/contact-actions/GetContactsSlicer";
import { deleteContactFunc } from "../../../store/custom-actions/contact-actions/DeleteContactSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { ContextApi } from "../../../store/ContextApi";
function MessagesPage() {
    // const loading = false
    const dispatch = useDispatch();
    const [contacts , setContacts] = useState([]);
    const contextApi = useContext(ContextApi);
    const [unReadMessages , setUnReadMessages] = useState([]);
    const contacts_url_api = `${contextApi.dashboard_URL_API}/contacts`;
    const {loading , data} = useSelector(state=>state.getContactsSlicer)
    const {loading:deleteLoading} = useSelector(state=>state.deleteContactSlicer)

    const getContacts = async () => {
      dispatch(getContactsFunc(contacts_url_api))
      .then(result => console.log(result))
    }

    useEffect(()=>{
      getContacts()
    },[])

    useEffect(()=>{
      data && setContacts(data)
      const notReadingMessages = data && contacts.length >= 1 && contacts.filter(message => message.is_read === 0);
      setUnReadMessages(notReadingMessages)
    },[data , contacts])


    const deleteMessage = (messageId) => {
        swal({
          title: "Confirm Message Deletion",
          text: `Once deleted, will not be Avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteContactFunc(`${contacts_url_api}/${messageId}`))
            .then(result => {
              if(result.payload.success === true) {
                getContacts()
              }
            });
          } else {
            swal("Your Message is safe!");
          }
        });
    }
    return (
        <>
        
            {
                loading
                ?
                (
                  <SpinnerLoading/>
                )
                :
                (
                <>
                    <div className="col-xl-12">
                      <div className="card students-list">
                        <div className="contacts-header flex items-center">
                          <div className="card-header border-0 flex-wrap pb-0">
                            <h4 className="header-text m-0 flex items-center">
                              <i className="fa-solid fa-comments mr-1"></i>
                              Users Messages
                            </h4>
                          </div>
                          <div className="relative flex items-center card-header border-0 flex-wrap pb-0">
                              not readable Messages <span className="text-black flex ml-1"> ({unReadMessages && unReadMessages.length >= 1 ? unReadMessages.length : 0})</span>
                          </div>
                        </div>
                        <div className="card-body py-0">
                          <div className="table-responsive">
                            <div
                              id="student_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              {
                              data && contacts.length >= 1 ?
                              <table
                                className="table contacts-table display mb-4 dataTablesCard order-table card-table text-black application "
                                id="application-tbl1_next"
                              >
                                <thead>
                                  <tr>
                                    <th className="text-[#4d5fe3]">Name</th>
                                    <th className="text-[#4d5fe3]">Email</th>
                                    <th className="text-[#4d5fe3]">Phone</th>
                                    <th className="text-[#4d5fe3]">Message Date</th>
                                    <th className="text-[#4d5fe3]">State</th>
                                    <th className="text-[#4d5fe3]">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contacts &&
                                    contacts.length > 0 &&
                                    contacts.map((message, index) => {
                                        const date = new Date(message.created_at)
                                        const year = date.getFullYear();
                                        const month = date.getMonth()+1;
                                        const day = date.getDate();
                                        return (
                                            <MessageRow
                                                key={index}
                                                name={message.name}
                                                email={message.email}
                                                phone={message.phone}
                                                subject={message.subject}
                                                messageContent={message.message}
                                                deleteMessageFunc={()=>deleteMessage(message.id)}
                                                messageId = {message.id}
                                                refetchContacts = {() => getContacts()}
                                                readingStatus = {message.is_read}
                                                messageDate = {`${day} / ${month} / ${year}`}
                                            />
                                        )
                                        })}
                                </tbody>
                              </table>
                              :
                              <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                <img
                                  src={noMessagesImg}
                                  alt="faild img"
                                  className="w-[150px] mb-3"
                                />
                                <p>
                                  No Messages Yet!
                                  <span
                                    className="text-[#4d5fe3] cursor-pointer"
                                    onClick={() =>
                                      window.history.back()
                                    }
                                  >
                                    back
                                  </span>
                                </p>
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </>
                )
            }
            <LoadingScreen loading={deleteLoading}/>
        </>
    )
}

export default MessagesPage;