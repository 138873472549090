import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { fetchAllCategoriesFunc } from '../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { ContextApi } from '../../../store/ContextApi';
import { addNewSubCategoryFunc } from '../../../store/custom-actions/sub-categories-actions/AddSubCategorySlicer';
import { getSubCategoriesFunc } from '../../../store/custom-actions/sub-categories-actions/GetSubCategoriesSlicer';
import { toast } from 'react-toastify';
import { getLanguagesFunc } from '../../../store/custom-actions/language/GetLanguagesSlicer';

function NewSCategory({show , onHide , categoriesApi}) {
    const contextApi = useContext(ContextApi);
    const {loading:getCategoriesLoading , categoriesData} = useSelector(state => state.getAllCategoriesSlicer)
    const {loading:addSubCategoryLoading} = useSelector(state => state.addSubCategorySlicer)
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const[Activaty , setActivaty] = useState(0);
    const dispatch = useDispatch();
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
    const subCategories_API_URL = `${contextApi.dashboard_URL_API}/sub-categories`;
    const [categoriesList , setCategoriesList] = useState([]);
    const {loading:getLanguagesLoading , data:languagesData} = useSelector(state => state.getLanguageSlicer)
    const languages_API_URL = `${contextApi.dashboard_URL_API}/languages`;
    const [languagesList , setLanguagesList] = useState([]);
    useEffect(() => {
        const getCategoriesData = async () => {
          try {
            dispatch(fetchAllCategoriesFunc(categories_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCategoriesData();
      }, []);
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
      }, [categoriesData]);
      useEffect(() => {
        categoriesList && setCategoriesList(categoriesList);
      }, [categoriesList]);
      useEffect(() => {
          const getLanguages = async () => {
            try {
              dispatch(getLanguagesFunc(languages_API_URL))
            } catch (error) {
              console.log(error);
            }
          };
          show && getLanguages();
        }, [show]);
        useEffect(() => {
          if(languagesData) {
            setLanguagesList(languagesData)
          }
        },[languagesData])
    const handleSubmitData = (data) => {
      if(data) {
        // const categoryForm = new FormData();
        // categoryForm.append("image" , categoryImage);
        // categoryForm.append("name" , data.name);
        // categoryForm.append("active" , Activaty);
        // categoryForm.append("category_id" , data.category_id);
        const objWillSend = {};
        for(const [key , value] of Object.entries(data)) {
          if(key === "category_id") {
            continue;
          }else {
            const langObj = {
              [key] : {
                name : value
              }
            }
            Object.assign(objWillSend , langObj)
          }
        }
        dispatch(addNewSubCategoryFunc({api : subCategories_API_URL , data : {...objWillSend , category_id: data.category_id , active: Activaty}}))
        .then(result => {
            if(result.payload.success === true) {
              dispatch(getSubCategoriesFunc(subCategories_API_URL));
              onHide();
              reset();
              // setCategoryImage("");
              toast.success(`${result.payload.message} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
        })
      }
      // else {
      //   toast.error(`category Image Is Reqired!`, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //   })
      // }
    }
    useEffect(() => {
      HandleErrorsListFunc(errors);
    },[errors])
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New Sub <span className='text-orange-700'>Category</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
            {
              getLanguagesLoading
              ?
              <p>Loading Languages..</p>
              :
              (
                languagesData
                ?
                (
                  languagesList && languagesList.length >= 1
                  ?
                  languagesList.map((lang , index) => (
                  <div className="form-group mb-3 col-sm-12">
                    <label>{`${lang.name} Name`}</label>
                    {errors.name?.message && (
                    <p className="text-red-600">{errors.name?.message}</p>
                    )}
                    <input
                    
                    {...register(lang.slug , {required : `${lang.name} name should not be empty!`})}
                    type="text"
                    className="form-control"
                    />
                  </div>
                  ))
                  :
                  <p>No Languages!</p>
                )
                :
                <p>Faild Load Languages!</p>
              )
            }
        {
            getCategoriesLoading ? (
                <div className="form-group flex items-center">
                <p>loading Categories..</p>
                </div>
            ) 
            : 
                categoriesData &&
                categoriesList &&
                categoriesList.length <= 0 ? (
                <div className="form-group flex items-center">
                    <p>No Categories</p>
                </div>
            ) 
            : 
            (
                <div
                className={`form-group mb-3 col-sm-12`}
                >
                <label>Category</label>
                {errors.category_id?.message && (
                    <p className="text-red-600">
                    {errors.category_id?.message}
                    </p>
                )}
                <select
                    {...register("category_id", {
                    required: "category is required",
                    })}
                    className="form-control col-sm-12"
                >
                    {categoriesList &&
                    categoriesList.map((cat, index) => {
                        return (
                        <option key={index} value={cat.id}>
                            {cat.name}
                        </option>
                        );
                    })}
                </select>
                </div>
            )
        }
          <div className='row'>
              {/* <div className="form-group mb-3 col-sm-12">
                  <label>Category Name</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("name" , {required : "name should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div> */}
              {/* {
                  imageViewr !== ""
                  ?
                  <div className="relative flex justify-center items-center mb-2">
                      <div className="relative w-[240px] h-[200px]">
                          <img src={imageViewr} alt="ategory img" className="absolute top-0 left-0 w-full h-full" />
                      </div>
                  </div>
                  :
                  ""
              } */}
              {/* <div className="form-group mb-3 col-sm-12">
                  <label>Category Image</label>
                  <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    file && setCategoryImage(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageViewr(imageUrl)
                  }}
                  />
              </div> */}
              <div className="form-group mb-3 col-sm-12">
                  <input
                  id="cat-active"
                  type="checkbox"
                  className="m-0 mr-3 translate-y-1"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  />
                  <label htmlFor='cat-active' className='cursor-pointer'>Activaty</label>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="new-category-form" className={`${addSubCategoryLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!addSubCategoryLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewSCategory;