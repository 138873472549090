import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getSingleUserFunc } from "../../../store/custom-actions/users-actions/GetSingleUserSlicer";
import { ContextApi } from "../../../store/ContextApi";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Dropdown } from "react-bootstrap";
import defaultUserImage from "../../../media/images/user (2).png"
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { toast } from "react-toastify";
import { deleteUserFunc } from "../../../store/custom-actions/users-actions/DeleteUserSlicer";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import ChangeUserPassForm from "../../custom-forms/users/ChangePassForm";
import UpdateUserStatus from "../../custom-forms/users/UpdateUserStatus";
import PageTitle from "../../layouts/PageTitle";
import UserOrdersTable from "./UserOrdersTable";

export default function UserProfile() {
    const {userId} = useParams();
    const {loading:getUserLoading , data:userData} = useSelector(state => state.getSingleUserSlicer)
    const {loading:deleteUserLoading} = useSelector(state => state.deleteUserSlicer)
    const contextApi = useContext(ContextApi);
    const singleUser_API_URL = `${contextApi.dashboard_URL_API}/users/${userId}`;
    const dispatch = useDispatch();
    const [addresses , setAddresses] = useState([])
    const [showChangePass , setShowChangePass] = useState()
    const [showUpdateStatus , setShowUpdateStatus] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        const getUser = async () => {
          try {
            dispatch(getSingleUserFunc(singleUser_API_URL));
          } catch (error) {
            console.log(error);
          }
        };
        getUser();
      }, []);
      useEffect(() => {
        userData && setAddresses(userData.user_addresses)
      },[userData])
    const deleteUserFunction = async () => {
      swal({
        title: "Confirm User Deletion",
        text: `Once deleted, This User will not be avilable!`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deleteUserFunc(singleUser_API_URL))
          .then((result) => {
            if(result.payload.success === true){
              navigate('/users')
              toast.success(`${result.payload.message} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
            }
          })
        }
      });
    };
    const sort = 10;
    let paggination = Array(Math.ceil(addresses.length / sort))
      .fill()
      .map((_, i) => i + 1);
  
    const activePag = useRef(0);
    // const jobData = useRef(null)
    const [jobData , setJobData] = useState(null)
    useEffect(() => {
        if (userData && userData.user_addresses) {
            setJobData(userData.user_addresses.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ))
        }
    }, [userData]);
    const onClick = (i) => {
      activePag.current = i;
      setJobData(addresses.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      ))
    };
    const [searchValue , setSearchValue] = useState("")
    const search = () => {
      if(searchValue !== "") {
        const searchResult = userData && userData.user_addresses &&
        userData.user_addresses.length >= 1 && userData.user_addresses.filter((address) =>  address.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
        console.log(searchResult);
        setJobData(searchResult)
      }else {
        userData && setJobData(userData.user_addresses);
      }
    }

    useEffect(() => {
      search()
    },[searchValue])

    return (
        <>
        <PageTitle motherMenu={"Uers"} activeMenu={"User Profile"}/>
        {
            getUserLoading
            ?
            <SpinnerLoading/>
            :
            (
                userData
                ?
                <>
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="profile card card-body px-3 pt-3 pb-0">
                            <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                <img
                                    src={userData.image_url ? userData.image_url : defaultUserImage}
                                    className="img-fluid rounded-circle"
                                    alt="profile"
                                />
                                </div>
                                <div className="profile-details">
                                <div className="profile-name px-3 pt-2">
                                    <h4 className="text-primary mb-0">{userData.first_name} {userData.last_name}</h4>
                                    {/* <p>{profileRole.name}</p> */}
                                </div>
                                <div className="profile-email px-2 pt-2">
                                    <h4 className="text-muted mb-0">{userData.email}</h4>
                                    <p>Email</p>
                                </div>
                                {
                                    checkPermissions(
                                        "update users",
                                        <Dropdown className="dropdown ms-auto z-[999]">
                                            <Dropdown.Toggle
                                            variant="primary"
                                            className="btn btn-primary light sharp i-false"
                                            data-toggle="dropdown"
                                            aria-expanded="true"
                                            >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="18px"
                                                height="18px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                                >
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                </g>
                                            </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                            <Dropdown.Item className="dropdown-item"
                                                onClick={() => setShowChangePass(true)}
                                            >
                                                <i className="fa-solid fa-user-lock mr-1"></i>
                                                Change Password
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item"
                                                onClick={() => setShowUpdateStatus(true)}
                                            >
                                                <i className="fa-solid fa-temperature-three-quarters mr-1"></i>
                                                Update Status
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item"
                                                onClick={() => deleteUserFunction()}
                                            >
                                                <i className="fa-solid fa-circle-minus mr-1"></i>
                                                Delete
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                                }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="profile-statistics">
                                                <div className="text-center">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h3 className="m-b-0">{addresses.length}</h3><span>Addresses</span>
                                                        </div>
                                                        <div className="col">
                                                            <h3 className="m-b-0">
                                                              {
                                                                userData.active === 1
                                                                ?
                                                                <span className="relative p-2 text-white text-sm bg-green-500 rounded-md">Active</span>
                                                                :
                                                                <span className="relative p-2 text-white text-sm bg-red-500 rounded-md">Not Active</span>
                                                              }  
                                                            </h3> <span>Activaty</span>
                                                        </div>
                                                        <div className="col">
                                                            <h3 className="m-b-0">{userData && userData.orders && userData.orders.length}</h3><span>Orders</span>
                                                        </div>
                                                        <div className="col">
                                                            <h3 className="m-b-0">
                                                              {
                                                                userData.is_email_verified === 1
                                                                ?
                                                                <span className="relative p-2 text-white text-sm bg-green-500 rounded-md">Verified</span>
                                                                :
                                                                <span className="relative p-2 text-white text-sm bg-red-500 rounded-md">Not Verified</span>
                                                              }  
                                                            </h3> <span>Verifing</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>	
                        </div>
                        <div className="col-12">
                            <div className="card">
                            <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">User Addresses</h4>
                            <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                    // onClick={() => search()
                                    // }
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                              <div className="w-100 table-responsive">
                                  <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                        <thead>
                                        <tr role="row">
                                            <th>#ID</th>
                                            <th>Title</th>
                                            <th>Address</th>
                                            <th>Country</th>
                                            <th>State</th>
                                            <th>City</th>
                                            <th>Phone</th>
                                            <th>Default</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                jobData !== null && jobData.length >=1 ? jobData.map((address , index) => {
                                                  const {country , state , city} = address;
                                                return (
                                                    <tr key={index}>
                                                        <td>#{address.id}</td>
                                                        <td>{address.title}</td>
                                                        <td>{address.address}</td>
                                                        <td>{country.name}</td>
                                                        <td>{state.name}</td>
                                                        <td>{city.name}</td>
                                                        <td>{address.phone}</td>
                                                        <td>
                                                          {
                                                            parseInt(address.is_default) !== 1
                                                            ?
                                                            <i class="fa-solid fa-circle-check text-green-500 scale-110"></i>
                                                            :
                                                            <i class="fa-solid fa-circle-xmark text-red-500 scale-110"></i>
                                                          }
                                                        </td>
                                                    </tr>
                                                )
                                                }) 
                                                :
                                                <tr>
                                                  <td colSpan={4}>No Adresses</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                            
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                            <div className="dataTables_info">
                                            Showing {activePag.current * sort + 1} to{" "}
                                            {addresses.length > (activePag.current + 1) * sort
                                                ? (activePag.current + 1) * sort
                                                : addresses.length}{" "}
                                            of {addresses.length} entries
                                            </div>
                                            <div
                                            className="dataTables_paginate paging_simple_numbers mb-0"
                                            id="example5_paginate"
                                            >
                                            <Link
                                                className="paginate_button previous disabled"
                                            //   to="/table-datatable-basic"
                                                to="#"
                                                onClick={() =>
                                                activePag.current > 0 && onClick(activePag.current - 1)
                                                }
                                            >
                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                            </Link>
                                            <span>
                                                {paggination.map((number, i) => (
                                                <Link
                                                    key={i}
                                                //   to="/table-datatable-basic"
                                                    to="#"
                                                    className={`paginate_button  ${
                                                    activePag.current === i ? "current" : ""
                                                    } `}
                                                    onClick={() => onClick(i)}
                                                >
                                                    {number}
                                                </Link>
                                                ))}
                                            </span>
                                            <Link
                                                className="paginate_button next"
                                            //   to="/table-datatable-basic"
                                                to="#"
                                                onClick={() =>
                                                activePag.current + 1 < paggination.length &&
                                                onClick(activePag.current + 1)
                                                }
                                            >
                                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                            </Link>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                </div>
                        </div>
                        <UserOrdersTable
                          orders={userData && userData.orders}
                        />
                      </div>
                </>
                :
                <FaildLoadData refetchDataFunc={() => dispatch(getSingleUserFunc(singleUser_API_URL))} />
            )
        }
        <LoadingScreen loading={deleteUserLoading} />
        <ChangeUserPassForm
          show = {showChangePass}
          onHide = {() => setShowChangePass(false)}
          userId = {userId}
        />
        <UpdateUserStatus
          show = {showUpdateStatus}
          onHide = {() => setShowUpdateStatus(false)}
          userId = {userId}
          oldActivaty = {userData && userData.active}
          oldVerifing = {userData && userData.is_email_verified}
          refetchFunc={() => dispatch(getSingleUserFunc(singleUser_API_URL))}
        />
        </>
    )
}
