import { useContext , useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import defaultStImg from "../../../media/images/user (2).png";
import Swal from "sweetalert2";
import "./send-notfication-age.css";
import sendIcon from "../../../media/images/send.png"
import { ContextApi } from "../../../store/ContextApi";
import { getUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { toast } from "react-toastify";
import { sendNotficationFunc } from "../../../store/custom-actions/notfications-actions/SendNotficationSlicer";
function SendNotficationPage() {
    const dispatch = useDispatch();
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const [messageSubject , setMessageSubject] = useState();
    const [stArr , setStArr] = useState([])
    const {loading:getUsersLoading , data:usersData} = useSelector(state => state.getUsersSlicer)
    const {loading:sendMessageLoading} = useSelector(state => state.sendNotficationSlicer)
    const contextApi = useContext(ContextApi);
    const users_API_URL = `${contextApi.dashboard_URL_API}/users`;
    const notfications_API_URL = `${contextApi.dashboard_URL_API}/notifications/send-users`;
    const [usersList , setUsersList] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
          try {
            dispatch(getUsersFunc(users_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getUsers();
      }, []);
      useEffect(() => {
        usersData && setUsersList(usersData)
      },[usersData])
    const modules = {
        toolbar: {
        container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image', 'video'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    const handleSubmitMessage = async (data) => {
        if(stArr.length < 1) {
            Swal.fire({
                title: "Oops!",
                text: "You Should Choose At Least One User To Send Message!",
                icon: "warning"
            });
        }else {
            dispatch(sendNotficationFunc({api : notfications_API_URL , data : {...data , users : stArr , subject : messageSubject}}))
            .then(result => {
                if(result.payload.success === true) {
                    setTimeout(() => window.history.back() , 2000);
                    reset();
                    setMessageSubject("");
                    setStArr([]);
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }

    return (
        <>
        
            <div className="relative flex row justify-between items-center mb-3">
                <div className="relative flex items-center">
                    <img src={sendIcon} alt="send icon" className="w-[20px] mr-1" />
                    <h4 className="m-0">New Message</h4>
                </div>
                <div className="flex items-center justify-end">
                    
                    <button 
                    type="submit"
                    form="send-message-form" 
                    className={`btn btn-primary btn-sm ${sendMessageLoading  ? "pointer-events-none opacity-50" : ""} `}
                    style={{display : "flex"}}
                    >
                        {
                            sendMessageLoading
                            ?
                            "Please Wait.."
                            :
                            <>
                            Send
                            <svg
                                className="w-5 h-5"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                ></path>
                            </svg>
                            </>
                        }
                    </button>
                </div>
            </div>

            <div className="send-note-form-div relative w-full ">
                <form 
                    className="send-message-formm"
                    id="send-message-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitMessage(data)
                    })}
                >
                    
                        <>
                            <div className={'row'}>
                                <div className="form-group mb-3 col-sm-12">
                                    <label className="flex items-center">
                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                        Message Title
                                    </label>
                                    {errors.title?.message && (
                                    <p className="text-red-600">
                                        {errors.title?.message}
                                    </p>
                                    )}
                                    <input
                                    {...register("title", {
                                        required: "Title is required !"
                                    })}
                                    type="text"
                                    className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="students-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Message Subject'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <h4 className="my-2">Select Users <span className='text-sm font-normal'>(at least 1 User)</span></h4>
                            <div className='row my-4'>
                                {
                                getUsersLoading
                                ?
                                (
                                    <SpinnerLoading/>
                                )
                                :
                                (
                                    usersData &&
                                    usersList.length >= 1
                                    ?
                                    usersList.map((user , index) => {
                                        return (
                                        <>
                                            <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
                                            <input 
                                            onChange={(e)=>{
                                                if(e.target.checked){
                                                stArr.push(user.id);
                                                }else {
                                                let filtred = stArr.filter(st => {
                                                    return st !== user.id
                                                });
                                                setStArr([...filtred]);
                                                }
                                            }}
                                            type='checkbox' 
                                            className={`cursor-pointer m-0 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} 
                                            id={index}/>
                                            <label className={`cursor-pointer flex items-center ml-1 mt-1 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} htmlFor={index}>
                                                <img src={user.image_url !== null ? user.image_url : defaultStImg} className='relative rounded-[50%] w-[25px] mx-2' alt="st img" />
                                                <p className='m-0'>{user.first_name} {user.last_name}</p>
                                            </label>
                                            </div>
                                        </>
                                        )
                                    })
                                    :
                                    <p>There are no Users!</p>
                                )
                                }
                            </div>
                            <div className="message-subject-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Message Subject'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <div className="row">
                                <ReactQuill 
                                modules = {modules}
                                className="p-0 border-none outline-none form-control bg-white mt-3" 
                                value={messageSubject} theme="snow" 
                                onChange={setMessageSubject} />
                            </div>
                        </>
                </form>
            </div>
        </>
        
    )
}

export default SendNotficationPage;