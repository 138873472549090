import "./new-product-form-style.css"
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { addProductFunc } from "../../../store/custom-actions/products-actions/AddProductSlicer";
import { ContextApi } from "../../../store/ContextApi";
import { getSubCategoriesFunc } from "../../../store/custom-actions/sub-categories-actions/GetSubCategoriesSlicer";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { Step, Stepper } from "react-form-stepper";
import { Link } from "react-router-dom";

function NewProductForm() {
    const contextApi = useContext(ContextApi);
    const productsApi = `${contextApi.dashboard_URL_API}/products`;
    const categories_api = `${contextApi.dashboard_URL_API}/categories`;
    const subCategories_API_URL = `${contextApi.dashboard_URL_API}/sub-categories`;
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const dispatch = useDispatch();
    const [productDesc , setProducDesc] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [productImage, setProductImage] = useState("");
    const [productImageViwer, setProductImageViwer] = useState("");
    const {loading:getSubCategoriesLoading , data:subCategoriesData} = useSelector(
        state => state.getSubCategoriesSlicer
    )
    const {loading:getCategoriesLoading , categoriesData} = useSelector(
        state => state.getAllCategoriesSlicer
    )
    const {loading:addProductLoading} = useSelector(
        state => state.addProductSlicer
    )
    const [typesObject , setTypesObject] = useState({});
    const [key , setKey] = useState("")
    const [valueOfKey , setValueOfKey] = useState("")
    const [goSteps, setGoSteps] = useState(0);
    const [stepName , setStepName] = useState("Category");
    const [productTitle , setProductTitle] = useState("");
    const [productSku , setProductSku] = useState("");
    const [productPrice , setProductPrice] = useState("");
    const [productDiscount , setProductDiscount] = useState(0);
    const [productStock , setProductStock] = useState("");
    const [minStock , setMinStock] = useState("");
    const [allMainDataValid , setAllMainDataValid] = useState();
    useEffect(() => {
        if(productTitle !== "" && productSku !== "" && productPrice !== "" && productStock !== "" && minStock !== "") {
            setAllMainDataValid(true);
        }else {
            setAllMainDataValid(false)
        }
    },[productTitle , productSku , productPrice , productStock , minStock])

    useEffect(() => {
        switch(goSteps) {
            case 0:
                setStepName("Category");
                break;
            case 1:
                setStepName("Main Data");
                break;
            case 2:
                setStepName("Item Description");
                break;
            case 3:
                setStepName("Item Attributes");
                break;
            case 4:
                setStepName("Item Image");
                break;
            default:
                setStepName("Category");
        }
    },[goSteps])
    
    useEffect(() => {
        const getCategoriesFunc = async () => {
          dispatch(fetchAllCategoriesFunc(`${categories_api}`));
        };
        getCategoriesFunc();
      }, [categories_api]);


      useEffect(() => {
        const getSubCategoriesData = async () => {
          try {
            dispatch(getSubCategoriesFunc(`${subCategories_API_URL}?category_id=${categoryId}`))
          } catch (error) {
            console.log(error);
          }
        };
        categoryId && categoryId !== "" && getSubCategoriesData();
      }, [categoryId]);
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
      }, [categoriesData]);
      useEffect(() => {
        subCategoriesData && setSubCategoriesList(subCategoriesData);
      }, [subCategoriesData]);


    const CategoriesStep = () => {
        return (
            <>
                <div className="new-item-form-div row relative w-full ">
                    {
                        getCategoriesLoading ? (
                            <div className="form-group flex items-center">
                                <p>loading Categories..</p>
                            </div>
                        ) : categoriesData &&
                            categoriesList &&
                            categoriesList.length <= 0 ? (
                            <div className="form-group flex items-center">
                                <p>No Categories</p>
                            </div>
                        ) : (
                            <div
                                className={`form-group mb-3 ${"col-sm-12 col-md-6"}`}
                            >
                            <label>Category</label>
                            <select
                                className="form-control col-sm-12"
                                onChange={(e) => setCategoryId(e.target.value)}
                                value={categoryId}
                            >
                                <option selected value={""} disabled>
                                    Choose Category..
                                </option>
                                {
                                categoriesList &&
                                categoriesList.map((cat, index) => {
                                    return (
                                    <option key={index} value={cat.id}>
                                        {cat.name}
                                    </option>
                                    );
                                })
                                }
                            </select>
                            </div>
                        )
                    }
                    {
                        
                        getSubCategoriesLoading ? (
                            <div className="form-group flex items-center col-sm-12 col-md-6">
                                <p>loading Sub Categories..</p>
                            </div>
                        ) : subCategoriesData &&
                            subCategoriesList &&
                            subCategoriesList.length <= 0 ? (
                            <div className="form-group flex items-center col-sm-12 col-md-6">
                                <p>No Sub Categories</p>
                            </div>
                        ) : (
                            <div
                            className={`form-group mb-3 col-sm-12 col-md-6 ${categoryId !== "" ? "" : "pointer-events-none opacity-50"}`}
                            >
                            <label>Sub Category</label>
                            <select
                                className="form-control col-sm-12"
                                onChange={(e) => setSubCategoryId(e.target.value)}
                                disabled = {categoryId !== "" ? false : true}
                                value={subCategoryId}
                            >
                                <option selected value={""} disabled>
                                    Choose Sub Category..
                                </option>
                                {
                                subCategoriesList &&
                                subCategoriesList.map((cat, index) => {
                                    return (
                                    <option key={index} value={cat.id}>
                                        {cat.name}
                                    </option>
                                    );
                                })
                                }
                            </select>
                            </div>
                        )
                        
                    }
                </div>
            </>
        )
    }

    const ImageStep = () => {
        return (
            <>
                {
                    productImage !== ""
                    ?
                    <div className="relative flex justify-center items-center mb-2">
                        <div className="relative w-[240px] h-[200px] ">
                            <img src={productImageViwer} alt="product img" className="absolute top-0 left-0 w-full h-full" />
                        </div>
                    </div>
                    :
                    ""
                }
                <div className="w-full flex justify-center items-center mb-1">
                    <span className="text-sm">best size (200px * 180px)</span>
                </div>
                <form className="file-upload-form">
                    <label for="file" className="file-upload-label">
                        <div className="file-upload-design">
                        <svg viewBox="0 0 640 512" height="1em">
                            <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                            ></path>
                        </svg>
                        <span className="browse-button">Browse file</span>
                        </div>
                        <input 
                            id="file" 
                            type="file" 
                            accept="image/jpeg, image/png, image/jpg"
                            className="form-control"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const imageUrl = URL.createObjectURL(file);
                                    setProductImageViwer(imageUrl); 
                                }                        
                                setProductImage(e.target.files[0]);
                            }}
                        />
                    </label>
                </form>
            </>
        )
    }

    const handleSubmitNewProduct = async (data) => {
        if(data) {
            if(productDesc === "") {
                toast.error("product Descripton Is Required!")
            }else {
                const productFormData = new FormData();
                productFormData.append("title" , data.title)
                productFormData.append("price" , data.price)
                // productFormData.append("discount" , data.discount)
                productFormData.append("sku" , data.sku)
                productFormData.append("stock" , data.stock)
                productFormData.append("min_stock" , data.min_stock)
                parseInt(data.discount) > parseInt(data.price) && productFormData.append("discount" , data.discount)
                productFormData.append("description" , productDesc)
                // productFormData.append("active" , activatyStatus)
                productFormData.append("active" , data.active_status === true ? 1 : 0)
                productFormData.append("recomended" , data.recomended === true ? 1 : 0)
                // productFormData.append("featured" , data.featured === true ? 1 : 0)
                productFormData.append("sub_category_id" , subCategoryId)
                productFormData.append("image" , productImage)
                for (const [key, value] of Object.entries(typesObject)) {
                    productFormData.append(`product_attributes[${key}]`, value);
                }
                dispatch(addProductFunc(
                    {
                        api : productsApi , 
                        data : productFormData
                    }
                ))
                .then (result => {
                    if(result.payload.success === true) {
                        reset();
                        setTimeout(() => window.history.back() , 2000)
                        toast.success(`${result.payload.message} !`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        })
                    }
                })
            }
        }
    }

    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];

    const modules = {
        toolbar: {
        container: [
            [{"color" : colors}],
            [{ 'background': colors }]
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link' , "video"],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean']
        ],
    }
    };


    return (
        <>
            <PageTitle motherMenu={"Products"} activeMenu={"New Item"} />
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">{stepName}</h4>
						</div>
						<div className="card-body">
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
									<Step className="nav-link" onClick={() => setGoSteps(4)} />
								</Stepper>
                                <form
                                    className="new-item-form"
                                    id="new-item-form"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitNewProduct(data);
                                    })}
                                >
                                    {goSteps === 0 && (
                                        <>
                                            <CategoriesStep/>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className={`btn btn-primary sw-btn-next ${subCategoryId !== "" ? "" : "pointer-events-none opacity-50"}`} onClick={() => setGoSteps(1)}>Next</button>
                                            </div>	
                                        </>
                                    )}
                                    {goSteps === 1 && (
                                        <>
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Product Title
                                                </label>
                                                {errors.title?.message && (
                                                <p className="text-red-600">
                                                    {errors.title?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("title", {
                                                    required: "product title is required !"
                                                })}
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setProductTitle(e.target.value)}
                                                value={productTitle}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Product SKU (Max 10 letters)
                                                </label>
                                                {errors.sku?.message && (
                                                <p className="text-red-600">
                                                    {errors.sku?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("sku", {
                                                    required: "product SKU is required !"
                                                })}
                                                type="text"
                                                className="form-control"
                                                max={10}
                                                onChange={(e) => setProductSku(e.target.value)}
                                                value={productSku}
                                                />
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    Product Price
                                                </label>
                                                {errors.price?.message && (
                                                <p className="text-red-600">
                                                    {errors.price?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("price", {
                                                    required: "Product price is required !"
                                                })}
                                                type="number"
                                                className="form-control"
                                                min={0}
                                                onChange={(e) => setProductPrice(e.target.value)}
                                                value={productPrice}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-clock mr-1"></i>
                                                    Discount (optional)
                                                </label>
                                                {errors.decount?.message && (
                                                <p className="text-red-600">
                                                    {errors.decount?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("discount")}
                                                type="number"
                                                className="form-control"
                                                onChange={(e) => setProductDiscount(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    Product Stock
                                                </label>
                                                {errors.stock?.message && (
                                                <p className="text-red-600">
                                                    {errors.stock?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("stock", {
                                                    required: "Product stock is required !"
                                                })}
                                                type="number"
                                                className="form-control"
                                                min={0}
                                                onChange={(e) => setProductStock(e.target.value)}
                                                value={productStock}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    Min Stock
                                                </label>
                                                {errors.min_stock?.message && (
                                                <p className="text-red-600">
                                                    {errors.min_stock?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("min_stock", {
                                                    required: "Product min_stock is required !"
                                                })}
                                                type="number"
                                                className="form-control"
                                                min={0}
                                                onChange={(e) => setMinStock(e.target.value)}
                                                value={minStock}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-12 col-md-4 flex items-cneter">
                                                <input 
                                                    {...register("active_status")}
                                                    id="customCheckBox5"
                                                    type="checkbox"
                                                    className=" scale-125 cursor-pointer"
                                                />
                                                <label htmlFor="customCheckBox5" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                                                    <i className="fa-solid fa-rss mr-1"></i>
                                                    Activaty Status
                                                </label>
                                            </div>
                                            <div className="form-group col-sm-12 col-md-4 flex items-cneter">
                                                <input 
                                                    {...register("recomended")}
                                                    id="customCheckBox4"
                                                    type="checkbox"
                                                    className=" scale-125 cursor-pointer"
                                                />
                                                <label htmlFor="customCheckBox4" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                                                    <i className="fa-solid fa-rss mr-1"></i>
                                                    Recomended
                                                </label>
                                            </div>
                                            {/*<div className="form-group col-sm-12 col-md-4 flex items-cneter">
                                                <input 
                                                    {...register("featured")}
                                                    id="customCheckBox3"
                                                    type="checkbox"
                                                    className=" scale-125 cursor-pointer"
                                                />
                                                <label htmlFor="customCheckBox3" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                                                    <i className="fa-solid fa-rss mr-1"></i>
                                                    Featured
                                                </label>
                                            </div>*/}
                                        </div>
                                        <div className="text-end toolbar toolbar-bottom p-2">
                                            <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
                                            <Link
                                            to="#" 
                                            role="button"
                                            className={`btn btn-primary sw-btn-next ms-1 ${allMainDataValid ? "" : "pointer-events-none opacity-50"}`} 
                                            onClick={() => {
                                                if(parseInt(productDiscount) > 0  &&  parseInt(productDiscount) < productPrice) {
                                                    toast.error(`Dicount Price Should be More than price !`, {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    })
                                                }else {
                                                    setGoSteps(2)
                                                }
                                            }}>Next</Link>
                                        </div>
                                    </>
                                    )}
                                    {goSteps === 2 && (
                                        <>
                                            <div className="row">
                                                <ReactQuill 
                                                modules = {modules}
                                                className="p-0 border-none outline-none form-control bg-white mt-3" 
                                                value={productDesc} 
                                                theme="snow" 
                                                onChange={setProducDesc} />
                                            </div>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
                                                <button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
                                            </div>	
                                        </>
                                    )}
                                    {goSteps === 3 && (
                                        <>
                                            <div className="row">
                                                {
                                                Object.keys(typesObject).length < 1
                                                &&
                                                <p className="my-2 text-center">Add Product Attributes (At Least one Attribute..)</p>
                                                }
                                                {
                                                Object.keys(typesObject).length >= 1
                                                &&
                                                Object.keys(typesObject).map((key , index) => {
                                                    return (
                                                    <div className="row flex items-center">
                                                        <div className="form-group mb-3 col-sm-2 col-md-3">
                                                            <input
                                                            type="text"
                                                            className="form-control key-input"
                                                            value={key}
                                                            disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-sm-9 col-md-8">
                                                            <input
                                                            type="text"
                                                            className="form-control"
                                                            value={typesObject[key]}
                                                            disabled
                                                            />
                                                        </div>
                                                        <i className="fa-solid fa-trash text-[17px] relative inline-block cursor-pointer delete-icon  transition-all duration-500 hover:scale-110 col-sm-1 translate-y-[-40px]"
                                                        style={{transform: "translateY(-8px)"}}
                                                        onClick={() => {
                                                            const newTypesObject = {...typesObject}
                                                            delete newTypesObject[key]
                                                            setTypesObject(newTypesObject)
                                                        }}
                                                        ></i>
                                                    </div>
                                                    )
                                                })
                                                }
                                                <div className="row">
                                                <div className="form-group mb-3 col-sm-3 col-md-4">
                                                    <label>Key</label>
                                                    <input
                                                    type="text"
                                                    className="form-control key-input"
                                                    value = {key}
                                                    onChange={(e) => setKey(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-sm-9 col-md-8">
                                                    <label>Value</label>
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    value = {valueOfKey}
                                                    onChange={(e) => setValueOfKey(e.target.value)}
                                                    />
                                                </div>
                                                </div>
                                                <div className="row">
                                                {
                                                    key !== "" && valueOfKey !== ""
                                                    &&
                                                    <Button className=""
                                                    onClick={() => {
                                                        setValueOfKey("");
                                                        setKey("");
                                                        setTypesObject((prevObject) => ({
                                                        ...prevObject,
                                                        [key]: valueOfKey,
                                                        }));
                                                    }}
                                                    >Append</Button>
                                                }
                                                </div>
                                            </div>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(2)}>Prev</button>
                                                <button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(4)}>Next</button>
                                            </div>	
                                        </>	
                                    )}
                                    {goSteps === 4 && (
                                        <>
                                            <ImageStep/>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className={`btn btn-secondary sw-btn-prev me-1 ${addProductLoading ? "pointer-events-none opacity-50" : ""}`} onClick={() => setGoSteps(3)}>Prev</button>
                                                <button 
                                                type="submit"
                                                form="new-item-form"
                                                className={`btn btn-primary sw-btn-next ms-1 ${addProductLoading ? "pointer-events-none opacity-50" : ""}`}  
                                                
                                                >
                                                    {
                                                        addProductLoading
                                                        ?
                                                        "loading.."
                                                        :
                                                        "Submit"
                                                    }
                                                </button>
                                            </div>	
                                        </>	
                                    )}
							    </form>
							</div>
						</div>
					</div>
				</div>
			</div>
        </>
    )
}

export default NewProductForm;