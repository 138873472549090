import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { changeAdminPassFunc } from "../../../store/custom-actions/admins-actions/ChangeAdminPassSlicer";

export default function ChangeAdminPassword() {
    const {adminId} = useParams();
    const contextApi = useContext(ContextApi);
    const singleAdminApi = `${contextApi.dashboard_URL_API}/admins/${adminId}/change-password`;
    const {register , handleSubmit  , formState : {errors}} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading:getSingleAdminLoading} = useSelector(
        state => state.getSingleAdminSlicer
    )
    const {loading:changePassLoadig} = useSelector(
        state => state.changeAdminPassSlicer
    )

    const handleSubmitData = async (data) => {
        if(data) {
            if(data.password !== data.password_confirmation) {
                toast.error(`Please Confirm New Password!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
            }else {
                const adminFormData = new FormData();
                adminFormData.append("password" , data.password);
                adminFormData.append("password_confirmation" , data.password_confirmation);
                dispatch(changeAdminPassFunc(
                    {
                        api : singleAdminApi , 
                        data : adminFormData
                    }
                ))
                .then (result => {
                    if(result.payload.success === true) {
                        navigate("/admins");
                        toast.success(`${result.payload.message} !`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        })
                    }
                })
            }
        }
    }


    return (
        <>
            <PageTitle motherMenu={"Admins"} activeMenu={"Update Admin Password"} />
            {
                getSingleAdminLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-wizard ">
                                    <form
                                        className="change-admin-pass-form"
                                        id="change-admin-pass-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitData(data);
                                        })}
                                    >
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    New Password
                                                </label>
                                                {errors.password?.message && (
                                                <p className="text-red-600">
                                                    {errors.password?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("password", {
                                                    required: "Password is required !"
                                                })}
                                                type="password"
                                                className="form-control"
                                                // onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Confirm New Password
                                                </label>
                                                {errors.password_confirmation?.message && (
                                                <p className="text-red-600">
                                                    {errors.password_confirmation?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("password_confirmation", {
                                                    required: "Confirmation is required !"
                                                })}
                                                type="password"
                                                className="form-control"
                                                max={10}
                                                // onChange={(e) => setConfirmation(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-end toolbar toolbar-bottom p-2">
                                            <button 
                                            type="submit"
                                            form="change-admin-pass-form"
                                            className={`btn btn-primary sw-btn-next ms-1 ${changePassLoadig ? "pointer-events-none opacity-50" : ""}`}
                                            >
                                                {
                                                    changePassLoadig
                                                    ?
                                                    "loading.."
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </div>	
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}