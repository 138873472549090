import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ContextApi } from '../../../../store/ContextApi';
import { translateTextFunc } from '../../../../store/custom-actions/language/TranslateTextSlicer';

function TranslateTextForm({show , hide , text , oldTranslate , langId , refetetchFunc}) {
    const contextApi = useContext(ContextApi);
    const translate_API_URL = `${contextApi.dashboard_URL_API}/languages/${langId}`;
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:translateTextLoading} = useSelector(state => state.translateTextSlicer);
    const dispatch = useDispatch();
    useEffect(() => {
        setValue("string_key" , text)
        setValue("translate_word" , oldTranslate)
    },[setValue , text , oldTranslate])
      
    const handleSubmitData = (data) => {
        const form = new FormData();
        form.append("string_key" ,data.string_key)
        form.append("translate_word" ,data.translate_word)
        form.append("_method" ,"PUT")
      dispatch(translateTextFunc({api : translate_API_URL , data : form}))
      .then(result => {
          if(result.payload.success === true) {
            refetetchFunc()
            hide();
            reset();
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Translation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12">
                  <label>Source Text</label>
                  <input
                  {...register("string_key")}
                  type="text"
                  className='form-control'
                  readOnly
                  />
              </div>
              <div className="form-group mb-3 col-sm-12">
                  <label>Translate Content</label>
                  {errors.translate_word?.message && (
                  <p className="text-red-600">{errors.translate_word?.message}</p>
                  )}
                  <input
                  {...register("translate_word" , {required : "Translation Content Is Required "})}
                  type="text"
                  className='form-control'
                  />
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
          <Button type="submit" form="new-category-form" className={`${translateTextLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!translateTextLoading ? (
            "Translate"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TranslateTextForm;