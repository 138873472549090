import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {useContext, useEffect, useState} from 'react';
import { updateCategoryFunc } from '../../../store/custom-actions/categories-actions/UpdateCategorySlicer';
import { fetchAllCategoriesFunc } from '../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { toast } from 'react-toastify';
import { getLanguagesFunc } from '../../../store/custom-actions/language/GetLanguagesSlicer';
import { ContextApi } from '../../../store/ContextApi';

function UpdateCategoryForm({showUpdateCategoryForm , hideUpdateCategoryForm , categoriesApi , categoryName , categoryId , categoryActivaty , translations}) {
    const contextApi = useContext(ContextApi);
    const {register , formState : {errors} , handleSubmit , setValue} = useForm();
    const {updateCategoryloading} = useSelector(state => state.updateCategorySlicer);
    const dispatch = useDispatch();
    const [activaty , setActivaty] = useState();
    const {loading:getLanguagesLoading , data:languagesData} = useSelector(state => state.getLanguageSlicer)
    const languages_API_URL = `${contextApi.dashboard_URL_API}/languages`;
    const [languagesList , setLanguagesList] = useState([]);

    useEffect(() => {
        const getLanguages = async () => {
          try {
            dispatch(getLanguagesFunc(languages_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        showUpdateCategoryForm && getLanguages();
      }, [showUpdateCategoryForm]);
      useEffect(() => {
        if(languagesData) {
          setLanguagesList(languagesData)
        }
      },[languagesData])
    const handleSubmitData = (data) => {
      // if (data.name === "") {
      //   toast.error("Category name must be more than 3 letters!");
      //   return;
      // }
      if(data) {
        console.log(data)
        const objWillSend = {};
        for(const [key , value] of Object.entries(data)) {
          const langObj = {
            [key] : {
              name : value
            }
          }
          Object.assign(objWillSend , langObj)
        }
        dispatch(updateCategoryFunc({categoryApi : `${categoriesApi}/${categoryId}` , newCategoryData : {...objWillSend , active : activaty}}))
        .then(result => {
            if(result.payload.success === true) {
              dispatch(fetchAllCategoriesFunc(categoriesApi));
              hideUpdateCategoryForm();
              toast.success(`${result.payload.message} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
            }
        })
      }
    }




    useEffect(() => {
      setActivaty(categoryActivaty)
      if(translations) {
        for(let i=0; i < translations.length; i++) {
          setValue(translations[i].locale , translations[i].name)
        }
      }
    },[setValue , categoryName , translations])

  useEffect(() => {
    HandleErrorsListFunc(errors);
  },[errors])
  return (
    <>
      <Modal show={showUpdateCategoryForm} onHide={hideUpdateCategoryForm}>
        <Modal.Header closeButton>
          <Modal.Title>Update On <span className='text-orange-700'>{categoryName}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form 
            id="update-category-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitData(data);
            })}
            >
              <div className='row'>
                  {
                    getLanguagesLoading
                    ?
                    <p>Loading Languages..</p>
                    :
                    (
                      languagesData
                      ?
                      (
                        languagesList && languagesList.length >= 1
                        ?
                        languagesList.map((lang , index) => (
                        <div className="form-group mb-3 col-sm-12" key={index}>
                          <label>{`${lang.name} Name`}</label>
                          {errors[lang.slug]?.message && (
                          <p className="text-red-600">{errors[lang.slug]?.message}</p>
                          )}
                          <input
                            {...register(lang.slug , {required : `${lang.name} name should not be empty!`})}
                            type="text"
                            className="form-control"
                          />
                        </div>
                        ))
                        :
                        <p>No Languages!</p>
                      )
                      :
                      <p>Faild Load Languages!</p>
                    )
                  }
                  {/* <div className="form-group mb-3 col-sm-12">
                      <label>Category Name</label>
                      {errors.name?.message && (
                          <p className="text-red-600">{errors.name?.message}</p>
                      )}
                      <input
                      {...register("name", {required : "name should not be empty!"})}
                      type="text"
                      className="form-control"
                      />
                  </div> */}
                  <div className="form-group mb-3 col-sm-12">
                    <input
                    id="cat-active"
                    type="checkbox"
                    className="m-0 mr-2 translate-y-1"
                    onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                    checked = {activaty === 1 ? true : false}
                    />
                    <label htmlFor='cat-active' className='cursor-pointer'>Activaty</label>
                  </div>
              </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideUpdateCategoryForm}>
            Close
          </Button>
          <Button type="submit" form="update-category-form" className={`${updateCategoryloading ? "pointer-events-none opacity-80" : ""}`}>
          {!updateCategoryloading 
          ? (
            "Update"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCategoryForm;