import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ContextApi } from '../../../store/ContextApi';
import { postCurrencySttingsFunc } from '../../../store/custom-actions/settings-actions/currency-settings-actions/PostCurrencySttingsSlicer';
import { getCurrencySettingsFunc } from '../../../store/custom-actions/settings-actions/currency-settings-actions/GetCurrencySettingsSlicer';
import SpinnerLoading from '../../components/spinner-loading/SpinnerLoading';
function CurrencyForm({showCurrencyForm , hideCurrencyForm}) {
    const contextApi = useContext(ContextApi);
    const currencyAPi = `${contextApi.dashboard_URL_API}/general-settings/global-currency-settings`;
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:postSettingsLoading} = useSelector(state => state.postCurrencySttingsSlicer);
    const {loading:getSittingLoading , data} = useSelector(state => state.getCurrencySettingsSlicer);
    const dispatch = useDispatch();

    const allCurrencies = [
        "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN",
        "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND", "BOB", "BRL",
        "BSD", "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY",
        "COP", "CRC", "CUP", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP",
        "ERN", "ETB", "EUR", "FJD", "FKP", "FOK", "GBP", "GEL", "GGP", "GHS",
        "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF",
        "IDR", "ILS", "IMP", "INR", "IQD", "IRR", "ISK", "JEP", "JMD", "JOD",
        "JPY", "KES", "KGS", "KHR", "KID", "KMF", "KRW", "KWD", "KYD", "KZT",
        "LAK", "LBP", "LKR", "LRD", "LSL", "LYD", "MAD", "MDL", "MGA", "MKD",
        "MMK", "MNT", "MOP", "MRU", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN",
        "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PGK",
        "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR",
        "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLL", "SOS", "SRD", "SSP",
        "STN", "SYP", "SZL", "THB", "TJS", "TMT", "TND", "TOP", "TRY", "TTD",
        "TVD", "TWD", "TZS", "UA"
    ]
    const getCurrencySettings = () => {
        dispatch(getCurrencySettingsFunc(currencyAPi))
    }
    useEffect(() => {
        getCurrencySettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("site_global_currency" , data.site_global_currency)
        }
    },[data])
    const handleSubmitData = (data) => {
      dispatch(postCurrencySttingsFunc({api : currencyAPi , data : data}))
      .then(result => {
          if(result.payload.success === true) {
            dispatch(getCurrencySettingsFunc(currencyAPi))
            hideCurrencyForm();
            reset()
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
  return (
    <>
      <Modal show={showCurrencyForm} onHide={hideCurrencyForm}>
        <Modal.Header closeButton>
          <Modal.Title>Currency Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                getSittingLoading
                ?
                <SpinnerLoading/>
                :
                <form 
                id="new-category-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitData(data)
                })}
                >
                <div className='row'>
                    <div className="form-group mb-3 col-sm-12">
                        <label>Currency</label>
                        {errors.name?.message && (
                        <p className="text-red-600">{errors.name?.message}</p>
                        )}
                        <select
                            {...register("site_global_currency")}
                            className='form-control'
                            onChange={() => {

                            }}
                        >
                        {
                            allCurrencies.map((currency => <option key={currency}>{currency}</option>))
                        }
                        </select>
                    </div>
                </div>
                </form>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCurrencyForm}>
            Close
          </Button>
          <Button type="submit" form="new-category-form" className={`${postSettingsLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!postSettingsLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CurrencyForm;