import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
//** Import Image */
import profile01 from "../../../images/profile/1.jpg";
import profile02 from "../../../images/profile/2.jpg";
import profile03 from "../../../images/profile/3.jpg";
import profile04 from "../../../images/profile/4.jpg";
import profile05 from "../../../images/profile/5.jpg";
import profile06 from "../../../images/profile/6.jpg";
import profile07 from "../../../images/profile/7.jpg";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import { getProfileDataFunc } from "../../../store/custom-actions/admins-actions/GetProfleDataSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import defaultUserImage from "../../../media/images/user (2).png"
import {useForm} from "react-hook-form";
import { updateAdminFunc } from "../../../store/custom-actions/admins-actions/UpdateAdminSlicer";
import { toast } from "react-toastify";
import { updateAdminImageFunc } from "../../../store/custom-actions/admins-actions/UpdateAdminImageSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
export default function AdminProfile () {
    const {register , handleSubmit  , formState : {errors} , setValue} = useForm();
    const {loading:getProfileLoadng , data} = useSelector(state => state.getProfileDataSlicer)
    const {loading:updateAdminLoading} = useSelector(
        state => state.updateAdminSlicer
    );
    const {loading:updateAdminImageLoading} = useSelector(
        state => state.updateAdminImageSlicer
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contextApi = useContext(ContextApi);
    const profile_API_URL = `${contextApi.dashboard_URL_API}/profile`;
    const adminsApi = `${contextApi.dashboard_URL_API}/admins`;
    const [profileData , setProfileData] = useState({});
    const [profileRole , setProfileRole] = useState({});
    const [rolePermissions , setRolePermissions] = useState([]);
    const [searchValue , setSearchValue] = useState()
    const [adminImage, setAdminImage] = useState("");
    const [imageViwer, setImageViwer] = useState(defaultUserImage);
    useEffect(() => {
        if(profileData) {
            setImageViwer(profileData.image)
        }
      }, [profileData]);
    useEffect(() => {
        const getProfileData = async () => {
            try {
                dispatch(getProfileDataFunc(profile_API_URL));
            } catch (error) {
                console.log(error);
            }
        };
        getProfileData();
    }, []);
    useEffect(() => {
        if(data) {
            setProfileData(data);
            data.roles && setProfileRole(data.roles[0]);
            data.roles && setRolePermissions(data.roles[0].permissions);
            setValue("name" , data.name)
            setValue("email" , data.email)
            setValue("phone" , data.phone)
        }
    },[data])
    const sort = 20;
    let paggination = Array(Math.ceil(rolePermissions.length / sort))
      .fill()
      .map((_, i) => i + 1);
  
    const activePag = useRef(0);
    const jobData = useRef(null)
    useEffect(() => {
        if (data && data.roles[0].permissions) {
            // If permissions exist, slice rolePermissions and assign to jobData.current
            jobData.current = data.roles[0].permissions.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            );
        }
    }, [data]);
    const onClick = (i) => {
      activePag.current = i;
      jobData.current = rolePermissions.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      );
    };
    const search = () => {
      if(searchValue !== "") {
        const searchResult = data && data.roles[0].permissions &&
        data.roles[0].permissions.length >= 1 && data.roles[0].permissions.filter((permission) =>  permission.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
        jobData.current = searchResult
      }else {
        jobData.current = data && data.roles[0].permissions
      }
    }
  const [activeToggle, setActiveToggle] = useState("update-image");
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };
  const handleSubmitMainData = async (data) => {
    if(data) {
        const adminFormData = new FormData();
        adminFormData.append("name" , data.name)
        adminFormData.append("phone" , data.phone)
        adminFormData.append("email" , data.email)
        dispatch(updateAdminFunc(
            {
                api : `${adminsApi}/${profileData.id}` , 
                data : adminFormData
            }
        ))
        .then (result => {
            if(result.payload.success === true) {
                dispatch(getProfileDataFunc(profile_API_URL));
                toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
            }
        })
    }
}

const handleSubmiteNewImage = async (data) => {
    if(adminImage !== "") {
        const adminFormData = new FormData();
        adminFormData.append("image" , adminImage);
        // adminFormData.append("_method" , "PUT");
        dispatch(updateAdminImageFunc(
            {
                api : `${adminsApi}/${profileData.id}/upload-image` , 
                data : adminFormData
            }
        ))
        .then (result => {
            if(result.payload.success === true) {
                dispatch(getProfileDataFunc(profile_API_URL));
                toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
            }
        })
    }else {
        toast.error(`Admin Image Must Not be empty!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    }
}
  return (
    <>
      <PageTitle activeMenu="Profile" motherMenu="Dashboard" />
        {
            getProfileLoadng
            ?
            <SpinnerLoading/>
            :
            (
                data
                ?
                <>
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="profile card card-body px-3 pt-3 pb-0">
                            <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                <img
                                    src={profileData.image_url ? profileData.image_url : defaultUserImage}
                                    className="img-fluid rounded-circle"
                                    alt="profile"
                                />
                                </div>
                                <div className="profile-details">
                                <div className="profile-name px-3 pt-2">
                                    <h4 className="text-primary mb-0">{profileData.name}</h4>
                                    <p>{profileRole.name}</p>
                                </div>
                                <div className="profile-email px-2 pt-2">
                                    <h4 className="text-muted mb-0">{profileData.email}</h4>
                                    <p>Email</p>
                                </div>
                                {
                                    checkPermissions(
                                        "update admins",
                                        <Dropdown className="dropdown ms-auto">
                                            <Dropdown.Toggle
                                            variant="primary"
                                            className="btn btn-primary light sharp i-false"
                                            data-toggle="dropdown"
                                            aria-expanded="true"
                                            >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="18px"
                                                height="18px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                                >
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                </g>
                                            </svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                            <Dropdown.Item className="dropdown-item"
                                                onClick={() => navigate(`/update-admin-pass/${profileData.id}`)}
                                            >
                                                <i className="fa-solid fa-lock text-primary me-2" />
                                                Change Password
                                            </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                                }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="profile-statistics">
                                                <div className="text-center">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h3 className="m-b-0">{rolePermissions.length}</h3><span>Permissions</span>
                                                        </div>
                                                        <div className="col">
                                                            <h3 className="m-b-0">{profileData.phone}</h3> <span>Phone</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>	
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header border-0 pb-0">
                                            <h5 className="text-primary">Today Highlights</h5>
                                        </div>	
                                        <div className="card-body pt-3"	>	
                                            <div className="profile-blog ">
                                                <img  src={profile01}  alt="profile" className="img-fluid  mb-4 w-100 " />
                                                <Link to="/post-details"> <h4>Darwin Creative Agency Theme</h4> </Link>
                                                <p className="mb-0">
                                                    A small river named Duden flows by their place and supplies
                                                    it with the necessary regelialia. It is a paradisematic
                                                    country, in which roasted parts of sentences fly into your
                                                    mouth.
                                                </p>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header border-0 pb-0">
                                            <h5 className="text-primary ">Interest</h5>
                                        </div>
                                        <div className="card-body pt-3">
                                            <div className="profile-interest ">
                                                <SRLWrapper options={options}>
                                                    <div className="row sp4">
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            <a href={profile02}> <img src={profile02} alt="profileImage" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            <a href={profile03}> <img src={profile03} alt="profile" className="img-fluid"/></a>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            <a href={profile04}><img src={profile04} alt="profile" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            {" "}
                                                            <a href={profile02}><img src={profile02} alt="profile" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            <a href={profile03} className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col" >
                                                                <img src={profile03} alt="profile"	className="img-fluid"/>	
                                                            </a>
                                                        </div>
                                                        <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                            <a href={profile04}	className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col">
                                                                <img  src={profile04} alt="profile"	className="img-fluid"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </SRLWrapper>
                                            </div>
                                        </div>	
                                    </div>
                                </div>	
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header border-0 pb-0">
                                            <h5 className="text-primary">Our Latest News</h5>
                                        </div>	
                                        <div className="card-body pt-3">
                                            <div className="profile-news">
                                            <div className="media pt-3 pb-3">
                                                <img src={profile05} alt="" className="me-3 rounded" width={75}/>
                                                <div className="media-body">
                                                    <h5 className="m-b-5">
                                                        <Link to="/post-details" className="text-black">
                                                            Collection of textile samples
                                                        </Link>
                                                    </h5>
                                                    <p className="mb-0">I shared this on my fb wall a few months back, and I thought. </p>
                                                </div>
                                            </div>
                                            <div className="media pt-3 pb-3">
                                                <img src={profile06} alt=""  className="me-3 rounded" width={75}/>
                                                <div className="media-body">
                                                    <h5 className="m-b-5">
                                                        <Link to="/post-details" className="text-black">
                                                        Collection of textile samples
                                                        </Link>
                                                    </h5>
                                                    <p className="mb-0">
                                                        I shared this on my fb wall a few months back, and I
                                                        thought.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="media pt-3 ">
                                                <img src={profile07} alt="" className="me-3 rounded" width={75} />
                                                <div className="media-body">
                                                    <h5 className="m-b-5">
                                                        <Link to="/post-details" className="text-black">
                                                            Collection of textile samples
                                                        </Link>
                                                    </h5>
                                                    <p className="mb-0">
                                                        I shared this on my fb wall a few months back, and I thought.
                                                    </p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>	
                            </div>	
                        </div>	
                        <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body">
                            <div className="profile-tab">
                                <div className="custom-tab-1">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item" onClick={() => setActiveToggle("update-image")}>
                                                <Link to="#upate-profile-image"  data-toggle="tab" className={`nav-link ${ activeToggle === "update-image" ? "active show" : ""}`}>Update Image</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${ activeToggle === "setting" ? "active show" : ""}`}>Setting</Link>
                                            </li>
                                        </ul>
                                    <div className="tab-content">
                                        <div id="upate-profile-image" className={`tab-pane fade ${ activeToggle === "update-image" ? "active show" : ""}`}>
                                            <div className="form-wizard mt-3">
                                                <form
                                                    className="change-admin-image-form"
                                                    id="change-admin-image-form"
                                                    onSubmit={handleSubmit((data) => {
                                                        handleSubmiteNewImage(data);
                                                    })}
                                                >
                                                    {
                                                        adminImage !== ""
                                                        ?
                                                        <div className="relative flex justify-center items-center mb-2">
                                                            <div className="relative w-[240px] h-[200px] ">
                                                                <img src={imageViwer} alt="product img" className="absolute top-0 left-0 w-full h-full" />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    <div className="w-full flex justify-center items-center mb-1">
                                                        <span className="text-sm">best size (200px * 180px)</span>
                                                    </div>
                                                    <div className="flex justify-center items-center">
                                                        <label for="file" className="file-upload-label">
                                                            <div className="file-upload-design">
                                                            <svg viewBox="0 0 640 512" height="1em">
                                                                <path
                                                                d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                                                                ></path>
                                                            </svg>
                                                            <span className="browse-button">Browse file</span>
                                                            </div>
                                                            <input 
                                                                id="file" 
                                                                type="file" 
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0];
                                                                    if (file) {
                                                                        const imageUrl = URL.createObjectURL(file);
                                                                        setImageViwer(imageUrl); 
                                                                    }                        
                                                                    setAdminImage(e.target.files[0]);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                    {
                                                        checkPermissions(
                                                            "update admins",
                                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                                <button 
                                                                type="submit"
                                                                form="change-admin-image-form"
                                                                className={`btn btn-primary sw-btn-next ms-1 ${!updateAdminImageLoading && adminImage !== "" ? "" : "pointer-events-none opacity-50"}`}
                                                                >
                                                                    {
                                                                        updateAdminImageLoading
                                                                        ?
                                                                        "loading.."
                                                                        :
                                                                        "Submit"
                                                                    }
                                                                </button>
                                                            </div>	
                                                        )
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                        <div id="profile-settings" className={`tab-pane fade ${ activeToggle === "setting" ? "active show" : ""}`}>
                                            <div className="pt-3">
                                                <div className="settings-form">
                                                    <h4 className="text-primary">Account Setting</h4>
                                                    <form
                                                    id="update-main-data"
                                                    onSubmit={handleSubmit((data) => {
                                                        handleSubmitMainData(data)
                                                    })}>
                                                        <div className="row">
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label className="form-label" >Name</label>
                                                                <input 
                                                                {...register("name" , {required : "name is required"})}
                                                                type="text" 
                                                                placeholder="Name" 
                                                                className="form-control"
                                                                />
                                                            </div>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label className="form-label">Email</label>
                                                                <input 
                                                                {...register("email" , {required : "email is required"})}
                                                                type="email" 
                                                                placeholder="Email" 
                                                                className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Phone</label>
                                                            <input 
                                                            {...register("phone" , {required : "phone is required"})}
                                                            type="text" 
                                                            placeholder="phone" 
                                                            className="form-control"
                                                            />
                                                        </div>
                                                        {
                                                            checkPermissions(
                                                                "update admins",
                                                                <button 
                                                                    className="btn btn-primary" 
                                                                    type="submit"
                                                                    form="update-main-data"
                                                                >
                                                                    {
                                                                        updateAdminLoading
                                                                        ?
                                                                        "loading.."
                                                                        :
                                                                        "Update"
                                                                    }
                                                                </button>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-12">
                        <div className="card">
                            <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">Permissions</h4>
                            <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                    onClick={() => search()}
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                            <div className="w-100 table-responsive">
                                <div id="example_wrapper" className="dataTables_wrapper">
                                <table id="example" className="display w-100 dataTable">
                                    <thead>
                                    <tr role="row">
                                        <th>#ID</th>
                                        <th>Name</th>
                                        <th>Guard Name</th>
                                        <th>Creaion Date</th>
                                        <th>Last Update</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        jobData.current !== null && jobData.current.map((permission , index) => {
                                        const date = new Date(permission.created_at)
                                        const year = date.getFullYear();
                                        const month = date.getMonth()+1;
                                        const day = date.getDate();
                                        const lastUpdateDate = new Date(permission.updated_at)
                                        const lastUpdateYear = lastUpdateDate.getFullYear();
                                        const lastUpdatemonth = lastUpdateDate.getMonth()+1;
                                        const lastUpdateDay = lastUpdateDate.getDate();
                                        return (
                                            <tr key={index}>
                                                <td>{permission.id}</td>
                                                <td>{permission.name}</td>
                                                <td>{permission.guard_name}</td>
                                                <td>{year} / {month} / {day}</td>
                                                <td>{lastUpdateYear} / {lastUpdatemonth} / {lastUpdateDay}</td>
                                            </tr>
                                        )
                                        }) 
                                    }
                                    </tbody>
                                </table>
                    
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {rolePermissions.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : rolePermissions.length}{" "}
                                    of {rolePermissions.length} entries
                                    </div>
                                    <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example5_paginate"
                                    >
                                    <Link
                                        className="paginate_button previous disabled"
                                    //   to="/table-datatable-basic"
                                        to="#"
                                        onClick={() =>
                                        activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </Link>
                                    <span>
                                        {paggination.map((number, i) => (
                                        <Link
                                            key={i}
                                        //   to="/table-datatable-basic"
                                            to="#"
                                            className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                        >
                                            {number}
                                        </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                    //   to="/table-datatable-basic"
                                        to="#"
                                        onClick={() =>
                                        activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </>
                :
                <FaildLoadData refetchDataFunc={() => dispatch(getProfileDataFunc(profile_API_URL))} />
            )
        }
    </>
  );
};
