import "./right-side-bar.css";
import React,{useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import United from "../../../../images/United.png";
import whatsappIcon from "../../../../media/images/whatsapp.png"
import { useDispatch, useSelector } from 'react-redux';
import { logoutFunc } from '../../../../store/custom-actions/auth/logout-actons/LogoutSlicer';
import Swal from 'sweetalert2';
import defaultUserImage from "../../../../media/images/user (2).png"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import emptyImg from "../../../../media/images/planet.png"
import { Spinner } from 'react-bootstrap';
import { ContextApi } from '../../../../store/ContextApi';
import { getNotficationsFunc } from '../../../../store/custom-actions/notfications-actions/GetNotficationSlicer';
import { readAllNotficationsFunc } from '../../../../store/custom-actions/notfications-actions/ReadAllNotficationsSlicer';
import { refreshTokenFunc } from '../../../../store/custom-actions/auth/RefreshTokenSlicer';
import { toast } from 'react-toastify';

function NotficationsList({show , onHide , notfications , notficationsLoading}) {
    const [animate , setAnimate] = useState();
    useEffect(() => {
        !notficationsLoading ? setAnimate(true) : setAnimate(false)
    },[notficationsLoading])
  return (
    <>
      <Modal
        className='z-[9999999999] notfications-parent'
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=''>
              <i className="fa-solid fa-bell mr-1"></i>
              Notfications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                notficationsLoading
                ?
                <>
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                </>
                :
                (   
                    notfications.length >= 1
                    ?
                    <div className='notfications-content overflow-x-hidden relative w-full max-h-[300px] overflow-y-auto'>
                        {
                            notfications &&
                            notfications.map((note , index) => {
                                const noteDate = new Date(note.created_at);
                                const sendingDay = noteDate.getDate();
                                const sendingMonth = noteDate.getMonth()+1;
                                const sendingYear = noteDate.getFullYear();
                                
                                const readingDate = new Date(note.read_at);
                                const readingDay = readingDate.getDate();
                                const readingMonth = readingDate.getMonth()+1;
                                const readingYear = readingDate.getFullYear();

                                const {data:noteData} = note;
                                const {title , subject , sender} = noteData;
                                return (
                                    <div style={{animationDelay : `.${index}s`}} key={index} className={`notfication-box transition-all duration-500 relative w-full px-2 py-3 mb-1 flex justify-between items-center text-black bg-blue-100 rounded-md ${animate ? "active" : ""}`}>
                                      <div className='relative flex flex-col'>
                                        <h4 className={"m-0 text-primary"}>
                                          <i className="fa-brands fa-cuttlefish mr-1"></i>
                                          {title}
                                        </h4>
                                        <h5 className='m-0 text-[#555758] flex flex-col'>
                                          {subject}
                                          <div className='relative flex flex-col mt-1'>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-user mr-1 opacity-90"></i>
                                              <p className='m-0'>Sender  <strong className='text-primary'>{sender}</strong></p>
                                            </div>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-calendar-days mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>Sent on {`${sendingYear} / ${sendingMonth} / ${sendingDay}`}</p>
                                            </div>
                                            <div className='read-note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-eye mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>Read in {`${readingYear} / ${readingMonth} / ${readingDay}`}</p>
                                            </div>
                                          </div>
                                        </h5>
                                      </div>
                                      <i className="fa-solid fa-comments text-primary text-[18px]"></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='relative flex flex-col justify-center items-center'>
                        <img src={emptyImg} alt="empty img" className='w-[100px]' />
                        <p className='mt-3'>No Notfications</p>
                    </div>
                )
            }
        </Modal.Body>
        <Modal.Footer>
          <Button className=" bg-blue-400 border-blue-400 text-white" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const RightSidebar = ({ onNote , showRightSidebar}) => {
	const [rightSelect, setRightSelect] = useState('Eng');
	const user = JSON.parse(window.localStorage.getItem("user"));
	const dispatch = useDispatch();
	const {loading:logoutLoading} = useSelector(
		state => state.logoutSlicer
	)
	const navigate = useNavigate();
	const {loading:getNotficationsLoading , data} = useSelector(state => state.getNotficationSlicer);
	const {loading:readAllNotsLoading} = useSelector(state => state.readAllNotficationsSlicer);
	const {loading:refreshTokenLoading} = useSelector(state => state.refreshTokenSlicer);
	const [showNotficationsList , setShowNotficationsList] = useState();
	const contextApi = useContext(ContextApi)
	const notficationsApi = `${contextApi.dashboard_URL_API}/notifications`;
	const refreshTokenApi = `${contextApi.dashboard_URL_API}/refresh`;
	const [notficationsList , setNotficationsList] = useState([]);
	const [unReadNotficationsCount , setUnReadNotficationsCount] = useState(0);
	const [unReadNotfications , setUnReadNotfications] = useState([]);
	const [readNotfications , setReadNotfications] = useState([]);
	const logoutFunction = () => {
		try {
			dispatch(logoutFunc())
		}catch(error) {
			Swal.fire({
				title: "Error!",
				text: error,
				icon: "error"
			});
		}
	}
	const getNotfications = async () => {
		dispatch(getNotficationsFunc(notficationsApi))
	}
	const readNotficationsFunction = async () => {
		dispatch(readAllNotficationsFunc(notficationsApi))
		.then(result => result.payload.success === true && getNotfications())
	}
	useEffect(() => {
		getNotfications()
	},[])
	useEffect(() => {
		data && data.read_notifications && setNotficationsList(data.read_notifications)
		data && data.unread_notifications_count && setUnReadNotficationsCount(data.unread_notifications_count)
		data && 
		data.unread_notifications && 
		setUnReadNotfications(data.unread_notifications)
		data && 
		data.read_notifications && 
		setReadNotfications(data.read_notifications)
	},[data])
	const refreshFunction = () => {
		try {
			dispatch(refreshTokenFunc(refreshTokenApi))
			.then (result => {
				if(result.payload.success === true) {
					toast.success(`${result.payload.message} !`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					})
				}
			})
		}
		catch(error) {
			Swal.fire({
				title: "Error!",
				text: error,
				icon: "error"
			});
		}
	}

	return (
	  <>
		{/* <div className={`right-sidebar shadow-lg shadow-slate-500 flex flex-col justify-between rounded-tl-[20px] rounded-bl-[20px] items-center md:translate-x-0 h-[85vh] top-[15vh] md:top-0 md:h-[100vh] right-0 fixed ${!showRightSidebar ? "translate-x-0 opacity-100" : "right-[-50px] opacity-0"} bg-white z-[99] transition-all duration-500`}> */}
		  <div className={`z-[999] right-sidebar shadow-lg shadow-slate-500 flex flex-col justify-between rounded-tl-[20px] rounded-bl-[20px] items-center md:translate-x-0 top-[15vh] md:top-0 md:h-[100vh] right-0 fixed ${!showRightSidebar ? "translate-x-0 opacity-100" : "right-[-50px] opacity-0"} bg-white z-[99] transition-all duration-500`}style={{zIndex: 9999999}}>				<ul>
					<Dropdown as="li" className="nav-item dropdown header-profile">
						<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
							<img src={user.image ? user.image : defaultUserImage} width={20} alt="" />
						</Dropdown.Toggle>
						<Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
							<Link to="/admin-profile" className="dropdown-item ai-icon" style={{display : "flex"}}>
								<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1" width={18} height={18} viewBox="0 0 24 24" fill="none"
									stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
								>
									<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
									<circle cx={12} cy={7} r={4} />
								</svg>
								<span className="ms-2">Profile </span>
							</Link>
							<Link to="#" className="dropdown-item ai-icon" style={{display : "flex"}}
								onClick={() => {
									refreshFunction()
								}}
							>
								<i className="fa-solid fa-arrows-rotate mr-1 text-green-500"></i>
								<span className="ms-2">
									{
										refreshTokenLoading
										?
										"loading.."
										:
										"Refresh Session"
									}
								</span>
							</Link>
							<Link  className="dropdown-item ai-icon" style={{display : "flex"}} onClick={() => {
								logoutFunction()
							}}>
								<svg
								id="icon-logout" xmlns="http://www.w3.org/2000/svg"
								className="text-danger me-1" width={18} height={18} viewBox="0 0 24 24" 
								fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
								>
									<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
									<polyline points="16 17 21 12 16 7" />
									<line x1={21} y1={12} x2={9} y2={12} />
								</svg>
								<span className="ms-2" >
									{
										logoutLoading
										?
										"loading.."
										:
										"Logout"
									}
								</span>
							</Link>
							{/* <LogoutPage /> */}
						</Dropdown.Menu>
					</Dropdown> 	
				</ul>
				<div className="sidebar-social-link ">
					<ul className="">
						<Dropdown as="li" className="nav-item dropdown notification_dropdown ">
							<Dropdown.Toggle variant="" as="a" className="nav-link  ai-icon i-false c-pointer" role="button" data-toggle=""
								onClick={() => navigate("/send-notfication")}
							>
								<i className="fa-solid fa-paper-plane text-black text-[18px]"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu  className="dropdown-menu dropdown-menu-end" style={{height : "100vh" , inset : "-196px calc(100% + -40px) 0 auto !important"}}>
								<PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 ps--active-y height370" id="DZ_W_TimeLine02">
									<h4 className="text-center border-bottom pb-2">Notications</h4>	
									<ul className="timeline">
										<li>
											<div className="timeline-badge primary" />
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>10 minutes ago</span>
												<h6 className="mb-0"> Youtube, a video-sharing website, goes live{" "} <strong className="text-primary">$500</strong>.</h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge info"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>20 minutes ago</span>
												<h6 className="mb-0">
													New order placed{" "}
													<strong className="text-info">#XF-2356.</strong>
												</h6>
												<p className="mb-0"> Quisque a consequat ante Sit amet magna at volutapt...</p>
											</Link>
										</li>
										<li>
											<div className="timeline-badge danger"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>30 minutes ago</span>
											  <h6 className="mb-0">
												john just buy your product{" "}
												<strong className="text-warning">Sell $250</strong>
											  </h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge success"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
											  <span>15 minutes ago</span>
											  <h6 className="mb-0">
												StumbleUpon is acquired by eBay.{" "}
											  </h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge warning"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
											  <span>20 minutes ago</span>
											  <h6 className="mb-0">
												Mashable, a news website and blog, goes live.
											  </h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge dark"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>20 minutes ago</span>
												<h6 className="mb-0">Mashable, a news website and blog, goes live.</h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge primary" />
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>10 minutes ago</span>
												<h6 className="mb-0"> Youtube, a video-sharing website, goes live{" "} <strong className="text-primary">$500</strong>.</h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge info"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
												<span>20 minutes ago</span>
												<h6 className="mb-0">
													New order placed{" "}
													<strong className="text-info">#XF-2356.</strong>
												</h6>
												<p className="mb-0"> Quisque a consequat ante Sit amet magna at volutapt...</p>
											</Link>
										</li>
										<li>
											<div className="timeline-badge danger"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
											  <span>30 minutes ago</span>
											  <h6 className="mb-0">
												john just buy your product{" "}
												<strong className="text-warning">Sell $250</strong>
											  </h6>
											</Link>
										</li>
										<li>
											<div className="timeline-badge success"></div>
											<Link className="timeline-panel c-pointer text-muted" to="#">
											  <span>15 minutes ago</span>
											  <h6 className="mb-0">
												StumbleUpon is acquired by eBay.{" "}
											  </h6>
											</Link>
										</li>
									</ul>
									<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
										<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}/>
									</div>
									<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
										<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }}/>
									</div>
								</PerfectScrollbar>
							</Dropdown.Menu>

						</Dropdown>	
						<Dropdown as="li" className="nav-item dropdown notification_dropdown ">
							<Dropdown.Toggle variant="" as="a" className="nav-link  i-false c-pointer" onClick={() => navigate("/users-messages")}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M16.9394 3.57129C18.2804 3.57129 19.5704 4.06765 20.5194 4.95828C21.4694 5.84704 22.0004 7.04579 22.0004 8.30073V15.6993C22.0004 18.3122 19.7304 20.4287 16.9394 20.4287H7.0604C4.2694 20.4287 2.0004 18.3122 2.0004 15.6993V8.30073C2.0004 5.68783 4.2594 3.57129 7.0604 3.57129H16.9394ZM18.5304 9.69615L18.6104 9.62123C18.8494 9.34964 18.8494 8.9563 18.5994 8.68471C18.4604 8.54517 18.2694 8.45994 18.0704 8.44121C17.8604 8.43091 17.6604 8.4974 17.5094 8.62852L13.0004 12C12.4204 12.4505 11.5894 12.4505 11.0004 12L6.5004 8.62852C6.1894 8.41312 5.7594 8.44121 5.5004 8.69407C5.2304 8.94693 5.2004 9.34964 5.4294 9.6306L5.5604 9.75234L10.1104 13.077C10.6704 13.4891 11.3494 13.7138 12.0604 13.7138C12.7694 13.7138 13.4604 13.4891 14.0194 13.077L18.5304 9.69615Z" fill="#130F26"/>
										{/* {
										parseInt(unReadNotficationsCount) >= 1
										&&
										<span className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2">{unReadNotficationsCount}</span>
										} */}
								</svg>								
							</Dropdown.Toggle>
						</Dropdown>	

						<Dropdown as="li" className="nav-item dropdown notification_dropdown">
							<Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a"
								onClick={() => {
									readNotficationsFunction()
									setShowNotficationsList(true);
								}}
							>
								<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z" fill="#130F26"/>
								</svg>								
								{
								parseInt(unReadNotficationsCount) >= 1
								&&
								<span className="absolute top-[0px] right-[10px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2">{unReadNotficationsCount}</span>
								}
							</Dropdown.Toggle>
						</Dropdown>
					</ul>	
					
				</div>
				<Link 
					to={"/new-whatsapp-message"}
					className="search-coundry d-flex align-items-center"
				>
					<img src={whatsappIcon} alt="whatapp-icon" className="w-[35px]"/>
				</Link>
			</div>
			<NotficationsList
				show = {showNotficationsList}
				onHide = {() => setShowNotficationsList(false)}
				notficationsLoading = {readAllNotsLoading}
				notfications = {readNotfications}
			/>
	  </>
	);
};

export default RightSidebar;