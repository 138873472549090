import { useForm } from "react-hook-form";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import { useContext, useEffect, useState } from "react";
import { getCouponsFunc } from "../../../store/custom-actions/coupons-actions/GetCouponsSlicer";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { getSingleCouponFunc } from "../../../store/custom-actions/coupons-actions/GetSingleCouponSlicer";
import EmptyData from "../../components/empty-data/EmptyData";
import { updateCouponFunc } from "../../../store/custom-actions/coupons-actions/UpdateCouponSlicer";

export default function UpdateCouponFrom() {
    const {couponId} = useParams();
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:getSingleCouponLoading , data:singleCouponData} = useSelector(state => state.getSingleCouponSlicer);
    const {loading:updateCouponLoading} = useSelector(state => state.updateCouponSlicer);
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const singleCoupon_API_URL = `${contextApi.dashboard_URL_API}/coupons/${couponId}`;
    const dispatch = useDispatch();
    const nivigate = useNavigate();
    const[coupon , setCoupon] = useState({})

    useEffect(() => {
        const getCoupon = async () => {
          try {
            dispatch(getSingleCouponFunc(singleCoupon_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCoupon();
      }, []);
    useEffect(() => {
        singleCouponData && setCoupon(singleCouponData);
        if(singleCouponData) {
            setValue("code" , singleCouponData.code);
            setValue("discount" , singleCouponData.discount);
            setValue("count_used" , singleCouponData.count_used);
            setValue("type" , singleCouponData.type);
            setValue("start_date" , singleCouponData.start_date);
            setValue("end_date" , singleCouponData.end_date);
        }
    },[singleCouponData])
    const handleSubmitData = (data) => {
        if(data.start_date > data.end_date) {
            toast.error(`End Date Must be More than Start Date!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }else {
            dispatch(updateCouponFunc({api : singleCoupon_API_URL , data : {...data}}))
            .then(result => {
                if(result.payload.success === true) {
                  const successMessage = result.payload.message;
                  dispatch(getCouponsFunc(coupons_API_URL));
                  reset();
                  nivigate("/coupons");
                  toast.success(`${successMessage}!`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                  })
              }
          })
        }
    }
    
    return (
        <>
            <PageTitle motherMenu={"Dashboard"} activeMenu={"Update Coupon"} />
            {
                getSingleCouponLoading
                ?
                <SpinnerLoading/>
                :
                (
                    singleCouponData
                    ?
                    (
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    id="update-coupon-form"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitData(data)
                                    })}
                                >
                                    <div className='row'>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label>Code</label>
                                            {errors.code?.message && (
                                            <p className="text-red-600">{errors.code?.message}</p>
                                            )}
                                            <input
                                            
                                            {...register("code" , {required : "Code should not be empty!"})}
                                            type="text"
                                            className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label>Discount</label>
                                            {errors.discount?.message && (
                                            <p className="text-red-600">{errors.discount?.message}</p>
                                            )}
                                            <input
                                            
                                            {...register("discount" , {required : "Discount should not be empty!"})}
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label>Usage Limit</label>
                                            {errors.count_used?.message && (
                                            <p className="text-red-600">{errors.count_used?.message}</p>
                                            )}
                                            <input
                                            
                                            {...register("count_used" , {required : "Usage limit should not be empty!"})}
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            />
                                        </div>
                                        <div
                                            className={`form-group mb-3 col-sm-12 col-md-6`}
                                        >
                                            <label>Discount Type</label>
                                            {errors.type?.message && (
                                            <p className="text-red-600">{errors.type?.message}</p>
                                            )}
                                            <select
                                                className={`form-control col-sm-12`}
                                                {...register("type" , {required : "Discount Type limit should not be empty!"})}
                                            >
                                                <option value={"fixed"}>Fixed</option>
                                                <option value={"percentage"}>Percentage</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label>From :</label>
                                            {errors.start_date?.message && (
                                            <p className="text-red-600">{errors.start_date?.message}</p>
                                            )}
                                            <input
                                            
                                            {...register("start_date" , {required : "Start Date should not be empty!"})}
                                            type="date"
                                            className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label>To :</label>
                                            {errors.end_date?.message && (
                                            <p className="text-red-600">{errors.end_date?.message}</p>
                                            )}
                                            <input
                                            
                                            {...register("end_date" , {required : "End Date should not be empty!"})}
                                            type="date"
                                            className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <Button type="submit" form="update-coupon-form" className={` form-control ${updateCouponLoading ? "opacity-80 pointer-events-none" : ""} w-fit`} style={{width : "fit-content"}}>
                                            {!updateCouponLoading ? (
                                            "Submit"
                                            ) : (
                                            <>
                                                <div className="relative flex justify-center items-center">
                                                loading..
                                                </div>
                                            </>
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                    )
                    :
                    <EmptyData emptyText={"No Data"} />
                )
            }
        </>
    )
}