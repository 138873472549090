import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { fetchAllCategoriesFunc } from '../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import { addNewCategoryFunc } from '../../../store/custom-actions/categories-actions/AddCategorySlicer';
import { toast } from 'react-toastify';
import { getLanguagesFunc } from '../../../store/custom-actions/language/GetLanguagesSlicer';
import { ContextApi } from '../../../store/ContextApi';

function AddCategoryForm({showCategoryForm , hideCategoryForm , categoriesApi}) {
    const contextApi = useContext(ContextApi)
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {addCategoryLoading} = useSelector(state => state.addCategorySlicer);
    const[Activaty , setActivaty] = useState(0);
    const dispatch = useDispatch();
    const {loading:getLanguagesLoading , data:languagesData} = useSelector(state => state.getLanguageSlicer)
    const languages_API_URL = `${contextApi.dashboard_URL_API}/languages`;
    const [languagesList , setLanguagesList] = useState([]);
    useEffect(() => {
        const getLanguages = async () => {
          try {
            dispatch(getLanguagesFunc(languages_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        showCategoryForm && getLanguages();
      }, [showCategoryForm]);
      useEffect(() => {
        if(languagesData) {
          setLanguagesList(languagesData)
        }
      },[languagesData])
    const handleSubmitData = (data) => {
      console.log(data)
      if(data) {
        const objWillSend = {};
        for(const [key , value] of Object.entries(data)) {
          const langObj = {
            [key] : {
              name : value
            }
          }
          Object.assign(objWillSend , langObj)
        }
        dispatch(addNewCategoryFunc({categoryApi : categoriesApi , categoryData : {...objWillSend , active : Activaty}}))
        .then(result => {
            if(result.payload.success === true) {
              dispatch(fetchAllCategoriesFunc(categoriesApi))
              hideCategoryForm();
              reset()
              toast.success(`${result.payload.message} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
            }
        })
      }
    }
    // useEffect(() => {
    //   HandleErrorsListFunc(errors);
    // },[errors])
    
  return (
    <>
      <Modal show={showCategoryForm} onHide={hideCategoryForm}>
        <Modal.Header closeButton>
          <Modal.Title>New <span className='text-orange-700'>Category</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
            {
              getLanguagesLoading
              ?
              <p>Loading Languages..</p>
              :
              (
                languagesData
                ?
                (
                  languagesList && languagesList.length >= 1
                  ?
                  languagesList.map((lang , index) => (
                  <div className="form-group mb-3 col-sm-12">
                    <label>{`${lang.name} Name`}</label>
                    {errors.name?.message && (
                    <p className="text-red-600">{errors.name?.message}</p>
                    )}
                    <input
                    
                    {...register(lang.slug , {required : `${lang.name} name should not be empty!`})}
                    type="text"
                    className="form-control"
                    />
                  </div>
                  ))
                  :
                  <p>No Languages!</p>
                )
                :
                <p>Faild Load Languages!</p>
              )
            }
              {/* <div className="form-group mb-3 col-sm-12">
                  <label>Category Name</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("name" , {required : "name should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div> */}
              <div className="form-group mb-3 col-sm-12">
                  <input
                  id="cat-active"
                  type="checkbox"
                  className="m-0 mr-3 translate-y-1"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  />
                  <label htmlFor='cat-active' className='cursor-pointer'>Activaty</label>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCategoryForm}>
            Close
          </Button>
          <Button type="submit" form="new-category-form" className={`${addCategoryLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!addCategoryLoading ? (
            "Add Category"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddCategoryForm;