import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addNewSliderFunc = createAsyncThunk(
    "add new ui slider function",
    async ({api , data}) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.post(api , data , config);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
);

const addNewSliderSlicer = createSlice({
    name : "add new ui slider slicer",
    initialState : {
        loading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewSliderFunc.pending , (state)=>{
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(addNewSliderFunc.rejected , (state)=>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(addNewSliderFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default addNewSliderSlicer.reducer;