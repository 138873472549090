import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getContactSettingsFunc } from "../../../store/custom-actions/settings-actions/contact-settings/GetContactSettingsSlicer";
import { postContactSettingsFunc } from "../../../store/custom-actions/settings-actions/contact-settings/PostContactSettingsSlicer";
function ContactSettingsPage() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/contact-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getContactSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postContactSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const getSettings = async () => {
        dispatch(getContactSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("contact_phone" , data.contact_phone);
            setValue("contact_email" , data.contact_email);
            setValue("contact_address" , data.contact_address);
        }
    },[data])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const formData = new FormData();
            formData.append("contact_phone" , data.contact_phone)
            formData.append("contact_email" , data.contact_email)
            formData.append("contact_address" , data.contact_address)
            dispatch(postContactSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Contact Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
                        onClick={() => window.history.back()}
                    >
                        <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Back</span>
                        <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                            <i className="fa-solid fa-arrow-left text-white"></i>
                        </span>
                    </button>
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-4">
                            <div className="relative settings-form-content">
                                <div className="new-settings-form-div relative w-full ">
                                    <form 
                                        className="new-settings-form"
                                        id="new-settings-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitSettings(data)
                                        })}
                                    >
                                        {
                                            <>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-phone-volume mr-1"></i>
                                                            Phone Number
                                                        </label>
                                                        {errors.contact_phone?.message && (
                                                        <p className="text-red-600">
                                                            {errors.contact_phone?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("contact_phone")}
                                                        type="tel"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-envelope mr-1"></i>
                                                            Email Address
                                                        </label>
                                                        {errors.contact_email?.message && (
                                                        <p className="text-red-600">
                                                            {errors.contact_email?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("contact_email")}
                                                        type="email"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-map-location-dot mr-1"></i>
                                                            Address
                                                        </label>
                                                        {errors.contact_address?.message && (
                                                        <p className="text-red-600">
                                                            {errors.contact_address?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("contact_address")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default ContactSettingsPage;