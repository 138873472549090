import React,{useContext, useEffect, useState}  from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import {Tab, Nav} from 'react-bootstrap';
import 'react-modal-video/css/modal-video.min.css';
import pic3 from './../../../images/courses/pic3.jpg';
import pic4 from './../../../images/courses/pic4.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { ContextApi } from '../../../store/ContextApi';
import { getSingleProductFunc } from '../../../store/custom-actions/products-actions/GetSingleProductSlicer';
import { AboutTabContent } from '../../components/Courses/CourseDetail1';
import SpinnerLoading from '../../components/spinner-loading/SpinnerLoading';
import swal from 'sweetalert';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import { deleteProductFunc } from '../../../store/custom-actions/products-actions/DeleteProductSlicer';
import { getAllProductsFunc } from '../../../store/custom-actions/products-actions/GetAllProductsSlicer';
import ProductDetailsDescion from './ProductDetailsDescion';
import checkPermissions from '../../global-functions/checkPermissionsFunc';


const ProductDetails =()=> {
	const [isOpen, setOpen] = useState(false);
    const contextApi = useContext(ContextApi);
	const site_global_currency = contextApi.homeSettings.site_global_currency;
    const prodctsApi = `${contextApi.dashboard_URL_API}/products`;
    const {productId} = useParams();
    const [product , setProduct] = useState({});
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const {loading:getSingleProductLoading , data} = useSelector(
        state => state.getSingleProductSlicer
    )
	const {loading:deleteProductLoading} = useSelector(
        state => state.deleteProductSlicer
    )

    useEffect(() => {
        const getSingleProduct = async () => {
            dispatch(getSingleProductFunc(`${prodctsApi}/${productId}`))
            .then((result) => console.log(result))
        }
        getSingleProduct()
    },[])
    useEffect(() => {
        data && setProduct(data);
    },[data])

    const deleteItemFunction = async () => {
        swal({
          title: "Confirm Item Deletion",
          text: `Once deleted, This Item will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteProductFunc(`${prodctsApi}/${productId}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getAllProductsFunc(prodctsApi));
				navigate("/products")
              }
            })
          } else {
            swal("Your Product is safe!");
          }
        });
      };
	return(
		<>
        {
            getSingleProductLoading
            ?
            <SpinnerLoading/>
            :
			<div className="row">
				<div className="col-xl-8 col-xxl-7">
					<div className="card">
						<div className="card-body">
							<div className="course-content d-flex justify-content-between flex-wrap" style={{width : "100%"}}>
								<div className='w-full'>
									<div className='relative w-full flex justify-between items-center mb-1'>
										<h3>{product.title}</h3>
										{
											checkPermissions(
												["delete products" , "update products"],
												<ProductDetailsDescion
													item_id = {productId}
													deleteFunction = {deleteItemFunction}
												/>
											)
										}
									</div>
									<ul className="d-flex align-items-center raiting my-0 flex-wrap">
										{
											parseInt(product.descount) > 0
											?
											<li>
												Price : {product.price} {site_global_currency}
												<del className='ml-1 text-red-500'>{product.descount}$</del>
												{/* <i className="fas fa-star text-orange ms-2"></i> */}
											</li>
											:
											<li>
												Price : {product.price} {site_global_currency}
											</li>
										}
										<li>
											SKU : {product.sku}
										</li>
										<li className='flex items-center'>
											Status : <span className={`ml-1 relative flex justify-center text-sm items-center rounded-lg p-1 text-white ${product.active === 1 ? "bg-green-500" : "bg-red-500"}`}>
												{
													product.active === 1
													?
													"Active"
													:
													"Not Active"
												}
											</span>
										</li>
									</ul>
								</div>
							</div>
							<div className="video-img style-1">
								<div className="view-demo">
									<img src={product.image_url ? product.image_url : "https://i.pinimg.com/564x/97/78/26/97782616d8c5b44108cef9ff8b25a392.jpg"} alt="product-img" />
									{/* <div className="play-button text-center">
										<Link to={'#'} className="popup-youtube" onClick={()=> setOpen(true)}>
											<svg width="96" height="96" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fillRule="evenodd" clipRule="evenodd" d="M16.6154 0C7.41046 0 0 7.41043 0 16.6154V55.3846C0 64.5896 7.41046 72 16.6154 72H55.3846C64.5895 72 72 64.5896 72 55.3846V16.6154C72 7.41043 64.5895 0 55.3846 0H16.6154ZM26.259 19.3846C26.5876 19.3728 26.9098 19.4783 27.1677 19.6821L46.5523 34.9129C47.2551 35.4672 47.2551 36.5328 46.5523 37.0871C40.0921 42.1633 33.6278 47.2366 27.1677 52.3125C26.2575 53.034 24.9168 52.3814 24.9231 51.22V20.7692C24.9226 20.0233 25.5135 19.4141 26.259 19.3846Z" fill="white"/>
											</svg>
										</Link>
									</div>	 */}
								</div>	
							</div>
							<Tab.Container defaultActiveKey='About'>
								<div className="course-details-tab style-2 mt-4">
									<nav>
										<Nav as='div' className="nav nav-tabs tab-auto" id="nav-tab">
											<Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='About' type="button">About</Nav.Link>
											<Nav.Link as='button' className="nav-link" id="nav-reviews-tab" eventKey='Review' type="button">More Info</Nav.Link>
										</Nav>
									</nav>
									<Tab.Content className="tab-content" id="nav-tabContent">
										<Tab.Pane  id="nav-about" eventKey='About'>
											<div className="about-content">
												<h4>About This Item</h4>
												<div dangerouslySetInnerHTML={{__html : product.description}} />
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey='Review'>
											{/* {reviewsData.map((item, index)=>(
												<div className="user-pic2" key={index}>
													<div className="d-flex align-items-center">
														<img src={item.image} alt="" />
														<div className="ms-3">
															<h4>{item.title}</h4>
															<ul className="d-flex align-items-center raiting my-0 flex-wrap">
																<li><span className="font-w500">5.0</span><i className="fas fa-star star-orange ms-2 me-1"></i>
																	<i className="fas fa-star star-orange me-1"></i>
																	<i className="fas fa-star star-orange me-1"></i>
																	<i className="fas fa-star star-orange me-1"></i>
																	<i className="fas fa-star star-orange"></i>
																</li>
																<li>{item.commentTime}</li>
															</ul>
														</div>
													</div>
													<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
												</div>
											))} */}
												<ul className='pt-3'>
													{
														product.product_attributes
														? 
														Object.entries(product.product_attributes).map(([key, value]) => {
															return <p>{key} : {value}</p>
														})	
														:
														<li className='mt-1'>No Data</li>
													}
												</ul>
										</Tab.Pane>
										<Tab.Pane id="nav-discussion" eventKey='Discussion'>
											<div className="about-content">
												<AboutTabContent title='About This Course' />
												<AboutTabContent title="Course’s Objectives" />
											</div>
										</Tab.Pane>
									</Tab.Content>
								</div>
							
							</Tab.Container>	
						</div>	
					</div>
				</div>
				{/* <div className="col-xl-4 col-xxl-5">
					<div className="card h-auto">
						<div className="card-header border-0 pb-0">
							<h4>Progress</h4>
							<DropDownBlog />
						</div>
						<div className="card-body pt-0">
							<div className="progress-box">
								<div className="progress ">
									<div className="progress-bar bg-primary" style={{width:'15%', height:'12px', borderRadius:'4px' }} role="progressbar"></div>
								</div>
								
								<div className="d-flex align-items-center justify-content-between">
									<h5 className="mb-0 fs-14 font-w600">Full-Stack Web Developer</h5>
									<span className="font-w600">10/110</span>
								</div>
							</div>
						</div>
					</div>
					<div className="custome-accordion">
						<Accordion className="accordion" defaultActiveKey="0">	
							{accordionBlog.map((data,i)=>(
								<Accordion.Item className="card" key={i} eventKey={`${i}`}>
									<Accordion.Header as="h2" className="accordion-header border-0">
										<span className="acc-heading">{data.title}</span>
										<span className="ms-auto">(1/110)</span>
									</Accordion.Header>    
									<Accordion.Collapse eventKey={`${i}`} id="collapseOne" >
										<div className="accordion-body card-body pt-0">
											<div className="acc-courses active">
												<div className="d-flex justify-content-between align-items-center">
													<div className="d-flex align-items-center">
														<span className="acc-icon">
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z" fill="var(--primary)"/>
															</svg>
														</span>	
														<h4 className="m-0">Introduction</h4>
													</div>	
													<span>1:00</span>
												</div>
											</div>
											<SmallCardBlog title='Getting Started' />
											<SmallCardBlog title='Tools' />
											<SmallCardBlog title='Install Tools' />
											<SmallCardBlog title='Plugins' />										
										</div>
									</Accordion.Collapse>	
								</Accordion.Item>
								
							))}
						</Accordion>
					</div>						
				</div> */}
			</div>	
        }
			<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="e6MhFghdQps" onClose={() => setOpen(false)} />
			<LoadingScreen
                loading={deleteProductLoading}
            />
		</>
	)
}
export default ProductDetails;