import faildImg from "../../../media/images/planet.png"
export default function FaildLoadData({refetchDataFunc}) {
    return (
        <>
            <div className="relative flex flex-col justify-center items-center w-full h-[70vh]">
                <div className="faild-img relative mb-2">
                    <img src={faildImg} alt="faild img" className="w-[200px]" />
                </div>
                <p>Faild Loading Data <span
                    className="text-blue-500 ml-1 cursor-pointer"
                    onClick={() => refetchDataFunc()}
                >Try agian</span></p>
            </div>
        </>
    )
}