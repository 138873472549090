import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';
import { getCitiesFunc } from '../../../store/custom-actions/cities-actions/GetCitiesSlicer';
import { addCityFunc } from '../../../store/custom-actions/cities-actions/AddCitySlicer';
import { getCountriesFunc } from '../../../store/custom-actions/countries-actions/GetContriesSlicer';
import { getStatesFunc } from '../../../store/custom-actions/states-actions/GetStatesSlicer';

function NewCityForm({show , onHide , defultCountryId , defultStateId}) {
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:addCityLoading} = useSelector(state => state.addCitySlicer);
    const contextApi = useContext(ContextApi);
    const cities_API_URL = `${contextApi.dashboard_URL_API}/cities`;
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const states_API_URL = `${contextApi.dashboard_URL_API}/states`;
    const [Activaty , setActivaty] = useState(0);
    const dispatch = useDispatch();
    const {loading:getCountriesLoading , data:countriesData} = useSelector(state => state.getCountriesSlicer)
    const {loading:getStatesLoading , data:statesData} = useSelector(state => state.getStatesSlicer)
    const [countriesList , setCountriesList] = useState([]);
    const [statesList , setStatesList] = useState([]);
    const [allValide , setAllValide] = useState(false);
    const [thereIsCountryAndState , setThereIsCountryAndState] = useState(false);
    const [countryId , setCountryId] = useState("");
    const [stateId , setStateId] = useState("");
    const [city , setCity] = useState("")
    const [zip , setZip] = useState("")
    useEffect(() => {
        const getCountries = async () => {
          try {
            dispatch(getCountriesFunc(countries_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCountries();
      }, []);
      useEffect(() => {
        countriesData && setCountriesList(countriesData);
      }, [countriesData]);
      useEffect(() => {
          const getStates = async () => {
            try {
              countryId !== "" && dispatch(getStatesFunc(`${states_API_URL}?country_id=${countryId}`))
            } catch (error) {
              console.log(error);
            }
          };
          getStates();
        }, [countryId]);
        useEffect(() => {
          statesData && setStatesList(statesData);
        }, [statesData]);

    useEffect(() => {
      countryId !== "" && stateId !== ""  ? setThereIsCountryAndState(true) : setThereIsCountryAndState(false)
    },[countryId , stateId])
    const handleSubmitData = (data) => {
      dispatch(addCityFunc({api : cities_API_URL , data : {...data , active : Activaty , country_id : defultCountryId ? defultCountryId : countryId , state_id : defultStateId ? defultStateId : stateId}}))
      .then(result => {
          if(result.payload.success === true) {
            const successMessage = result.payload.message;
            dispatch(getCitiesFunc(cities_API_URL))
            onHide();
            reset();
            setCountryId("");
            setStateId("");
            setCity("")
            setZip("")
            toast.success(`${successMessage} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
      })
    }
    useEffect(() => {
      city !== "" && zip !== "" ? setAllValide(true) : setAllValide(false)
    },[city , zip])
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-city-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
            <div className="form-group mb-3 col-sm-12 col-md-8">
                <label>City</label>
                {errors.name?.message && (
                <p className="text-red-600">{errors.name?.message}</p>
                )}
                <input
                {...register("name" , {required : "name should not be empty!"})}
                type="text"
                onChange={(e) => setCity(e.target.value)}
                className="form-control"
                value={city}
                />
            </div>
            <div className="form-group mb-3 col-sm-12 col-md-4">
                <label>ZIP</label>
                {errors.code?.message && (
                <p className="text-red-600">{errors.code?.message}</p>
                )}
                <input
                {...register("code" , {required : "ZIP should not be empty!"})}
                type="text"
                className="form-control"
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                />
            </div>
          </div>
          {
            defultStateId
            ?
            ""
            :
            <div className='row'>
                  {
                      getCountriesLoading ? (
                          <div className="form-group flex items-center">
                              <p>loading Countries..</p>
                          </div>
                      ) : countriesData &&
                          countriesList &&
                          countriesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>No Countries</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 ${"col-sm-12"}`}
                          >
                          <label>Country</label>
                          <select
                              className={`form-control col-sm-12 ${allValide ? "" : "pointer-events-none opacity-70"}`}
                              onChange={(e) => setCountryId(e.target.value)}
                          >
                              <option selected value={""} disabled>
                                  Choose Country..
                              </option>
                              {
                              countriesList &&
                              countriesList.map((cat, index) => {
                                  return (
                                  <option key={index} value={cat.id}>
                                      {cat.name}
                                  </option>
                                  );
                              })
                              }
                          </select>
                          </div>
                      )
                  }
                  {
                      getStatesLoading ? (
                          <div className="form-group flex items-center">
                              <p>loading States..</p>
                          </div>
                      ) : statesData &&
                          statesList &&
                          statesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>No States</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 ${"col-sm-12"}`}
                          >
                          <label>State</label>
                          <select
                              className={`form-control col-sm-12 ${countryId === "" ? " pointer-events-none opacity-70" : ""}`}
                              onChange={(e) => setStateId(e.target.value)}
                          >
                              <option selected value={""} disabled>
                                  Choose State..
                              </option>
                              {
                              statesList &&
                              statesList.map((state, index) => {
                                  return (
                                  <option key={index} value={state.id}>
                                      {state.name}
                                  </option>
                                  );
                              })
                              }
                          </select>
                          </div>
                      )
                  }
            </div>
          }
          <div className='row'>
            <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                  <input
                //   id="cat-active"
                  type="checkbox"
                  id="customCheckBox5"
                  className="m-0 mr-3 form-check-input cursor-pointer"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  />
                  <label htmlFor='customCheckBox5' className='cursor-pointer ml-1'>Activaty</label>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="new-city-form" className={`${addCityLoading || (!thereIsCountryAndState && !defultStateId) ? "opacity-80 pointer-events-none" : ""}`}>
          {!addCityLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewCityForm;