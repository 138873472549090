import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const readAllNotficationsFunc = createAsyncThunk(
    "read all notfications function",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.post(api , {} , config);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
);

const readAllNotficationsSlicer = createSlice({
    name : "read all notfications slicer",
    initialState : {
        loading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(readAllNotficationsFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(readAllNotficationsFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(readAllNotficationsFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default readAllNotficationsSlicer.reducer;