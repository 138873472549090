import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';
import { getCitiesFunc } from '../../../store/custom-actions/cities-actions/GetCitiesSlicer';
import { updateCityFunc } from '../../../store/custom-actions/cities-actions/UpdateCitySlicer';
import { getStatesFunc } from '../../../store/custom-actions/states-actions/GetStatesSlicer';
import { getCountriesFunc } from '../../../store/custom-actions/countries-actions/GetContriesSlicer';

function UpdateCityForm({show , onHide , cityId , oldName , oldCode , oldStatus  , oldStateId , oldCountryId}) {
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:updateCityLoading} = useSelector(state => state.updateCitySlicer);
    const contextApi = useContext(ContextApi);
    const cities_API_URL = `${contextApi.dashboard_URL_API}/cities`;
    const city_API_URL = `${contextApi.dashboard_URL_API}/cities/${cityId}`;
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const states_API_URL = `${contextApi.dashboard_URL_API}/states`;
    const {loading:getCountriesLoading , data:countriesData} = useSelector(state => state.getCountriesSlicer)
    const {loading:getStatesLoading , data:statesData} = useSelector(state => state.getStatesSlicer)
    const [countriesList , setCountriesList] = useState([]);
    const [statesList , setStatesList] = useState([]);
    const [Activaty , setActivaty] = useState(0);
    const [countryId , setCountryId] = useState();
    const [stateId , setStateId] = useState();
    const dispatch = useDispatch();
    
    useEffect(() => {
        setValue("name" , oldName);
        setValue("code" , oldCode);
        setActivaty(oldStatus)
        setStateId(oldStateId)
        setCountryId(oldCountryId)
      },[setValue , oldName , oldCode , oldStatus , oldStateId , oldCountryId])

      useEffect(() => {
          const getCitites = async () => {
            try {
              dispatch(getCountriesFunc(countries_API_URL))
            } catch (error) {
              console.log(error);
            }
          };
          getCitites();
        }, []);
        useEffect(() => {
          countriesData && setCountriesList(countriesData);
        }, [countriesData]);
        
        useEffect(() => {
            const getStates = async () => {
              try {
                dispatch(getStatesFunc(`${states_API_URL}?country_id=${countryId}`))
              } catch (error) {
                console.log(error);
              }
            };
            getStates();
          }, [countryId]);
          useEffect(() => {
            statesData && setStatesList(statesData);
          }, [statesData]);
    const handleSubmitData = (data) => {
      dispatch(updateCityFunc({api : city_API_URL , data : {...data , active : Activaty , country_id : countryId , state_id : stateId}}))
      .then(result => {
          if(result.payload.success === true) {
            const successMessage = result.payload.message;
            dispatch(getCitiesFunc(cities_API_URL))
            onHide();
            reset();
            toast.success(`${successMessage} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
      })
    }
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="update-country-from"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12 col-md-8">
                  <label>City</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("name" , {required : "name should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-4">
                  <label>Code</label>
                  {errors.code?.message && (
                  <p className="text-red-600">{errors.code?.message}</p>
                  )}
                  <input
                  
                  {...register("code" , {required : "Code should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
          </div>
          <div className='row'>
          {
            // oldStateId
            // ?
            // ""
            // :
            <div className='row'>
                  {
                      getCountriesLoading ? (
                          <div className="form-group flex items-center">
                              <p>loading Countries..</p>
                          </div>
                      ) : countriesData &&
                          countriesList &&
                          countriesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>No Countries</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 ${"col-sm-12"}`}
                          >
                          <label>Country</label>
                          <select
                              className={`form-control col-sm-12`}
                              onChange={(e) => setCountryId(e.target.value)}
                              defaultValue={countryId}
                          >
                              {
                              countriesList &&
                              countriesList.map((cat, index) => {
                                  return (
                                  <option key={index} value={cat.id}>
                                      {cat.name}
                                  </option>
                                  );
                              })
                              }
                          </select>
                          </div>
                      )
                  }
                  {
                      getStatesLoading ? (
                          <div className="form-group flex items-center">
                              <p>loading States..</p>
                          </div>
                      ) : statesData &&
                          statesList &&
                          statesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>No States</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 ${"col-sm-12"}`}
                          >
                          <label>State</label>
                          <select
                            onChange={(e) => setStateId(e.target.value)}
                              className={`form-control col-sm-12 ${countryId === "" ? " pointer-events-none opacity-70" : ""}`}
                              defaultValue={stateId}
                              disabled = {countryId === oldCountryId}
                          >
                              {
                              statesList &&
                              statesList.map((state, index) => {
                                  return (
                                  <option key={index} value={state.id}>
                                      {state.name}
                                  </option>
                                  );
                              })
                              }
                          </select>
                          </div>
                      )
                  }
            </div>
          }
          </div>
          <div className='row'>
            <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                <input
              //   id="cat-active"
                type="checkbox"
                id="customCheckBox5"
                className="m-0 mr-3 form-check-input cursor-pointer"
                onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                checked = {Activaty === 1 ? true : false}
                />
                <label htmlFor='customCheckBox5' className='cursor-pointer ml-1'>Activaty</label>
            </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="update-country-from" className={`${updateCityLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!updateCityLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCityForm;