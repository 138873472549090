import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext , useEffect, useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';
import { updateUserFunc } from '../../../store/custom-actions/users-actions/UpdateUserSlicer';
import { getUsersFunc } from '../../../store/custom-actions/users-actions/GetUsersSlicer';

export default function UpdateUserStatus({show , onHide , userId , oldActivaty , oldVerifing , refetchFunc}) {
    const {handleSubmit } = useForm();
    const {loading:updateStatusLoading} = useSelector(state => state.updateUserSlicer)
    const contextApi = useContext(ContextApi);
    const users_API_URL = `${contextApi.dashboard_URL_API}/users`;
    const user_API_URL = `${contextApi.dashboard_URL_API}/users/${userId}`;
    const dispatch = useDispatch();
    const [Activaty , setActivaty] = useState();
    const [emailVerified , setEmailVerified] = useState();

    useEffect(() => {
        setActivaty(oldActivaty);
        setEmailVerified(oldVerifing);
    },[oldActivaty , oldVerifing])
    const handleSubmitData = () => {
        dispatch(updateUserFunc({api : user_API_URL , data : {active : Activaty , is_email_verified : emailVerified }}))
        .then(result => {
            if(result.payload.success === true) {
              const successMessage = result.payload.message;
              dispatch(getUsersFunc(users_API_URL))
              refetchFunc && refetchFunc()
              onHide();
              toast.success(`${successMessage} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
          }
        })
    }
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="update-user-status"
          onSubmit={handleSubmit(() => {
              handleSubmitData()
          })}
          >
            <div className='row'>
            <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                  <input
                  type="checkbox"
                  id="customCheckBox5"
                  className="m-0 mr-3 form-check-input cursor-pointer"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  checked = {Activaty === 1 ? true : false}
                  />
                  <label htmlFor='customCheckBox5' className='cursor-pointer ml-1'>
                    {
                        Activaty === 1
                        ?
                        "Active User"
                        :
                        "Not Active User"
                    }
                  </label>
              </div>
              <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                  <input
                  type="checkbox"
                  id="customCheckBox6"
                  className="m-0 mr-3 form-check-input cursor-pointer"
                  onChange={(e) => e.target.checked ? setEmailVerified(1) : setEmailVerified(0)}
                  checked = {emailVerified === 1 ? true : false}
                  />
                  <label htmlFor='customCheckBox6' className='cursor-pointer ml-1'>
                    {
                        emailVerified
                        ?
                        "Email Verified"
                        :
                        "Email Not Verified"
                    }
                  </label>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="update-user-status" className={`${updateStatusLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!updateStatusLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}