import React, { Fragment, useContext, useEffect, useState } from "react";
// images
import qrcode from "../../../images/qr.png";
//import logo from "../../../../../images/logo.png";
import logoText from "../../../images/logo-text.png";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import EmptyData from "../../components/empty-data/EmptyData";
import { getSingleOrderFunc } from "../../../store/custom-actions/orders-actions/GetSingleOrderSlicer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ContextApi } from "../../../store/ContextApi";
import { Button, Modal } from "react-bootstrap";
import { updateOrderRefundFunc } from "../../../store/custom-actions/orders-actions/UpdateOrderRefund";
import Swal from "sweetalert2";

const UpdateRefundOrderPage = () => {
    const {orderId} = useParams();
    const {loading , data:orderData} = useSelector(
        state => state.getSingleOrderSlicer
    )
    const {loading:updateRefundLoading ,} = useSelector(
        state => state.updateOrderRefundSlicer
    )
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    const order_API_URL = `${contextApi.dashboard_URL_API}/orders/${orderId}`;
    const refund_order_API_URL = `${contextApi.dashboard_URL_API}/orders/${orderId}/refund-order`;
    const dispatch = useDispatch();
    const [order , setOrder] = useState({})
    const [year , stYear] = useState("");
    const [month , setMonth] = useState("");
    const [day , setDay] = useState("");
    const [orderProducts , setOrderProducts] = useState([]);
    const [orderOwn , setorderOwn] = useState({});
    const [productWhichUpdateQuantity , setProductWhichUpdateQuantity] = useState();
    const [productWhichUpdateId , setProductWhichUpdateId] = useState();
    const [productWhichUpdate , setProductWhichUpdate] = useState({});
    const [showUpdateRefund , setShowUpdateRefund] = useState();
    const [totalRefudProductsPrice , settotalRefudProductsPrice] = useState(0);
    const [refundQuantity , setRefundQuantity] = useState();
    const [productsObj , setProductsObj] = useState({});
    const navigate = useNavigate();
    const hideRefundForm = () => {
        setShowUpdateRefund(false);
        setRefundQuantity("");
        setProductWhichUpdateId("");
    };
    useEffect(() => {
        const getSingleOrder = async () => {
          try {
            dispatch(getSingleOrderFunc(order_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getSingleOrder();
    }, []);
    useEffect(() => {
        if(orderData) {
            setOrder(orderData)
            const date = new Date(orderData.created_at)
            const year = date.getFullYear();
            const month = date.getMonth()+1;
            const day = date.getDate();
            stYear(year);
            setMonth(month);
            setDay(day);
            setOrderProducts(orderData.products);
            if(orderData.products.length >=1) {
                const allrefund = orderProducts.flatMap(product => parseInt(product.pivot.refund_qyt) * parseInt(product.pivot.price))
                // const refundTotal = orderProducts.reduce(one , toe)
                console.log(allrefund)
                if(allrefund.length < 1) {
                    settotalRefudProductsPrice(0)
                }else {
                    const price = allrefund.reduce((first , second) => first+second);
                    console.log(price)
                    settotalRefudProductsPrice(price)
                }
            }
            setorderOwn(orderData.user) 
        }
    },[orderData])
    const switchOrderStatus = (status) => {
        switch(status) {
          case 0:
            return (
                "Pending"
            );
          case 1:
            return (
                "Active"
            );
          case 2:
            return (
                "Delivery"
            );
          case 3:
            return (
                "completed"
            );
          default :
            return (
                "Pending"
            );
            
        }
      }
    const pushProductFunction = () => {
        const productObj = {
          [productWhichUpdateId] : refundQuantity
        };
        setProductsObj(prevProductsObj => ({
            ...prevProductsObj,
            ...productObj
        }));
        hideRefundForm();
    };

    useEffect(() => {
        console.log(productsObj);
    },[productsObj])
      const handleUpdateRefund = () => {
        try {
            dispatch(updateOrderRefundFunc({api : refund_order_API_URL , data : {products : productsObj}}))
            .then(result => {
                if(result.payload.success === true) {
                    dispatch(getSingleOrderFunc(order_API_URL));
                    navigate(`/invoice-page/${orderId}`)
                }
            })
        }
        catch(error) {
            Swal.fire({
                title: "Error!",
                text: error,
                icon: "error"
            });
        }
      }
      const getRefundQuantity = (product) => {
        if (productsObj.hasOwnProperty(product.id)) {
          return productsObj[product.id];
        }
        return product.pivot.refund_qyt;
      };
      const getRefundQuantityForInput = (product) => {
        if (Object.keys(productsObj) && productsObj.hasOwnProperty(product.id)) {
          return productsObj[product.id];
        }
        if(product && Object.keys(product).length >= 1) {
            return product.pivot.refund_qyt;
        }
      };
      const calckrefundTotal = (product) => {
        if (Object.keys(productsObj) && productsObj.hasOwnProperty(product.id)) {
          return `${productsObj[product.id] * product.pivot.price} ${site_global_currency}`;
        }
        if(product && Object.keys(product).length >= 1) {
            return `${product.pivot.refund_qyt * product.pivot.price} ${site_global_currency}`;
        }
      };
      
  return (
    <Fragment>
      <PageTitle activeMenu="Update Refund Products" motherMenu="Orders" />
      {
        loading
        ?
        <SpinnerLoading/>
        :
        (
            orderData && Object.keys(order).length >= 1
            ?
            <div className="row">
                <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                    {" "}
                    Invoice : #{order.invoice_no} <strong>{year} / {month} / {day}</strong>{" "}
                    <span className="float-right">
                        <strong>Status:</strong> {switchOrderStatus(order.status)}
                    </span>{" "}
                    <Link
                    to={"#"} 
                    className={`btn btn-primary btn-sm mt-2 ${Object.keys(productsObj).length < 1 ? "pointer-events-none opacity-50" : ""}`}
                    onClick={() => {
                        handleUpdateRefund()
                    }}
                    >
                        {
                            updateRefundLoading
                            ?
                            "loading.."
                            :
                            "Update Invoice"
                        }
                    </Link>
                    </div>
                    <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th className="center">#</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th className="right">Unit Cost</th>
                            <th className="center">Refund</th>
                            <th className="center">SKU</th>
                            <th className="right">Total</th>
                            <th className="right">Refund Total</th>
                            <th className="right">Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderProducts
                                &&
                                orderProducts.length >=1
                                &&
                                orderProducts.map((product , index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="center">{product.id}</td>
                                            <td className="left strong">{product.title}</td>
                                            <td className="left">{product.pivot.qyt}</td>
                                            <td className="left">{product.pivot.price} ${site_global_currency}</td>
                                            <td className="left">
                                                {
                                                    getRefundQuantity(product)
                                                }
                                            </td>
                                            <td className="left">{product.sku}</td>
                                            <td className="left">
                                                {parseInt(product.pivot.qyt) * parseInt(product.pivot.price)
                                                } ${site_global_currency}</td>
                                            <td className="left">
                                                {
                                                    calckrefundTotal(product)
                                                }
                                            </td>
                                            <td className="left">
                                            <Link
                                            to={"#"} 
                                            onClick={() => {
                                                setProductWhichUpdateQuantity(product.pivot.qyt)
                                                setProductWhichUpdateId(product.id)
                                                setProductWhichUpdate(product)
                                                setShowUpdateRefund(true);
                                            }}
                                            className="btn btn-primary btn-sm mt-2"
                                            >Update Refund</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-5"> </div>
                        <div className="col-lg-4 col-sm-5 ms-auto">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <EmptyData/>
        )
      }
                    <Modal show={showUpdateRefund} onHide={hideRefundForm}>
                      <Modal.Header closeButton>
                        <Modal.Title>Refund Quantity</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="form-group mb-3 col-sm-12">
                              <label>Choose Quantity</label>
                              {
                                productWhichUpdateQuantity < refundQuantity
                                ||
                                refundQuantity < 0
                                ?
                                <p className="mb-1 text-red-500">Refund Quantity must be more than zero and less than product quantity!</p>
                                :
                                ""
                              }
                              
                              <input
                                className="form-control"
                                type="number"
                                min={0}
                                max={productWhichUpdateQuantity}
                                onChange={(e) => {
                                    setRefundQuantity(e.target.value);
                                }}
                                defaultValue={getRefundQuantityForInput(productWhichUpdate)}
                              />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hideRefundForm}>
                          Close
                        </Button>
                        {
                            productWhichUpdateQuantity >= refundQuantity
                            ?
                            <Button variant="primary" onClick={() => {
                                pushProductFunction()
                            }}>
                                Done
                            </Button>
                            :
                            ""
                        }
                      </Modal.Footer>
                    </Modal>
    </Fragment>
  );
};

export default UpdateRefundOrderPage;
