import { useNavigate, useParams } from "react-router-dom"
import { getSingleOrderFunc } from "../../../store/custom-actions/orders-actions/GetSingleOrderSlicer";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { useForm } from "react-hook-form";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { updateOrderFunc } from "../../../store/custom-actions/orders-actions/UpdateOrderSlicer";
import { toast } from "react-toastify";

export default function UpdatePaymnetStatus() {
    const {orderId} = useParams();
    const {loading , data:orderData} = useSelector(
        state => state.getSingleOrderSlicer
    )
    const {loading:updateOrderLoading} = useSelector(
        state => state.updateOrderSlicer
    )
    const navigate = useNavigate();
    const {register , handleSubmit , formState : {errors} , setValue} = useForm()
    const contextApi = useContext(ContextApi);
    const order_API_URL = `${contextApi.dashboard_URL_API}/orders/${orderId}`;
    const dispatch = useDispatch();

    useEffect(() => {
        const getSingleOrder = async () => {
          try {
            dispatch(getSingleOrderFunc(order_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getSingleOrder();
    }, []);
    useEffect(() => {
        if(orderData) {
            setValue("payment_status" , orderData.payment_status)
        }
    },[orderData])
    const handleSubmitData = (data) => {
        const formData = new FormData();
        formData.append("payment_status" , data.payment_status)
        formData.append("_method" , "PUT")
        dispatch(updateOrderFunc({api : order_API_URL , data : formData}))
        .then(result => {
            if(result.payload.success === true) {
              const successMessage = result.payload.message;
              navigate("/orders")
              toast.success(`${successMessage} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
          }
        })
      }
    return (
        <>
            <PageTitle motherMenu={"Orders"} activeMenu={"Update Order Status"} />
            {
                loading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-wizard ">
                                    <form
                                        className="update-order-form"
                                        id="update-order-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitData(data);
                                        })}
                                    >
                                            <div className={`form-group mb-3 "col-sm-12"}`}>
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Payment Status
                                                </label>
                                                {errors.payment_status?.message && (
                                                <p className="text-red-600">
                                                    {errors.payment_status?.message}
                                                </p>
                                                )}
                                                <select
                                                    {...register("payment_status", {
                                                        required: "payment status is required !"
                                                    })}
                                                    className="form-control"
                                                >
                                                    <option value={"pending"}>pending</option>
                                                    <option value={"complete"}>Complete</option>
                                                    <option value={"failed"}>Failed</option>
                                                </select>
                                            </div>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button 
                                                type="submit"
                                                className={`btn btn-primary sw-btn-next ms-1 ${updateOrderLoading ? "pointer-events-none opacity-50" : ""}`}>
                                                    {
                                                        updateOrderLoading
                                                        ?
                                                        "Loading.."
                                                        :
                                                        "Submit"
                                                    }
                                                </button>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}