import { configureStore } from "@reduxjs/toolkit";
// auth
import loginSlicer from "./custom-actions/auth/login-actions/LoginSlicer";
import logoutSlicer from "./custom-actions/auth/logout-actons/LogoutSlicer";
import refreshTokenSlicer from "./custom-actions/auth/RefreshTokenSlicer";

// products
import getAllProductsSlicer from "./custom-actions/products-actions/GetAllProductsSlicer";
import addProductSlicer from "./custom-actions/products-actions/AddProductSlicer";
import deleteProductSlicer from "./custom-actions/products-actions/DeleteProductSlicer";
import getSingleProductSlicer from "./custom-actions/products-actions/GetSingleProductSlicer";
import updateProductSlicer from "./custom-actions/products-actions/UpdateProductSlicer";

// categories
import getAllCategoriesSlicer from "./custom-actions/categories-actions/GetAllCategoriesSlicer";
import addCategorySlicer from "./custom-actions/categories-actions/AddCategorySlicer";
import deleteCategorySlicer from "./custom-actions/categories-actions/DeleteCategoriesSlicer";
import updateCategorySlicer from "./custom-actions/categories-actions/UpdateCategorySlicer";

// sub categories
import getSubCategoriesSlicer from "./custom-actions/sub-categories-actions/GetSubCategoriesSlicer";
import addSubCategorySlicer from "./custom-actions/sub-categories-actions/AddSubCategorySlicer";
import deleteSubCategorySlicer from "./custom-actions/sub-categories-actions/DeleteSubCategoriesSlicer";
import updateSubCategorySlicer from "./custom-actions/sub-categories-actions/UpdateSubCategorySlicer";

// countries
import getCountriesSlicer from "./custom-actions/countries-actions/GetContriesSlicer";
import addCountrySlicer from "./custom-actions/countries-actions/AddCountryFunc";
import getSingleCountrySlicer from "./custom-actions/countries-actions/GetSingleCountrySlicer";
import deleteCountrySlicer from "./custom-actions/countries-actions/DeleteCountrySlicer";
import updateCountrySlicer from "./custom-actions/countries-actions/UpdateCountrySlicer";

// cities
import getCitiesSlicer from "./custom-actions/cities-actions/GetCitiesSlicer";
import addCitySlicer from "./custom-actions/cities-actions/AddCitySlicer";
import getSingleCitySlicer from "./custom-actions/cities-actions/GetSingleCitySlicer";
import updateCitySlicer from "./custom-actions/cities-actions/UpdateCitySlicer";
import deleteCitySlicer from "./custom-actions/cities-actions/DeleteCitySlicer";

//states
import getStatesSlicer from "./custom-actions/states-actions/GetStatesSlicer";
import addStateSlicer from "./custom-actions/states-actions/AddStateSlicer";
import getSingleStateSlicer from "./custom-actions/states-actions/GetSingleStateSlicer";
import updateStateSlicer from "./custom-actions/states-actions/UpdateStateSlicer";
import deleteStateSlicer from "./custom-actions/states-actions/DeleteStateSlicer";

//copons
import getCouponsSlicer from "./custom-actions/coupons-actions/GetCouponsSlicer";
import addCouponSlicer from "./custom-actions/coupons-actions/AddCouponSlicer";
import deleteCouponSlicer from "./custom-actions/coupons-actions/DeleteCouponSlicer";
import updateCouponSlicer from "./custom-actions/coupons-actions/UpdateCouponSlicer";
import getSingleCouponSlicer from "./custom-actions/coupons-actions/GetSingleCouponSlicer";

//users
import getUsersSlicer from "./custom-actions/users-actions/GetUsersSlicer";
import getSingleUserSlicer from "./custom-actions/users-actions/GetSingleUserSlicer";
import deleteUserSlicer from "./custom-actions/users-actions/DeleteUserSlicer";
import updateUserSlicer from "./custom-actions/users-actions/UpdateUserSlicer";

//roles
import getRolesSlicer from "./custom-actions/roles-actions/GetRolesSlicer";
import getSingleRoleSlicer from "./custom-actions/roles-actions/GetSingleRoleSlicer";
import addRoleSlicer from "./custom-actions/roles-actions/AddRoleSlicer";
import updateRoleSlicer from "./custom-actions/roles-actions/UpdateRoleSlicer";
import deleteRoleSlicer from "./custom-actions/roles-actions/DeleteRoleSlicer";

//permissions
import getPermissionsSlicer from "./custom-actions/permissions-actions/GetPermissionsSlicer";

//admins
import getAdminsSicer from "./custom-actions/admins-actions/GetAdminsSlicer";
import updateAdminSlicer from "./custom-actions/admins-actions/UpdateAdminSlicer";
import addAdminSlicer from "./custom-actions/admins-actions/AddAdminSlicer";
import getSingleAdminSlicer from "./custom-actions/admins-actions/GetSingleAdminSlicer";
import changeAdminPassSlicer from "./custom-actions/admins-actions/ChangeAdminPassSlicer";
import updateAdminImageSlicer from "./custom-actions/admins-actions/UpdateAdminImageSlicer";
import deleteAdminSlicer from "./custom-actions/admins-actions/DeleteAdminSlicer";
import getProfileDataSlicer from "./custom-actions/admins-actions/GetProfleDataSlicer";

//orders
import getOrdersSlicer from "./custom-actions/orders-actions/GetOrdersSlicer";
import getSingleOrderSlicer from "./custom-actions/orders-actions/GetSingleOrderSlicer";
import updateOrderSlicer from "./custom-actions/orders-actions/UpdateOrderSlicer";
import updateOrderRefundSlicer from "./custom-actions/orders-actions/UpdateOrderSlicer";
import deleteOrderSlicer from "./custom-actions/orders-actions/DeleteOrderSlicer";

//news letters
import getNewsLettersSlicer from "./custom-actions/news-letters-actions/GetNewsLettersSlicer";
import addNewEmailSlicer from "./custom-actions/news-letters-actions/AddNewEmailSlicer";
import postNewMessageSlicer from "./custom-actions/news-letters-actions/PostNewMessageSlicer";
import verifyEmailSlicer from "./custom-actions/news-letters-actions/VerifyEmailSlicer";
import deleteEmailSlicer from "./custom-actions/news-letters-actions/DeleteEmailSlicer";

//settings
import getBasicSettingsSlicer from "./custom-actions/settings-actions/basic-settings/GetBasicSettingsSlicer";
import postBasicSettingsSlicer from "./custom-actions/settings-actions/basic-settings/PostBasicSettingsSlicer";
//currency
import getCurrencySettingsSlicer from "./custom-actions/settings-actions/currency-settings-actions/GetCurrencySettingsSlicer";
import postCurrencySttingsSlicer from "./custom-actions/settings-actions/currency-settings-actions/PostCurrencySttingsSlicer";
//smtp
import getSmtpSettingsSlicer from "./custom-actions/settings-actions/smtp/GetSmtpSettingsSlicer";
import postSmtpSettingsSlicer from "./custom-actions/settings-actions/smtp/PostSmtpSettingsSlicer";
import testSmtpSettingsSlicer from "./custom-actions/settings-actions/smtp/TestSmtpSettingsSlicer";
//logo
import getLogoSettingsSlicer from "./custom-actions/settings-actions/logo-settings/GetLogoSettingsSlicer";
import postLogoSettingsSlicer from "./custom-actions/settings-actions/logo-settings/PostLogoSettingsSlicer";
//tax
import postTaxFeeSettingsSlicer from "./custom-actions/settings-actions/tax-fee-settings/PostTaxFeeSettingsSlicer";
import getTaxFeeSettingsSlicer from "./custom-actions/settings-actions/tax-fee-settings/GetTaxFeeSettingsSlicer";
//orders outside
import getOrdersOutSideSettingsSlicer from "./custom-actions/settings-actions/orders-outside/GetOrdersOutSideSettingsSlicer";
import postOrdersOutSideSettingsSlicer from "./custom-actions/settings-actions/orders-outside/PostOrdersOutSideSettingsSlicer";
//paymnet
import getPaymentSettingsSlicer from "./custom-actions/settings-actions/payment-gateway-settings/GetPaymentSettingsSlicer";
import postPaymentSettingsSlicer from "./custom-actions/settings-actions/payment-gateway-settings/PostPaymentSettingsSlicer";
import getHomeDataSlicer from "./custom-actions/settings-actions/dahsboard/GetHomeDataSlicer";
import getSiteDataSlicer from "./custom-actions/settings-actions/dahsboard/GetSiteDataSlicer";
//sochial
import getSocialSettingsSlicer from "./custom-actions/settings-actions/social-settings/GetSocialSettingsSlicer";
import postSocialSettingsSlicer from "./custom-actions/settings-actions/social-settings/PostSocialSettingsSlicer";
//contact
import getContactSettingsSlicer from "./custom-actions/settings-actions/contact-settings/GetContactSettingsSlicer";
import postContactSettingsSlicer from "./custom-actions/settings-actions/contact-settings/PostContactSettingsSlicer";

//sliders
import getSlidersSlicer from "./custom-actions/sliders/GetSlidersSlicer"
import addNewSliderSlicer from "./custom-actions/sliders/AddSliderSlicer"
import updateSliderSlicer from "./custom-actions/sliders/UpdateSliderSlicer"
import deleteSliderSlicer from "./custom-actions/sliders/DeleteSliderSlicer"

//notfications
import getNotficationSlicer from "./custom-actions/notfications-actions/GetNotficationSlicer";
import readAllNotficationsSlicer from "./custom-actions/notfications-actions/ReadAllNotficationsSlicer";
import sendNotficationSlicer from "./custom-actions/notfications-actions/SendNotficationSlicer";

//contact
import readMessageSlicer from "./custom-actions/contact-actions/ReadMessageSlicer";
import getContactsSlicer from "./custom-actions/contact-actions/GetContactsSlicer";
import deleteContactSlicer from "./custom-actions/contact-actions/DeleteContactSlicer";

//whatsapp
import postWhatsappMessageSlicer from "./custom-actions/whatsapp-actions/PostWhatsappMessageSlicer";

//languages
import getLanguageSlicer from "./custom-actions/language/GetLanguagesSlicer";
import getSingleLanguageSlicer from "./custom-actions/language/GetSingleLanguageSlicer";
import translateTextSlicer from "./custom-actions/language/TranslateTextSlicer";
import newTranslateTextSlicer from "./custom-actions/language/NewTranslateTextSlicer";
import addNewLanguageSlicer from "./custom-actions/language/AddNewLanguageSlicer";
import makeLangDefaultSlicer from "./custom-actions/language/MakeLangDefaultSlicer";
import deleteLanguageSlicer from "./custom-actions/language/DeleteLanguageSlicer";
import getAppLanguageSlicer from "./custom-actions/language-settings/GetAppLanguageSlicer";
export const CustomStore = configureStore({
    reducer : {
        loginSlicer,
        logoutSlicer,
        refreshTokenSlicer,
        // products slicer
        getAllProductsSlicer,
        addProductSlicer,
        deleteProductSlicer,
        getSingleProductSlicer,
        updateProductSlicer,

        //categories
        getAllCategoriesSlicer,
        addCategorySlicer,
        deleteCategorySlicer,
        updateCategorySlicer,

        //sub categories
        getSubCategoriesSlicer,
        addSubCategorySlicer,
        deleteSubCategorySlicer,
        updateSubCategorySlicer,

        //countries
        getCountriesSlicer,
        getSingleCountrySlicer,
        deleteCountrySlicer,
        updateCountrySlicer,
        addCountrySlicer,

        //cities
        getCitiesSlicer,
        addCitySlicer,
        getSingleCitySlicer,
        updateCitySlicer,
        deleteCitySlicer,

        //states
        getStatesSlicer,
        addStateSlicer,
        getSingleStateSlicer,
        updateStateSlicer,
        deleteStateSlicer,

        //coupons
        addCouponSlicer,
        deleteCouponSlicer,
        getCouponsSlicer,
        updateCouponSlicer,
        getSingleCouponSlicer,

        //users
        getUsersSlicer,
        getSingleUserSlicer,
        deleteUserSlicer,
        updateUserSlicer,

        //roles
        getRolesSlicer,
        getSingleRoleSlicer,
        updateRoleSlicer,
        addRoleSlicer,
        deleteRoleSlicer,
        //permissions
        getPermissionsSlicer,

        //admins
        getAdminsSicer,
        getSingleAdminSlicer,
        updateAdminSlicer,
        addAdminSlicer,
        changeAdminPassSlicer,
        updateAdminImageSlicer,
        deleteAdminSlicer,
        getProfileDataSlicer,

        //orders
        getOrdersSlicer,
        deleteOrderSlicer,
        getSingleOrderSlicer,
        updateOrderSlicer,
        updateOrderRefundSlicer,

        //news letters
        getNewsLettersSlicer,
        addNewEmailSlicer,
        postNewMessageSlicer,
        verifyEmailSlicer,
        deleteEmailSlicer,

        //settings
        getBasicSettingsSlicer,
        postBasicSettingsSlicer,
        //currency
        getCurrencySettingsSlicer,
        postCurrencySttingsSlicer,
        //smtp
        getSmtpSettingsSlicer,
        postSmtpSettingsSlicer,
        testSmtpSettingsSlicer,
        //logo
        getLogoSettingsSlicer,
        postLogoSettingsSlicer,
        //tax
        getTaxFeeSettingsSlicer,
        postTaxFeeSettingsSlicer,
        //orders oustide
        getOrdersOutSideSettingsSlicer,
        postOrdersOutSideSettingsSlicer,
        //payment
        getPaymentSettingsSlicer,
        postPaymentSettingsSlicer,
        //home
        getHomeDataSlicer,
        getSiteDataSlicer,
        //sochial
        getSocialSettingsSlicer,
        postSocialSettingsSlicer,
        //conact
        getContactSettingsSlicer,
        postContactSettingsSlicer,
        
        //notfications
        getNotficationSlicer,
        readAllNotficationsSlicer,
        sendNotficationSlicer,

        //contact
        readMessageSlicer,
        getContactsSlicer,
        deleteContactSlicer,

        //whatsapp
        postWhatsappMessageSlicer,

        //languages
        getLanguageSlicer,
        getSingleLanguageSlicer,
        translateTextSlicer,
        addNewLanguageSlicer,
        makeLangDefaultSlicer,
        deleteLanguageSlicer,
        newTranslateTextSlicer,
        getAppLanguageSlicer,

        //sliders
        getSlidersSlicer,
        addNewSliderSlicer,
        updateSliderSlicer,
        deleteSliderSlicer,
    }
})