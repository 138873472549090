import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { ContextApi } from "../../../store/ContextApi";
import EmptyData from "../../components/empty-data/EmptyData";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { Link } from "react-router-dom";
import { getSubCategoriesFunc } from "../../../store/custom-actions/sub-categories-actions/GetSubCategoriesSlicer";
import { deleteSubCategoryFunc } from "../../../store/custom-actions/sub-categories-actions/DeleteSubCategoriesSlicer";
import UpdateSubCategoryForm from "./UpdateSubCategory";
import NewSCategory from "./NewSubCategory";
import { toast } from "react-toastify";
import PageTitle from "../../layouts/PageTitle";
import checkPermissions from "../../global-functions/checkPermissionsFunc";


function SubCategoriesPage() {
    const {loading , data} = useSelector(state => state.getSubCategoriesSlicer)
    const {loading:deleteCategoriesLoading} = useSelector(state => state.deleteSubCategorySlicer)
    const contextApi = useContext(ContextApi);
    const subCategories_API_URL = `${contextApi.dashboard_URL_API}/sub-categories`;
    const [categoriesList , setCategoriesList] = useState([]);
    const [mainCateoryId , setMainCateoryId] = useState();
    const [showUpdateCategoryForm , setShowUpdateCategoryForm] = useState();
    const [catNameWillUpdate , setCatNameWillUpdate] = useState();
    const [categoryActivaty , setCategoryActivaty] = useState();
    const [catIdWillUpdate , setCatIdWillUpdate] = useState();
    const [catTranslations , setCatTranslations] = useState([]);
    const [showNewSubCategory , setShowNewSubCategory] = useState();
    const [searchValue , setSearchValue] = useState("");
    // const [categoryImage , setCategoryImage] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const getSubCategoriesData = async () => {
          try {
            dispatch(getSubCategoriesFunc(subCategories_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getSubCategoriesData();
      }, []);
      useEffect(() => {
        data && setCategoriesList(data);
        data && (jobData.current = data.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        ));
      }, [data]);

      const deleteCategoryFunction = async (catId) => {
        swal({
          title: "Confirm Sub Category Deletion",
          text: `Once deleted, This Sub Category will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            try {
              dispatch(deleteSubCategoryFunc(`${subCategories_API_URL}/${catId}`))
              .then((result)=>{
                if(result.payload.success === true){
                  dispatch(getSubCategoriesFunc(subCategories_API_URL));
                  toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
                }
              });
            }catch (error) {
              console.log(error);
            }
          }})
      };
      const sort = 20;
      let paggination = Array(Math.ceil(categoriesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = data &&
          data.length >= 1 && data.filter((cat) =>  cat.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getSubCategoriesFunc(subCategories_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getSubCategoriesFunc(subCategories_API_URL));
      },[searchValue])
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Sub Catgories"} />
        {
            !loading && categoriesList.length < 1
            &&
            checkPermissions(
              "create sub categories",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => [
                  setShowNewSubCategory(true)
                ]}
                >New Sub Category</Link>
              </div>
            )
        }
        <div className="categories-section relative ">
            <div>
            </div>
            {
                loading 
                ?
                (
                  <>
                    <SpinnerLoading/>
                  </>
                )
                :
                <>
                    {
                        categoriesList && categoriesList.length > 0 ?
                        <div className="col-12">
                        <div className="card">
                          <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">Sub Catgories</h4>
                            <div className="relative add-and-search flex items-center">
                              <div className="input-group search-area w-auto mr-2">
                                  <span className="input-group-text"
                                    onClick={() => search()}
                                  >
                                      <Link to={"#"}>
                                      <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 32 32"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                          fill="var(--primary)"
                                          ></path>
                                      </svg>
                                      </Link>
                                  </span>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search here..."
                                      onChange={(e) => {
                                      setSearchValue(e.target.value);
                                      }}
                                  />
                              </div>
                              {
                                checkPermissions(
                                  "create sub categories",
                                  <Link 
                                  to={"#"} 
                                  className="btn btn-primary btn-sm"
                                  onClick={() => [
                                    setShowNewSubCategory(true)
                                  ]}
                                  >New Sub Category</Link>
                                )
                              }
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div id="example_wrapper" className="dataTables_wrapper">
                                <table id="example" className="display w-100 dataTable">
                                  <thead>
                                    <tr role="row">
                                      <th>#ID</th>
                                      <th>Category</th>
                                      <th>Creation Date</th>
                                      <th>Status</th>
                                      <th>Products</th>
                                      {
                                        checkPermissions(
                                          ["delete sub categories" , "update sub categories"],
                                          <th>Actions</th>
                                        )
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      jobData.current.map((category , index) => {
                                          const date = new Date(category.created_at)
                                          const year = date.getFullYear();
                                          const month = date.getMonth()+1;
                                          const day = date.getDate();
                                          return (
                                              <tr key={index}>
                                                  <td>{category.id}</td>
                                                  <td>{category.name}</td>
                                                  <td>{year} / {month} / {day}</td>
                                                  <td>
                                                      <span className={`relative text-sm rounded-lg text-white ${category.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                          {
                                                              category.active === 1
                                                              ?
                                                              "Active"
                                                              :
                                                              "Not Active"
                                                          }
                                                      </span>
                                                  </td>
                                                  <td>{category.products_count}</td>
                                                  {
                                                    checkPermissions(
                                                      ["delete sub categories" , "update sub categories"],
                                                      <td>
                                                        {
                                                          checkPermissions(
                                                            "update sub categories",
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary shadow btn-xs sharp me-1"
                                                                onClick={() => {
                                                                  setCatNameWillUpdate(category.name)
                                                                  setCategoryActivaty(category.active)
                                                                  setCatIdWillUpdate(category.id)
                                                                  setShowUpdateCategoryForm(true);
                                                                  setMainCateoryId(category.category.id);
                                                                  // setCategoryImage(category.image_url);
                                                                  setCatTranslations(category.translations)
                                                                }}
                                                            >
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </Link>
                                                          )
                                                        }
                                                        {
                                                          checkPermissions(
                                                            "delete sub categories",
                                                            <Link
                                                                to="#"
                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                onClick={() => {
                                                                    deleteCategoryFunction(category.id)
                                                                }}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </Link>
                                                          )
                                                        }
                                                      </td>
                                                    )
                                                  }
                                              </tr>
                                          )
                                      }) 
                                    }
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                  <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {categoriesList.length > (activePag.current + 1) * sort
                                      ? (activePag.current + 1) * sort
                                      : categoriesList.length}{" "}
                                    of {categoriesList.length} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example5_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      onClick={() =>
                                        activePag.current > 0 && onClick(activePag.current - 1)
                                      }
                                    >
                                      <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </Link>
                                    <span>
                                      {paggination.map((number, i) => (
                                        <Link
                                          key={i}
                                        //   to="/table-datatable-basic"
                                          to="#"
                                          className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                          } `}
                                          onClick={() => onClick(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>
                                    <Link
                                      className="paginate_button next"
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      onClick={() =>
                                        activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                      }
                                    >
                                      <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        :
                        // <h2>no categories</h2>
                        <EmptyData/>
                    }
                
                </>
            }
        </div>
        <NewSCategory
            show = {showNewSubCategory}
            onHide = {() => setShowNewSubCategory(false)}
        />
        {/* <UpdateSubCategoryForm
          showSubUpdateCategoryForm = {showUpdateCategoryForm} 
          hideSubUpdateCategoryForm = {()=>setShowUpdateCategoryForm(false)}
          subCategoriesApi = {subCategories_API_URL}
          subCategoryName={catNameWillUpdate}
          subCategoryId={catIdWillUpdate}
          subCategoryActivaty = {categoryActivaty}
        /> */}
        <UpdateSubCategoryForm
          showUpdateCategoryForm = {showUpdateCategoryForm} 
          hideUpdateCategoryForm = {()=>setShowUpdateCategoryForm(false)}
          subCategoriesApi = {subCategories_API_URL}
          categoryName={catNameWillUpdate}
          categoryId={catIdWillUpdate}
          translations={catTranslations}
          categoryActivaty = {categoryActivaty}
          mainCateoryId = {mainCateoryId}
          // categoryImg = {categoryImage}
        />
        <LoadingScreen loading={deleteCategoriesLoading}/>
        </>
    )
}


export default SubCategoriesPage