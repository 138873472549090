import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getPaymentSettingsFunc } from "../../../store/custom-actions/settings-actions/payment-gateway-settings/GetPaymentSettingsSlicer";
import { postPaymentSettingsFunc } from "../../../store/custom-actions/settings-actions/payment-gateway-settings/PostPaymentSettingsSlicer";
import EmptyData from "../../components/empty-data/EmptyData";
import { Button } from "react-bootstrap";
function PaymentsGatewaySettings() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/payment-gateway-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getPaymentSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postPaymentSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [paymentsGateway , setPaymentsGateway] = useState({})
    const getSettings = async () => {
        dispatch(getPaymentSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            setPaymentsGateway(data)
        }
    },[data])
    const handleSubmitSettings = async () => {
        if(Object.keys(paymentsGateway).length >= 1) {
            const formData = new FormData();
            for(const [key , value] of Object.entries(paymentsGateway)) {
                formData.append(key , value)
            }
            dispatch(postPaymentSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            toast.error(`Can Not Submit Empty Data!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }
    const [key , setKey] = useState("")
    const [valueOfKey , setValueOfKey] = useState("")
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Payments Gateway Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
                        onClick={() => window.history.back()}
                    >
                        <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Back</span>
                        <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                            <i className="fa-solid fa-arrow-left text-white"></i>
                        </span>
                    </button>
                    <button 
                        type="submit"
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                (
                    data && Object.keys(paymentsGateway).length >= 1
                    ?
                    <>
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card p-3">
                                <form
                                    id="payments-gateway-from"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitSettings()
                                    })}
                                >
                                    <div className="row">
                                        {
                                        Object.keys(paymentsGateway).length < 1
                                        &&
                                        <p className="my-2 text-center">Add Product Attributes (At Least one Attribute..)</p>
                                        }
                                        {
                                        Object.keys(paymentsGateway).length >= 1
                                        &&
                                        Object.keys(paymentsGateway).map((key , index) => {
                                            return (
                                            <div className="row flex items-center" key={index}>
                                                {/* <div className="form-group mb-3 col-sm-2 col-md-3"> */}
                                                    {/* <input
                                                    type="text"
                                                    className="form-control key-input"
                                                    value={key}
                                                    disabled
                                                    /> */}
                                                {/* </div> */}
                                                <div className="form-group mb-3 col-sm-12">
                                                    <label >
                                                        {key.replace(/_/g , " ")}
                                                    </label>
                                                    {
                                                        key !== "paymob_card_gateway"
                                                        &&
                                                        key !== "paymob_wallet_gateway"
                                                        &&
                                                        key !== "cash_on_delivery"
                                                        &&
                                                        key !== "send_receipt"
                                                        &&
                                                        key !== "cash_on_site"
                                                        ?
                                                        <input
                                                        type="text"
                                                        className={`form-control`}
                                                        defaultValue={paymentsGateway[key]}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setPaymentsGateway((prevObject) => ({
                                                                ...prevObject,
                                                                [key]: e.target.value,
                                                            }));
                                                        }}
                                                        />
                                                        :
                                                        <input
                                                        type="checkbox"
                                                        id="customCheckBox5"
                                                        className="form-check-input scale-110 cursor-pointer ml-2"
                                                        checked = {parseInt(paymentsGateway[key]) === 1 ? true : false}
                                                        onChange={(e) => {
                                                            console.log(e.target.checked)
                                                            setPaymentsGateway((prevObject) => ({
                                                                ...prevObject,
                                                                [key]: e.target.checked ? 1 : 0,
                                                            }));
                                                        }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            )
                                        })
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </>	
                :
                <EmptyData/>
                )
            }
        </>
    )
}


export default PaymentsGatewaySettings;