import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { Step, Stepper } from "react-form-stepper";
import { getRolesFunc } from "../../../store/custom-actions/roles-actions/GetRolesSlicer";
import { addAdminFunc } from "../../../store/custom-actions/admins-actions/AddAdminSlicer";

export default function NewAdminForm() {
    const contextApi = useContext(ContextApi);
    const adminsApi = `${contextApi.dashboard_URL_API}/admins`;
    const rolesApi = `${contextApi.dashboard_URL_API}/roles`;
    const [rolesList , setRolesList] = useState([]);
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const dispatch = useDispatch();
    const [roleName, setRoleName] = useState("");
    const [adminImage, setAdminImage] = useState("");
    const [imageViwer, setImageViwer] = useState("");
    const {loading:getRolesLoading , data:rolesData} = useSelector(
        state => state.getRolesSlicer
    );
    const {loading:addAdminLoading} = useSelector(
        state => state.addAdminSlicer
    );
    const [goSteps, setGoSteps] = useState(0);
    const [stepName , setStepName] = useState("Main Data");

    useEffect(() => {
        switch(goSteps) {
            case 0:
                setStepName("Role");
                break;
            case 1:
                setStepName("Main Data");
                break;
            case 2:
                setStepName("Authintication");
                break;
            case 3:
                setStepName("Image");
                break;
            default:
                setStepName("Main Data");
        }
    },[goSteps])
    
    useEffect(() => {
        const getRoles = async () => {
            dispatch(getRolesFunc(`${rolesApi}`));
        };
        getRoles();
    }, []);


    useEffect(() => {
        rolesData && setRolesList(rolesData);
    }, [rolesData]);

    const [adminName , setAdminName] = useState("");
    const [adminEmail , setAdminEmail] = useState("");
    const [adminPhone , setAdminPhone] = useState("");
    const [allMainDataValid , setAllMainDataValid] = useState();
    useEffect(() => {
        if(adminName !== "" && adminEmail !== "" && adminPhone !== "") {
            setAllMainDataValid(true);
        }else {
            setAllMainDataValid(false)
        }
    },[adminName , adminEmail , adminPhone])

    const AuthStep = () => {
        const [password , setPassword] = useState("");
        const [confirmation , setConfirmation] = useState("");
        const [validationAuth , setValidationAuth] = useState();
        useEffect(() => {
            if(password !== "" && confirmation !== "" && password === confirmation) {
                setValidationAuth(true);
            }else {
                setValidationAuth(false)
            }
        },[password , confirmation])
        return (
            <>
                <div className={'row'}>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label className="flex items-center">
                            <i className="fa-brands fa-cuttlefish mr-1"></i>
                            Password
                        </label>
                        {errors.password?.message && (
                        <p className="text-red-600">
                            {errors.password?.message}
                        </p>
                        )}
                        <input
                        {...register("password", {
                            required: "Password is required !"
                        })}
                        type="password"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label className="flex items-center">
                            <i className="fa-brands fa-cuttlefish mr-1"></i>
                            Confirm Password
                        </label>
                        {errors.password_confirmation?.message && (
                        <p className="text-red-600">
                            {errors.password_confirmation?.message}
                        </p>
                        )}
                        <input
                        {...register("password_confirmation", {
                            required: "Confirmation is required !"
                        })}
                        type="password"
                        className="form-control"
                        max={10}
                        onChange={(e) => setConfirmation(e.target.value)}
                        />
                    </div>
                </div>
                <div className="text-end toolbar toolbar-bottom p-2">
                    <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
                    <button 
                    className={`btn btn-primary sw-btn-next ms-1 ${validationAuth ? "" : "pointer-events-none opacity-50"}`} 
                    onClick={() => setGoSteps(3)}>Next</button>
                </div>
            </>
        )
    }

    const ImageStep = () => {
        return (
            <>
                {
                    adminImage !== ""
                    ?
                    <div className="relative flex justify-center items-center mb-2">
                        <div className="relative w-[240px] h-[200px] ">
                            <img src={imageViwer} alt="product img" className="absolute top-0 left-0 w-full h-full" />
                        </div>
                    </div>
                    :
                    ""
                }
                <div className="w-full flex justify-center items-center mb-1">
                    <span className="text-sm">best size (200px * 180px)</span>
                </div>
                <form className="file-upload-form">
                    <label for="file" className="file-upload-label">
                        <div className="file-upload-design">
                        <svg viewBox="0 0 640 512" height="1em">
                            <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                            ></path>
                        </svg>
                        <span className="browse-button">Browse file</span>
                        </div>
                        <input 
                            id="file" 
                            type="file" 
                            accept="image/jpeg, image/png, image/jpg"
                            className="form-control"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const imageUrl = URL.createObjectURL(file);
                                    setImageViwer(imageUrl); 
                                }                        
                                setAdminImage(e.target.files[0]);
                            }}
                        />
                    </label>
                </form>
            </>
        )
    }

    const handleSubmitData = async (data) => {
        if(data) {
            const adminFormData = new FormData();
            adminFormData.append("name" , data.name)
            adminFormData.append("phone" , data.phone)
            adminFormData.append("email" , data.email)
            adminFormData.append("password" , data.password)
            adminFormData.append("role" , roleName)
            adminFormData.append("password_confirmation" , data.password_confirmation)
            adminFormData.append("image" , adminImage)
            dispatch(addAdminFunc(
                {
                    api : adminsApi , 
                    data : adminFormData
                }
            ))
            .then (result => {
                if(result.payload.success === true) {
                    reset();
                    setTimeout(() => window.history.back() , 2000)
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }


    return (
        <>
            <PageTitle motherMenu={"Admins"} activeMenu={"New Admin"} />
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">{stepName}</h4>
						</div>
						<div className="card-body">
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper>
                                <form
                                    className="new-admin-form"
                                    id="new-admin-form"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitData(data);
                                    })}
                                >
                                    {goSteps === 0 && (
                                        <>
                                            {
                                                getRolesLoading ? (
                                                    <div className="form-group flex items-center">
                                                        <p>loading Roles..</p>
                                                    </div>
                                                ) : rolesData &&
                                                    rolesList &&
                                                    rolesList.length <= 0 ? (
                                                    <div className="form-group flex items-center">
                                                        <p>No Roles</p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`form-group mb-3 ${"col-sm-12"}`}
                                                    >
                                                    <label>Role</label>
                                                    <select
                                                        className="form-control col-sm-12"
                                                        onChange={(e) => setRoleName(e.target.value)}
                                                        value={roleName}
                                                    >
                                                        <option selected value={""} disabled>
                                                            Choose Role..
                                                        </option>
                                                        {
                                                        rolesList &&
                                                        rolesList.map((role, index) => {
                                                            return (
                                                            <option key={index} value={role.name}>
                                                                {role.name}
                                                            </option>
                                                            );
                                                        })
                                                        }
                                                    </select>
                                                    </div>
                                                )
                                            }
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className={`btn btn-primary sw-btn-next ${setRoleName !== "" ? "" : "pointer-events-none opacity-50"}`} onClick={() => setGoSteps(1)}>Next</button>
                                            </div>	
                                        </>
                                    )}
                                    {goSteps === 1 && (
                                        <>
                                            {/* <MainDataStep/> */}
                                            <div className={'row'}>
                                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                                    <label className="flex items-center">
                                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                        Name
                                                    </label>
                                                    {errors.name?.message && (
                                                    <p className="text-red-600">
                                                        {errors.name?.message}
                                                    </p>
                                                    )}
                                                    <input
                                                    {...register("name", {
                                                        required: "Name title is required !"
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e) => setAdminName(e.target.value)}
                                                    value={adminName}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                                    <label className="flex items-center">
                                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                        Email
                                                    </label>
                                                    {errors.email?.message && (
                                                    <p className="text-red-600">
                                                        {errors.email?.message}
                                                    </p>
                                                    )}
                                                    <input
                                                    {...register("email", {
                                                        required: "Email is required !"
                                                    })}
                                                    type="email"
                                                    className="form-control"
                                                    max={10}
                                                    onChange={(e) => setAdminEmail(e.target.value)}
                                                    value={adminEmail}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`row`}>
                                                <div className="form-group mb-3 col-sm-12">
                                                    <label className="flex items-center">
                                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                        Phone
                                                    </label>
                                                    {errors.phone?.message && (
                                                    <p className="text-red-600">
                                                        {errors.phone?.message}
                                                    </p>
                                                    )}
                                                    <input
                                                    {...register("phone", {
                                                        required: "Phone Number is required !"
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    min={0}
                                                    onChange={(e) => setAdminPhone(e.target.value)}
                                                    value={adminPhone}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
                                                <button 
                                                className={`btn btn-primary sw-btn-next ms-1 ${allMainDataValid ? "" : "pointer-events-none opacity-50"}`} 
                                                onClick={() => setGoSteps(2)}>Next</button>
                                            </div>
                                        </>
                                    )}
                                    {goSteps === 2 && (
                                        <>
                                            <AuthStep/>
                                        </>
                                    )}
                                    {goSteps === 3 && (
                                        <>
                                            {/* <StepFour /> */}
                                            <ImageStep/>
                                            <div className="text-end toolbar toolbar-bottom p-2">
                                                <button  className={`btn btn-secondary sw-btn-prev me-1 ${addAdminLoading ? "pointer-events-none opacity-50" : ""}`} onClick={() => setGoSteps(2)}>Prev</button>
                                                <button 
                                                type="submit"
                                                form="new-admin-form"
                                                className={`btn btn-primary sw-btn-next ms-1 ${addAdminLoading ? "pointer-events-none opacity-50" : ""}`}
                                                >
                                                    {
                                                        addAdminLoading
                                                        ?
                                                        "loading.."
                                                        :
                                                        "Submit"
                                                    }
                                                </button>
                                            </div>	
                                        </>	
                                    )}
							    </form>
							</div>
						</div>
					</div>
				</div>
			</div>
        </>
    )
}