import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ContextApi } from '../../../store/ContextApi';
import ReactQuill from 'react-quill';
import { postNewMessageFunc } from '../../../store/custom-actions/news-letters-actions/PostNewMessageSlicer';
import Select from "react-select";
import { getNewsLettersFunc } from '../../../store/custom-actions/news-letters-actions/GetNewsLettersSlicer';
import PageTitle from '../../layouts/PageTitle';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
   const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
   } = props;
   return (
      <div
         {...restInnerProps}
         ref={ref}
         style={getStyles("clearIndicator", props)}
      >
         <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
   );
};

const ClearIndicatorStyles = (base, state) => ({
   ...base,
   cursor: "pointer",
   color: state.isFocused ? "blue" : "black",
});

function NewMessage() {
  const contextApi = useContext(ContextApi);
  const newsEmailsApi = `${contextApi.dashboard_URL_API}/newsletters/send-mail`;
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:sendMessageLoading} = useSelector(state => state.postNewMessageSlicer);
    const dispatch = useDispatch();
    const [messageContent , setMessageContent] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const {loading:getEmailsLoading , data:emailsData} = useSelector(state => state.getNewsLettersSlicer)
    const news_API_URL = `${contextApi.dashboard_URL_API}/newsletters`;
    const [emailsList , setEmailsList] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        const getPersons = async () => {
          try {
            dispatch(getNewsLettersFunc(news_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getPersons();
      }, []);
      useEffect(() => {
        emailsData && setEmailsList(emailsData);
      }, [emailsData]);
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'background': colors }]
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link' , "video"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    useEffect(() => {
      console.log(selectedOptions);
    },[selectedOptions])
    const handleSubmitData = (data) => {
        if(messageContent !== "") {
            if(selectedOptions.length >= 1) {
                dispatch(postNewMessageFunc({api : newsEmailsApi , data : {...data , newsletter_id : selectedOptions , message : messageContent}}))
                .then(result => {
                    if(result.payload.success === true) {
                        reset();
                        navigate("/news-letters")
                        toast.success(`${result.payload.message} !`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        })
                    }
                })
            }else {
                toast.error(`Please Choose Emails!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
            }
        }else {
            toast.error(`Message Content Must not be empty!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }
    const handleChange = (selected) => {
      const selectedIds = selected ? selected.map(option => option.id) : [];
      setSelectedOptions(selectedIds);
    };
  return (
    <>
      <PageTitle motherMenu={"News Letters"} activeMenu={"Post News"} />
      <div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card p-3">
              <form 
              id="new-message-form"
              onSubmit={handleSubmit((data) => {
                  handleSubmitData(data)
              })}
              >
                <div className='row'>
                    <div className="form-group mb-3 col-sm-12">
                        <label>Subject</label>
                        {errors.subject?.message && (
                        <p className="text-red-600">{errors.subject?.message}</p>
                        )}
                        <input
                        
                        {...register("subject" , {required : "subject should not be empty!"})}
                        type="text"
                        className="form-control"
                        />
                    </div>
                </div>
                <div className='row'>
                  {
                    getEmailsLoading
                    ?
                    <p>Loading Users..</p>
                    :
                    <Select
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      isMulti
                      getOptionLabel={(option) => option.email}
                      options={emailsList}
                      onChange={handleChange}
                    />
                  }
                </div>
                <div className="row px-3">
                <ReactQuill
                modules = {modules}
                className="p-0 border-none outline-none form-control bg-white mt-3 col-sm-12" 
                value={messageContent} 
                theme="snow" 
                onChange={(content) => setMessageContent(content)} />
                </div>
                <Button style={{marginTop : "4px"}} type="submit" form="new-message-form" className={`${sendMessageLoading ? "opacity-80 pointer-events-none" : ""}`}>
                  {!sendMessageLoading ? (
                    <>
                      Send
                      <i className="fa-solid fa-paper-plane ml-1"></i>
                    </>
                  ) : (
                    <>
                      <div className="relative flex justify-center items-center">
                        loading..
                      </div>
                    </>
                  )}
                </Button>
              </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewMessage;