import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { useContext, useEffect, useRef, useState } from "react";
import { ContextApi } from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { getSingleLanguageFunc } from "../../../store/custom-actions/language/GetSingleLanguageSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import TranslateTextForm from "../../custom-forms/settings-pages/languages/TranslateTextForm";
import NewTextForm from "../../custom-forms/settings-pages/languages/NewTextForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function UpdateLaguageWordsPage() {
    const {langId} = useParams();
    const contextApi = useContext(ContextApi);
    const langApi = `${contextApi.dashboard_URL_API}/languages/${langId}`;
    const {loading:getSingleLangLoading , data:singleLangData} = useSelector(
        state => state.getSingleLanguageSlicer
    );
    const [searchValue , setSearchValue] = useState("");
    const [langWords , setLangWords] = useState({})
    const [showTranslationForm , setShowTranslationForm] = useState()
    const dispatch = useDispatch();
    useEffect(() => {
        const getLang = () => {
            dispatch(getSingleLanguageFunc(langApi))
        }
        getLang();
    },[])

    useEffect(() => {
        if(singleLangData) {
            setLangWords(singleLangData.all_word);
        }
    },[singleLangData]);
    const search = () => {
        if(searchValue !== "") {
            if (langWords && singleLangData.all_word) {
                const filtred = Object.entries(langWords).filter(([key , value]) => {
                    return (key.toLowerCase().includes(searchValue.toLowerCase()) || value.toLowerCase().includes(searchValue.toLowerCase())) && [key , value]
                })
                filtred.length >= 1 ? setLangWords({...filtred}) : setLangWords(singleLangData.all_word)
                const filteredObject = Object.fromEntries(filtred);
                Object.keys(filteredObject).length >= 1 && setLangWords(filteredObject);
                }
        }else {
            setLangWords(singleLangData.all_word);
        }
    }
    useEffect(() => {
        Object.keys(langWords).length >= 1 && search()
    },[searchValue])

    const [text , setText] = useState("");
    const [oldTranslate , setOldTranslate] = useState("");
    const [showNewTextForm , setShowNewTextForm] = useState();
    const showTranslationFormFunc = (textSource , oldTranslate) => {
        setShowTranslationForm(true);
        setText(textSource)
        setOldTranslate(oldTranslate)
    }
    return (
        <>
            <PageTitle motherMenu={"Language"} activeMenu={"Tranlsate Words"} />
            {
                getSingleLangLoading
                ?
                <SpinnerLoading/>
                :
                (
                    singleLangData
                    ?
                    (
                        langWords && Object.keys(langWords).length >= 1
                        &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header flex justify-between items-center">
                                    <h4 className="card-title">Language Words</h4>
                                    <div className="relative add-and-search flex items-center">
                                    <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text"
                                            // onClick={() => search()}
                                        >
                                            <Link to={"#"}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                fill="var(--primary)"
                                                ></path>
                                            </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                            onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {
                                        checkPermissions(
                                        "create language",
                                        <Link 
                                        to={"#"} 
                                        className="btn btn-primary btn-sm"
                                        onClick={() => setShowNewTextForm(true)}
                                        >Create</Link>
                                        )
                                    }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="w-100 table-responsive h-[300px] overflow-y-auto">
                                    <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                <th>Text</th>
                                                <th>Tranlsation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            Object.entries(langWords).map(([key , value]) => {
                                                return (
                                                    <tr 
                                                    key={key} 
                                                    className="hover:bg-slate-200 cursor-pointer transition-all duration-300"
                                                    onClick={() => {
                                                        // setShowTranslationForm(true);
                                                        showTranslationFormFunc(key , value)
                                                    }}
                                                    >
                                                        <td>{key}</td>
                                                        <td>{value}</td>
                                                    </tr>
                                                )
                                            }) 
                                            }
                                        </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    <FaildLoadData/>
                )
            }
            <TranslateTextForm
                show={showTranslationForm}
                hide={() => setShowTranslationForm(false)}
                refetetchFunc={() => dispatch(getSingleLanguageFunc(langApi))}
                langId={langId}
                text={text}
                oldTranslate={oldTranslate}
            />
            <NewTextForm
                show={showNewTextForm}
                hide={() => setShowNewTextForm(false)}
                refetetchFunc={() => dispatch(getSingleLanguageFunc(langApi))}
                langSlug={singleLangData && singleLangData.language && singleLangData.language.slug}
            />
        </>
    )
}