import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getCouponsFunc } from "../../../store/custom-actions/coupons-actions/GetCouponsSlicer";
import { deleteCouponFunc } from "../../../store/custom-actions/coupons-actions/DeleteCouponSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function CouponsPage() {
    const {loading:getCouponsLoading , data:couponsData} = useSelector(state => state.getCouponsSlicer)
    const {loading:deleteCouponLoading} = useSelector(state => state.deleteCouponSlicer)
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const [couponsList , setCouponsList] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const getCoupons = async () => {
          try {
            dispatch(getCouponsFunc(coupons_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCoupons();
      }, []);
      useEffect(() => {
        couponsData && setCouponsList(couponsData);
        couponsData && (jobData.current = couponsData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [couponsData]);
      const sort = 20;
      let paggination = Array(Math.ceil(couponsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        couponsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = couponsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = couponsData &&
          couponsData.length >= 1 && couponsData.filter((state) =>  state.code.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getCouponsFunc(coupons_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getCouponsFunc(coupons_API_URL));
      },[searchValue])
      const deleteCouponFunction = async (id) => {
        swal({
          title: "Confirm Coupon Deletion",
          text: `Once deleted, This Coupon will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteCouponFunc(`${coupons_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getCouponsFunc(coupons_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Coupon is safe!");
          }
        });
      };
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Coupons"} />
        {
            !getCouponsLoading && couponsList.length < 1
            &&
            checkPermissions(
              "create coupons",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"/new-coupon"} 
                className="btn btn-primary btn-sm"
                >New Coupon</Link>
              </div>
            )
        }
        {
            getCouponsLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                couponsData
                ?
                (
                    couponsList && couponsList.length >= 1
                    &&
                    <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Coupons</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create coupons",
                              <Link 
                              to={"/new-coupon"} 
                              className="btn btn-primary btn-sm"
                              >New Coupon</Link>
                            )
                          }
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Code</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>Limit</th>
                                  <th>Type</th>
                                  <th>Discount</th>
                                  <th>Users</th>
                                  {
                                    checkPermissions(
                                      ["update coupons" , "delete coupons"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  jobData.current.map((coupon , index) => {
                                      return (
                                          <tr key={index}>
                                              <td>{coupon.id}</td>
                                              <td>{coupon.code}</td>
                                              <td>{coupon.start_date}</td>
                                              <td>{coupon.end_date}</td>
                                              <td>{coupon.count_used}</td>
                                              <td>{coupon.type}</td>
                                              <td>{coupon.discount}</td>
                                              <td>
                                                <Link
                                                      to="/coupon-users"
                                                      className="btn btn-danger shadow btn-xs sharp"
                                                  >
                                                    {coupon.coupon_usages && coupon.coupon_usages.length}
                                                </Link>
                                              </td>
                                              {
                                                checkPermissions(
                                                  ["update coupons" , "delete coupons"],
                                                  <td>
                                                      <Link
                                                          to="#"
                                                          className="btn btn-danger shadow btn-xs sharp"
                                                          onClick={() => {
                                                              deleteCouponFunction(coupon.id)
                                                          }}
                                                      >
                                                          <i className="fa fa-trash"></i>
                                                      </Link>
                                                  </td>
                                                )
                                              }
                                          </tr>
                                      )
                                  }) 
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {couponsList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : couponsList.length}{" "}
                                of {couponsList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getCouponsFunc(coupons_API_URL))}
                />
            )
        }
        <LoadingScreen loading={deleteCouponLoading} />
        </>
    )
}