import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./show-image-modal.css";
function ShowImageModal(props) {
    const {image} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='show-image-modal'
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Show Image
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className='image-modal relative w-full flex justify-center items-center'>
            <img src={image} alt="img modal" className='relative rounded-md ' />
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
export default ShowImageModal