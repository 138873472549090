import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { getNewsLettersFunc } from "../../../store/custom-actions/news-letters-actions/GetNewsLettersSlicer";
import NewEmailForm from "../../custom-forms/news-letters/NewEmailForm";
import NewMessage from "../../custom-forms/news-letters/NewMessage";
import swal from "sweetalert";
import { deleteEmailFunc } from "../../../store/custom-actions/news-letters-actions/DeleteEmailSlicer";
import { toast } from "react-toastify";
import { verifyEmailFunc } from "../../../store/custom-actions/news-letters-actions/VerifyEmailSlicer";
import EmptyData from "../../components/empty-data/EmptyData";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function NewsLettersPage() {
    const {loading:getEmailsLoading , data:emailsData} = useSelector(state => state.getNewsLettersSlicer)
    const {loading:verifyEmailLoading} = useSelector(state => state.verifyEmailSlicer)
    const {loading:deleteEmailLoading} = useSelector(state => state.deleteEmailSlicer)
    const contextApi = useContext(ContextApi);
    const news_API_URL = `${contextApi.dashboard_URL_API}/newsletters`;
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();
    const [emailsList , setEmailsList] = useState([])
    const [showNewEmailForm , setShowNewEmailForm] = useState()
    const [showNewMessageForm , setShowNewMessageForm] = useState()

    useEffect(() => {
        const getPersons = async () => {
          try {
            dispatch(getNewsLettersFunc(news_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getPersons();
      }, []);
      useEffect(() => {
        emailsData && setEmailsList(emailsData);
        emailsData && (jobData.current = emailsData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [emailsData]);
      const sort = 20;
      let paggination = Array(Math.ceil(emailsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        emailsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = emailsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = emailsData &&
          emailsData.length >= 1 && emailsData.filter((state) =>  state.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getNewsLettersFunc(news_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getNewsLettersFunc(news_API_URL));
      },[searchValue])

      const verifyEmailFunction = async (id) => {
        const verifingFunction = () => {
          try {
            dispatch(verifyEmailFunc({api : `${news_API_URL}/verify-mail-send` , data : {newsletter_id : id}}))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getNewsLettersFunc(news_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          }
          catch(error) {
            swal({
              title: "Error",
              text: error,
              icon: "error",
              buttons: true,
            })
          }
        }
        swal({
          title: "Confirm Verify This Email",
          text: `Once Verifyed , You Can Send messages for him!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            verifingFunction()
          }
        });
      };

      const deleteEmailFunction = async (id) => {
        swal({
          title: "Confirm Email Deletion",
          text: `Once deleted, This Email will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteEmailFunc(`${news_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getNewsLettersFunc(news_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Email is safe!");
          }
        });
      };


    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"News Letters"} />
            <div className="relative mt-2 flex justify-end items-center">
            {
              !getEmailsLoading && emailsList.length < 1
              &&
              <>
                {
                  checkPermissions(
                    "create newsletters",
                    <Link
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setShowNewEmailForm(true)
                    }}
                    >New Email</Link>
                  )
                }
                {
                  !getEmailsLoading && emailsList.length >= 1
                  &&
                  checkPermissions(
                    "create notifications",
                    <Link
                    to="/post-news"
                    className="btn btn-primary btn-sm ml-1"
                    >New Message</Link>
                  )
                }
              </>
              }
            </div>
        {
            getEmailsLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                emailsData
                ?
                (
                    emailsList && emailsList.length >= 1
                    ?
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header flex justify-between items-center">
                          <h4 className="card-title">Emails</h4>
                          <div className="relative add-and-search flex items-center">
                            <div className="input-group search-area w-auto mr-2">
                                <span className="input-group-text"
                                  onClick={() => search()}
                                >
                                    <Link to={"#"}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                        fill="var(--primary)"
                                        ></path>
                                    </svg>
                                    </Link>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here..."
                                    onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    }}
                                />
                            </div>
                            {
                              checkPermissions(
                                "create newsletters",
                                <Link
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  setShowNewEmailForm(true)
                                }}
                                >New Email</Link>
                              )
                            }
                            {
                              checkPermissions(
                                "create notifications",
                                <Link
                                to="/post-news"
                                className="btn btn-primary btn-sm ml-1"
                                >New Message</Link>
                              )
                            }
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                              <table id="example" className="display w-100 dataTable">
                                <thead>
                                  <tr role="row">
                                    <th>#ID</th>
                                    <th>Email</th>
                                    <th>Creation</th>
                                    <th>Last Update</th>
                                    <th>Email Verifing</th>
                                    {
                                      checkPermissions(
                                        ["create notifications" , "delete newsletters"],
                                        <th>Actions</th>
                                      )
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    emailsData  && jobData.current.map((person , index) => {
                                      const creationDate = new Date(person.created_at)
                                      const creationYear = creationDate.getFullYear();
                                      const creationMonth = creationDate.getMonth()+1;
                                      const creationDay = creationDate.getDate();
                                      const updatedDate = new Date(person.updated_at)
                                      const updatedYear = updatedDate.getFullYear();
                                      const updatedMonth = updatedDate.getMonth()+1;
                                      const updatedDay = updatedDate.getDate();
                                        return (
                                            <tr key={index}>
                                                <td>{person.id}</td>
                                                <td>{person.email}</td>
                                                <td>{creationYear} / {creationMonth} / {creationDay}</td>
                                                <td>{updatedYear} / {updatedMonth} / {updatedDay}</td>
                                                <td>
                                                  {
                                                  <span className={`relative text-sm rounded-lg text-white ${person.is_verified === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          person.is_verified === 1
                                                          ?
                                                          "Verified"
                                                          :
                                                          "Not Verified"
                                                      }
                                                  </span>
                                                  }
                                                </td>
                                                {
                                                  checkPermissions(
                                                    ["create notifications" , "delete newsletters"],
                                                    <td>
                                                      {
                                                        checkPermissions(
                                                          "delete newsletters",
                                                          <Link
                                                              to="#"
                                                              className="btn btn-danger text-white ml-1"
                                                              onClick={() => {
                                                                  deleteEmailFunction(person.id)
                                                              }}
                                                          >
                                                              <i className="fa fa-trash"></i>
                                                          </Link>
                                                        )
                                                      }
                                                        {
                                                          person.is_verified !== 1
                                                          &&
                                                          checkPermissions(
                                                            "create notifications",
                                                            <Link
                                                                to="#"
                                                                className="btn bg-green text-white ml-1"
                                                                onClick={() => {
                                                                    verifyEmailFunction(person.id)
                                                                }}
                                                            >
                                                                Verify
                                                            </Link>
                                                          )
                                                        }
                                                    </td>
                                                  )
                                                }

                                            </tr>
                                        )
                                    }) 
                                  }
                                </tbody>
                              </table>
                
                              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                <div className="dataTables_info">
                                  Showing {activePag.current * sort + 1} to{" "}
                                  {emailsList.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : emailsList.length}{" "}
                                  of {emailsList.length} entries
                                </div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers mb-0"
                                  id="example5_paginate"
                                >
                                  <Link
                                    className="paginate_button previous disabled"
                                  //   to="/table-datatable-basic"
                                    to="#"
                                    onClick={() =>
                                      activePag.current > 0 && onClick(activePag.current - 1)
                                    }
                                  >
                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                  </Link>
                                  <span>
                                    {paggination.map((number, i) => (
                                      <Link
                                        key={i}
                                      //   to="/table-datatable-basic"
                                        to="#"
                                        className={`paginate_button  ${
                                          activePag.current === i ? "current" : ""
                                        } `}
                                        onClick={() => onClick(i)}
                                      >
                                        {number}
                                      </Link>
                                    ))}
                                  </span>
                                  <Link
                                    className="paginate_button next"
                                  //   to="/table-datatable-basic"
                                    to="#"
                                    onClick={() =>
                                      activePag.current + 1 < paggination.length &&
                                      onClick(activePag.current + 1)
                                    }
                                  >
                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <EmptyData/>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getNewsLettersFunc(news_API_URL))}
                />
            )
        }
        <LoadingScreen loading={deleteEmailLoading} />
        <LoadingScreen loading={verifyEmailLoading} />
        <NewEmailForm
          showForm={showNewEmailForm}
          hideForm={() => setShowNewEmailForm(false)}
        />
        </>
    )
}