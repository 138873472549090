import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';
import { updateStateFunc } from '../../../store/custom-actions/states-actions/UpdateStateSlicer';
import { getStatesFunc } from '../../../store/custom-actions/states-actions/GetStatesSlicer';
import { getCountriesFunc } from '../../../store/custom-actions/countries-actions/GetContriesSlicer';

function UpdateStateForm({show , onHide , stateId , oldName , oldCode , oldShipping , oldStatus , oldCountry}) {
    const {register , reset , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:updateStateLoading} = useSelector(state => state.updateStateSlicer);
    const contextApi = useContext(ContextApi);
    const states_API_URL = `${contextApi.dashboard_URL_API}/states`;
    const state_API_URL = `${contextApi.dashboard_URL_API}/states/${stateId}`;
    const[Activaty , setActivaty] = useState(0);
    const dispatch = useDispatch();

    const {loading:getCountriesLoading , data:countriesData} = useSelector(state => state.getCountriesSlicer)
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const[countriesList , setCountriesList] = useState([]);
    const[countryId , setCountryId] = useState();
    useEffect(() => {
        const getCitites = async () => {
          try {
            dispatch(getCountriesFunc(countries_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCitites();
      }, []);
      useEffect(() => {
        countriesData && setCountriesList(countriesData);
      }, [countriesData]);
    useEffect(() => {
        setValue("name" , oldName);
        setValue("code" , oldCode);
        setValue("shipping_amount" , oldShipping);
        setCountryId(oldCountry);
        setActivaty(oldStatus)
      },[setValue , oldName , oldCode , oldStatus , oldShipping , oldCountry])
    const handleSubmitData = (data) => {
      dispatch(updateStateFunc({api : state_API_URL , data : {...data , active : Activaty , country_id : countryId}}))
      .then(result => {
          if(result.payload.success === true) {
            const successMessage = result.payload.message;
            dispatch(getStatesFunc(states_API_URL))
            onHide();
            reset();
            toast.success(`✔️ ${successMessage} !`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
      })
    }
    
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="update-state-from"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12 col-md-6">
                  <label>State</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  
                  {...register("name" , {required : "name should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-3">
                  <label>Shipping</label>
                  {errors.shipping_amount?.message && (
                  <p className="text-red-600">{errors.shipping_amount?.message}</p>
                  )}
                  <input
                  
                  {...register("shipping_amount" , {required : "shipping should not be empty!"})}
                  type="number"
                  className="form-control"
                  min={0}
                  />
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-3">
                  <label>ZIP</label>
                  {errors.code?.message && (
                  <p className="text-red-600">{errors.code?.message}</p>
                  )}
                  <input
                  
                  {...register("code" , {required : "ZIP should not be empty!"})}
                  type="text"
                  className="form-control"
                  />
              </div>
          </div>
          <div className='row'>
          {
                      getCountriesLoading ? (
                          <div className="form-group flex items-center">
                              <p>loading Countries..</p>
                          </div>
                      ) : countriesData &&
                          countriesList &&
                          countriesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>No Countries</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 ${"col-sm-12"}`}
                          >
                          <label>Country</label>
                          <select
                              className="form-control col-sm-12"
                              onChange={(e) => setCountryId(e.target.value)}
                              defaultValue={countryId}
                          >
                              {
                              countriesList &&
                              countriesList.map((cat, index) => {
                                  return (
                                  <option key={index} value={cat.id}>
                                      {cat.name}
                                  </option>
                                  );
                              })
                              }
                          </select>
                          </div>
                      )
                  }
            <div className="form-group col-sm-12 form-check custom-checkbox mb-3 checkbox-danger">
                  <input
                //   id="cat-active"
                  type="checkbox"
                  id="customCheckBox5"
                  className="m-0 mr-3 form-check-input cursor-pointer"
                  onChange={(e) => e.target.checked ? setActivaty(1) : setActivaty(0)}
                  checked = {Activaty === 1 ? true : false}
                  />
                  <label htmlFor='customCheckBox5' className='cursor-pointer ml-1'>Activaty</label>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" form="update-state-from" className={`${updateStateLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!updateStateLoading ? (
            "Submit"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateStateForm;