import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import { deleteUserFunc } from "../../../store/custom-actions/users-actions/DeleteUserSlicer";
import ChangeUserPassForm from "../../custom-forms/users/ChangePassForm";
import UpdateUserStatus from "../../custom-forms/users/UpdateUserStatus";
import { getAdminsFunc } from "../../../store/custom-actions/admins-actions/GetAdminsSlicer";
import AdminDescion from "./AdminDescion";
import EmptyData from "../../components/empty-data/EmptyData";
import defaultUserImage from "../../../media/images/user (2).png";
import checkPermissions from "../../global-functions/checkPermissionsFunc";


export default function AdminsPage() {
    const {loading:getAdminsLoading , data:adminsData} = useSelector(state => state.getAdminsSicer)
    const {loading:deleteAdminLoading} = useSelector(state => state.deleteAdminSlicer)
    const contextApi = useContext(ContextApi);
    const admins_API_URL = `${contextApi.dashboard_URL_API}/admins`;
    const [adminsList , setAdminsList] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const [showChangePass , setShowChangePass] = useState();
    const [adminWhichUpdateId , setAdminWhichUpdateId] = useState();
    const [showUpdateStatus , setShowUpdateStatus] = useState();
    const [oldActivaty , setOldActivaty] = useState();
    const [oldEmailVerifed , setOldEmailVerifed] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        const getAdmins = async () => {
          try {
            dispatch(getAdminsFunc(admins_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getAdmins();
      }, []);
      useEffect(() => {
        adminsData && setAdminsList(adminsData);
        adminsData && (jobData.current = adminsData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [adminsData]);
      const sort = 20;
      let paggination = Array(Math.ceil(adminsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        adminsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = adminsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = adminsData &&
          adminsData.length >= 1 && adminsData.filter(
            (user) => user.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          );
          jobData.current = searchResult
        }else {
          dispatch(getUsersFunc(admins_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getUsersFunc(admins_API_URL));
      },[searchValue])
      const deleteAdminFunction = async (id) => {
        swal({
          title: "Confirm Admin Deletion",
          text: `Once deleted, This Admin will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteUserFunc(`${admins_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getUsersFunc(admins_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Admin is safe!");
          }
        });
      };
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Admins"} />
        {
            !getAdminsLoading && adminsList.length < 1
            &&
            checkPermissions(
              "create admins",
              <div className="relative mt-2 flex justify-end items-center">
                  <Link 
                  to={"/new-admin"} 
                  className="btn btn-primary btn-sm"
                  >New Admin</Link>
              </div>
            )
        }
        {
            getAdminsLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                adminsData
                ?
                (
                    adminsList && adminsList.length >= 1
                    ?
                    <div className="col-12">
                        <div className="card">
                        <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">Admins</h4>
                            <div className="relative add-and-search flex items-center">
                            <div className="input-group search-area w-auto mr-2">
                                <span className="input-group-text"
                                    onClick={() => search()}
                                >
                                    <Link to={"#"}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                        fill="var(--primary)"
                                        ></path>
                                    </svg>
                                    </Link>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here..."
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </div>
                                {
                                  checkPermissions(
                                    "create admins",
                                    <Link 
                                    to={"/new-admin"} 
                                    className="btn btn-primary btn-sm"
                                    >New Admin</Link>
                                  )
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <table id="example" className="display w-100 dataTable">
                                <thead>
                                    <tr role="row">
                                    <th>#ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    jobData.current.map((admin , index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{admin.id}</td>
                                                <td>
                                                    <Link to={`/user-profile/${admin.id}`} className="relative flex items-center">
                                                    <img 
                                                        src={admin.image_url ? admin.image_url : defaultUserImage} 
                                                        className="mr-1 w-[30px] h-[30px] rounded-[50%] cursor-pointer" 
                                                        alt="user img"
                                                    />
                                                    {admin.name}
                                                    </Link>
                                                </td>
                                                <td>{admin.email}</td>
                                                <td>{admin.phone}</td>
                                                <td>
                                                    <span className={`relative text-sm rounded-lg text-white ${admin.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                        {
                                                            admin.active === 1
                                                            ?
                                                            "Active"
                                                            :
                                                            "Not Active"
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    {
                                                        <AdminDescion
                                                        deleteFunction={() => deleteAdminFunction(admin.id)}
                                                        adminId = {admin.id}
                                                        // showChangePassForm = {() => {
                                                        //     setShowChangePass(true);
                                                        //     setAdminWhichUpdateId(user.id)
                                                        // }}
                                                        // showUpdateStatusForm = {() => {
                                                        //     setShowUpdateStatus(true);
                                                        //     setAdminWhichUpdateId(user.id)
                                                        //     setOldActivaty(user.active)
                                                        //     setOldEmailVerifed(user.is_email_verified)
                                                        // }}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }) 
                                    }
                                </tbody>
                                </table>
                
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {adminsList.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : adminsList.length}{" "}
                                    of {adminsList.length} entries
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example5_paginate"
                                >
                                    <Link
                                    className="paginate_button previous disabled"
                                    to="#"
                                    onClick={() =>
                                        activePag.current > 0 && onClick(activePag.current - 1)
                                    }
                                    >
                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </Link>
                                    <span>
                                    {paggination.map((number, i) => (
                                        <Link
                                        key={i}
                                        to="#"
                                        className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                        } `}
                                        onClick={() => onClick(i)}
                                        >
                                        {number}
                                        </Link>
                                    ))}
                                    </span>
                                    <Link
                                    className="paginate_button next"
                                    //   to="/table-datatable-basic"
                                    to="#"
                                    onClick={() =>
                                        activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                    }
                                    >
                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    :
                    <EmptyData emptyText={"There is no Admin Yet !"}/>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getAdminsFunc(admins_API_URL))}
                />
            )
        }
        <LoadingScreen loading={deleteAdminLoading} />
        <ChangeUserPassForm
          show = {showChangePass}
          onHide = {() => setShowChangePass(false)}
          userId = {adminWhichUpdateId}
        />
        <UpdateUserStatus
          show = {showUpdateStatus}
          onHide = {() => setShowUpdateStatus(false)}
          userId = {adminWhichUpdateId}
          oldActivaty = {oldActivaty}
          oldVerifing = {oldEmailVerifed}
        />
        </>
    )
}