import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import CurrencyForm from "../../custom-forms/settings-pages/CurrencyForm"
import checkPermissions from "../../global-functions/checkPermissionsFunc";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);
	const user = JSON.parse(window.localStorage.getItem("user"))
	const userRoleName = user && user.roleName && user.roleName.toLowerCase();
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  const [showCurrencyForm , setShowCurrencyForm] = useState()
  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "schedule",
      "instructors",
      "message",
      "activity",
      "profile",
      "task",
    ],
	products = [
		"all-products",
		"Books",
		"other",
		"products-details",
	],
	roles = [
		"roles",
	],
	categories = [
		"all",
		"sub-categories"
	],
	addresses = [
		"cities",
		"countries",
		"states"
	],
	coupons = [
		"coupons",
	],
	users = [
		"users",
	],
	admins = [
		"admins",
	],
	orders = [
		"orders",
	],
	newsLetters = [
		"news-letters",
	],
	settings = [
		"basic-settings",
		"smtp-settings",
		"logo-settings",
		"tax-settings",
		"orders-outside-settings",
		"payment-gateway-settings",
		"social-settings",
		"contact-settings",
		"languages-settings",
		"sliders-settings",
	]
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
			<li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow" to="#" >
					<i className="bi bi-grid"></i>
					<span className="nav-text">Dashboard</span>
				</Link>
				<ul>
					<li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>
				</ul>
			</li>
			{
				checkPermissions(
					"read admins" , 
					<li className={`${admins.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="fa-regular fa-circle-user"></i>
							<span className="nav-text">Admins</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/admins" ? "mm-active" : ""}`} to="/admins">All Admins</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read users",
					<li className={`${users.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="fa-regular fa-user"></i>
							<span className="nav-text">Users</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/users" ? "mm-active" : ""}`} to="/users">Users</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					["read categories" , "read sub categories"],
					<li className={`${categories.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="bi bi-grid"></i>
							<span className="nav-text">categories</span>
						</Link>
						<ul>
							{
								checkPermissions(
									"read categories",
									<li><Link className={`${path === "categories" ? "mm-active" : ""}`} to="/categories">Categories</Link></li>
								)
							}
							{
								checkPermissions(
									"read sub categories",
									<li><Link className={`${path === "sub-categories" ? "mm-active" : ""}`} to="/sub-categories">Sub Categories</Link></li>
								)
							}
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read products",
					<li className={`${products.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="bi bi-book"></i>
							<span className="nav-text">Products</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/products" ? "mm-active" : ""}`} to="/products">All Products</Link></li>
							{/* <li><Link className={`${path === "/books" ? "mm-active" : ""}`} to="/books">Books</Link></li>
							<li><Link className={`${path === "/other" ? "mm-active" : ""}`} to="/other">Other</Link></li> */}
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read news-letters",
					<li className={`${newsLetters.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="bi bi-book"></i>
							<span className="nav-text">News</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/news-letters" ? "mm-active" : ""}`} to="/news-letters">News Letters</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read roles",
					<li className={`${roles.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="fa-solid fa-circle-check"></i>
							<span className="nav-text">Roles</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/roles" ? "mm-active" : ""}`} to="/roles">Roles</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read addresses",
					<li className={`${addresses.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							{/* <i className="bi bi-book"></i> */}
							<i className="fa-regular fa-compass"></i>
							<span className="nav-text">Addresses</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/countries" ? "mm-active" : ""}`} to="/countries">Countries</Link></li>
							<li><Link className={`${path === "/cities" ? "mm-active" : ""}`} to="/cities">Cities</Link></li>
							<li><Link className={`${path === "/states" ? "mm-active" : ""}`} to="/states">States</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read coupons",
					<li className={`${coupons.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="fa-solid fa-handshake"></i>
							<span className="nav-text">Coupons</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/coupons" ? "mm-active" : ""}`} to="/coupons">Coupons</Link></li>
						</ul>
					</li>
				)
			}
			{
				checkPermissions(
					"read orders",
					<li className={`${orders.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							{/* <i className="fa-solid fa-handshake"></i> */}
							<i className="fa-regular fa-folder"></i>
							<span className="nav-text">Orders</span>
						</Link>
						<ul>
							<li><Link className={`${path === "/orders" ? "mm-active" : ""}`} to="/orders">Orders</Link></li>
						</ul>
					</li>
				)
			}
			{
				userRoleName === "super admin"
				&&
				<li className={`${settings.includes(path) ? "mm-active" : ""}`}>
					<Link className="has-arrow" to="#" >
						<i className="fa-solid fa-pen-to-square"></i>
						<span className="nav-text">Settings</span>
					</Link>
					<ul>
						<li><Link className={`${path === "/basic-settings" ? "mm-active" : ""}`} to="/basic-settings">Basic</Link></li>
						<li><Link className={`${path === "#" ? "mm-active" : ""}`} to="#"
							onClick={() => setShowCurrencyForm(true)}
						>Currency</Link></li>
						<li><Link className={`${path === "/smtp-settings" ? "mm-active" : ""}`} to="/smtp-settings">SMTP</Link></li>
						<li><Link className={`${path === "/logo-settings" ? "mm-active" : ""}`} to="/logo-settings">Site Logo</Link></li>
						<li><Link className={`${path === "/tax-settings" ? "mm-active" : ""}`} to="/tax-settings">Tax</Link></li>
						<li><Link className={`${path === "/orders-outside-settings" ? "mm-active" : ""}`} to="/orders-outside-settings">Orders Oustide</Link></li>
						<li><Link className={`${path === "/payment-gateway-settings" ? "mm-active" : ""}`} to="/payment-gateway-settings">Payment Gateway</Link></li>
						<li><Link className={`${path === "/social-settings" ? "mm-active" : ""}`} to="/social-settings">Social Media</Link></li>
						<li><Link className={`${path === "/contact-settings" ? "mm-active" : ""}`} to="/contact-settings">Contact</Link></li>
						<li><Link className={`${path === "/languages-settings" ? "mm-active" : ""}`} to="/languages-settings">Languages</Link></li>
						<li><Link className={`${path === "/sliders-settings" ? "mm-active" : ""}`} to="/sliders-settings">Sliders</Link></li>
					</ul>
				</li>
			}

			{/* <li className={`${instructor.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow" to="#"> <i className="bi bi-people"></i> <span className="nav-text">Instructor</span></Link>
				<ul>
					<li><Link className={`${path === "instructor-dashboard" ? "mm-active" : ""}`} to="/instructor-dashboard">Dashboard</Link></li>
					<li><Link className={`${path === "instructor-courses" ? "mm-active" : ""}`} to="/instructor-courses">Courses</Link></li>
					<li><Link className={`${path === "instructor-schedule" ? "mm-active" : ""}`} to="/instructor-schedule">Schedule</Link></li>
					<li><Link className={`${path === "instructor-students" ? "mm-active" : ""}`} to="/instructor-students">Students</Link></li>
					<li><Link className={`${path === "instructor-resources" ? "mm-active" : ""}`} to="/instructor-resources">Resources</Link></li>
					<li><Link className={`${path === "instructor-transactions" ? "mm-active" : ""}`} to="/instructor-transactions">Transactions</Link></li>
					<li><Link className={`${path === "instructor-liveclass" ? "mm-active" : ""}`} to="/instructor-liveclass">Live Class</Link></li>
				</ul>
			</li>			
			<li className={`${app.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-info-circle"></i>
					<span className="nav-text">Apps</span>
				</Link>
				<ul>
					<li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="/app-profile">Profile</Link></li>
					<li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post Details</Link></li>
					<li className={`${email.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Email</Link>
						<ul  className={`${email.includes(path) ? "mm-show" : ""}`}>
						  <li><Link className={`${ path === "email-compose" ? "mm-active" : ""}`} to="/email-compose">Compose</Link></li>
						  <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`} to="/email-inbox">Inbox</Link></li>
						  <li><Link className={`${path === "email-read" ? "mm-active" : ""}`} to="/email-read">Read</Link></li>
						</ul>
					</li>
					<li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}to="/app-calender">Calendar</Link></li>
					<li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Shop</Link>
						<ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
							<li><Link className={`${ path === "ecom-product-grid" ? "mm-active" : ""}`} to="/ecom-product-grid">Product Grid</Link></li>
							<li><Link className={`${ path === "ecom-product-list" ? "mm-active" : ""}`} to="/ecom-product-list">Product List</Link></li>
							<li><Link className={`${ path === "ecom-product-detail" ? "mm-active" : "" }`} to="/ecom-product-detail">Product Details</Link></li>
							<li><Link className={`${ path === "ecom-product-order" ? "mm-active" : "" }`} to="/ecom-product-order">Order</Link></li>
							<li><Link className={`${ path === "ecom-checkout" ? "mm-active" : ""}`} to="/ecom-checkout">Checkout</Link></li>
							<li><Link className={`${ path === "ecom-invoice" ? "mm-active" : "" }`} to="/ecom-invoice">Invoice</Link></li>
							<li><Link className={`${ path === "ecom-customers" ? "mm-active" : "" }`} to="/ecom-customers">Customers</Link></li>
						</ul>
					</li>
				</ul>
			</li>
			<li className={`${charts.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-pie-chart"></i>
					<span className="nav-text">Charts</span>
				</Link>
				<ul>
					<li><Link className={`${path === "chart-rechart" ? "mm-active" : ""}`} to="/chart-rechart">RechartJs</Link></li>
					<li><Link className={`${path === "chart-chartjs" ? "mm-active" : ""}`} to="/chart-chartjs">Chartjs</Link></li>
					<li><Link className={`${path === "chart-chartist" ? "mm-active" : ""}`} to="/chart-chartist">Chartist</Link></li>
					<li><Link className={`${path === "chart-sparkline" ? "mm-active" : ""}`} to="/chart-sparkline">Sparkline</Link></li>
					<li><Link className={`${path === "chart-apexchart" ? "mm-active" : ""}`} to="/chart-apexchart" >Apexchart</Link></li>
				</ul>
			</li>
			<li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-star"></i>
					<span className="nav-text">Bootstrap</span>
				</Link>
				<ul >
					<li><Link className={`${path === "ui-accordion" ? "mm-active" : ""}`} to="/ui-accordion">Accordion</Link></li>
					<li><Link className={`${path === "ui-alert" ? "mm-active" : ""}`} to="/ui-alert"> Alert</Link></li>
					<li><Link className={`${path === "ui-badge" ? "mm-active" : ""}`} to="/ui-badge">Badge</Link></li>
					<li><Link className={`${path === "ui-button" ? "mm-active" : ""}`} to="/ui-button">Button</Link></li>
					<li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`} to="/ui-modal">Modal</Link></li>
					<li><Link className={`${path === "ui-button-group" ? "mm-active" : ""}`} to="/ui-button-group">Button Group</Link></li>
					<li><Link className={`${path === "ui-list-group" ? "mm-active" : ""}`} to="/ui-list-group" >List Group</Link></li>
					<li><Link className={`${path === "ui-card" ? "mm-active" : ""}`} to="/ui-card">Cards</Link></li>
					<li><Link className={`${path === "ui-carousel" ? "mm-active" : ""}`} to="/ui-carousel">Carousel</Link></li>
					<li><Link className={`${path === "ui-dropdown" ? "mm-active" : ""}`} to="/ui-dropdown">Dropdown</Link></li>
					<li><Link className={`${path === "ui-popover" ? "mm-active" : ""}`} to="/ui-popover">Popover</Link></li>
					<li><Link className={`${path === "ui-progressbar" ? "mm-active" : ""}`} to="/ui-progressbar">Progressbar</Link></li>
					<li><Link className={`${path === "ui-tab" ? "mm-active" : ""}`} to="/ui-tab">Tab</Link></li>
					<li><Link className={`${path === "ui-typography" ? "mm-active" : ""}`} to="/ui-typography">Typography</Link></li>
					<li><Link className={`${path === "ui-pagination" ? "mm-active" : ""}`} to="/ui-pagination">Pagination</Link></li>
					<li><Link className={`${path === "ui-grid" ? "mm-active" : ""}`} to="/ui-grid">Grid</Link></li>
				</ul>
			</li>
			<li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-heart"></i><span className="nav-text">Plugins</span>
				</Link>
				<ul>
					<li><Link className={`${path === "uc-select2" ? "mm-active" : ""}`} to="/uc-select2">Select 2</Link></li>
					<li><Link className={`${path === "uc-noui-slider" ? "mm-active" : ""}`} to="/uc-noui-slider">Noui Slider</Link></li>
					<li><Link className={`${path === "uc-sweetalert" ? "mm-active" : ""}`} to="/uc-sweetalert">Sweet Alert</Link></li>
					<li><Link className={`${path === "uc-toastr" ? "mm-active" : ""}`} to="/uc-toastr">Toastr</Link></li>
					<li><Link className={`${path === "map-jqvmap" ? "mm-active" : ""}`} to="/map-jqvmap">Jqv Map</Link></li>
					<li><Link className={`${path === "uc-lightgallery" ? "mm-active" : ""}`} to="/uc-lightgallery">Light Gallery</Link></li>
				</ul>
			</li>
			<li className={`${redux.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-archive"></i><span className="nav-text">Redux</span>
				</Link>
                <ul>
                  <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/todo">Todo</Link></li>  
                </ul>
            </li>
			<li className={`${widget.includes(path) ? "mm-active" : ""}`}>
				<Link to="widget-basic" className="ai-icon" >
					<i className="bi bi-gear-wide"></i>
					<span className="nav-text">Widget</span>
				</Link>
			</li>
			<li className={`${forms.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-file-earmark-check"></i>
					<span className="nav-text forms">Forms</span>
				</Link>
				<ul >
					<li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form Elements</Link></li>
					<li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`} to="/form-wizard"> Wizard</Link></li>
					<li>
						<Link className={`${path === "form-editor-summernote" ? "mm-active" : ""}`}to="/form-editor-summernote">
							Summernote
						</Link>
					</li>
					<li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`} to="/form-pickers">Pickers</Link></li>
					<li>
						<Link className={`${path === "form-validation-jquery" ? "mm-active" : ""}`} to="/form-validation-jquery">
							Form Validate
						</Link>
					</li>
				</ul>
			</li>
			<li className={`${table.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" ><i className="bi bi-file-earmark-spreadsheet"></i><span className="nav-text">Table</span></Link>
				<ul>
					<li>
						<Link className={`${ path === "table-filtering" ? "mm-active" : "" }`} to="/table-filtering">
							Table Filtering
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-sorting" ? "mm-active" : "" }`} to="/table-sorting">
							Table Sorting
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-bootstrap-basic" ? "mm-active" : "" }`} to="/table-bootstrap-basic">
							Bootstrap
						</Link>
					</li>
					<li>
						<Link className={`${ path === "table-datatable-basic" ? "mm-active" : ""}`} to="/table-datatable-basic">
							Datatable
						</Link>
					</li>
				</ul>
			</li>
			<li className={`${pages.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="bi bi-file-earmark-break"></i>
					<span className="nav-text">Pages</span>
				</Link>
				<ul>
					<li className={`${error.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >Error</Link>
						<ul>
							<li><Link className={`${ path === "page-error-400" ? "mm-active" : "" }`} to="/page-error-400">Error 400</Link></li>
							<li><Link className={`${ path === "page-error-403" ? "mm-active" : "" }`} to="/page-error-403">Error 403</Link></li>
							<li><Link className={`${ path === "page-error-404" ? "mm-active" : "" }`} to="/page-error-404">Error 404</Link></li>
							<li><Link className={`${ path === "page-error-500" ? "mm-active" : "" }`} to="/page-error-500">Error 500</Link></li>
							<li><Link className={`${ path === "page-error-503" ? "mm-active" : "" }`} to="/page-error-503">Error 503</Link></li>
						</ul>
					</li>
					<li><Link className={`${path === "page-lock-screen" ? "mm-active" : ""}`} to="/page-lock-screen">Lock Screen</Link></li>
				</ul>
			</li> */}
        </MM>
			<div className="copyright">
				<p><strong>Online Bookstore Admin</strong> © {new Date().getFullYear()} All Rights Reserved</p>
				<p className="fs-12">Made with <span className="heart"></span> by Topgrowth</p>
			</div>
      </PerfectScrollbar>
	  {
		showCurrencyForm
		&&
		<CurrencyForm
			showCurrencyForm={showCurrencyForm}
			hideCurrencyForm={() => setShowCurrencyForm(false)}
		/>
	  }
    </div>
  );
};
export default SideBar;