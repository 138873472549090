import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const deleteOrderFunc = createAsyncThunk(
    "delete order function",
    async (api) => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        try {
            const response = await axios.delete(api , config);
            return response.data;
        }
        catch(error) {
            checkErrors(error.response , error.message);
        }
    }
)

const deleteOrderSlicer = createSlice({
    name : "delete order slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(deleteOrderFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(deleteOrderFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(deleteOrderFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default deleteOrderSlicer.reducer;