import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import { getSingleAdminFunc } from "../../../store/custom-actions/admins-actions/GetSingleAdminSlicer";
import { useNavigate, useParams } from "react-router-dom";
import { updateAdminFunc } from "../../../store/custom-actions/admins-actions/UpdateAdminSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";

export default function UpdateAdminMainData() {
    const {adminId} = useParams();
    const contextApi = useContext(ContextApi);
    const singleAdminApi = `${contextApi.dashboard_URL_API}/admins/${adminId}`;
    const {register , handleSubmit  , formState : {errors} , setValue} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loading:getSingleAdminLoading , data:singleAdminData} = useSelector(
        state => state.getSingleAdminSlicer
    )
    const {loading:updateAdminLoading} = useSelector(
        state => state.updateAdminSlicer
    )
    
    useEffect(() => {
        const getSingleAdminData = async () => {
            dispatch(getSingleAdminFunc(singleAdminApi));
        };
        getSingleAdminData();
    }, []);


      useEffect(() => {
        if(singleAdminData) {
            setValue("name" , singleAdminData.name)
            setValue("email" , singleAdminData.email)
            setValue("phone" , singleAdminData.phone)
        }
      }, [singleAdminData]);

    const handleSubmitData = async (data) => {
        if(data) {
            const adminFormData = new FormData();
            adminFormData.append("name" , data.name)
            adminFormData.append("phone" , data.phone)
            adminFormData.append("email" , data.email)
            dispatch(updateAdminFunc(
                {
                    api : singleAdminApi , 
                    data : adminFormData
                }
            ))
            .then (result => {
                if(result.payload.success === true) {
                    // dispatch(getAdminsFunc(adminsApi));
                    navigate("/admins");
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }


    return (
        <>
            <PageTitle motherMenu={"Admins"} activeMenu={"Update Admin"} />
            {
                getSingleAdminLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-wizard ">
                                    <form
                                        className="update-admin-form"
                                        id="update-admin-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitData(data);
                                        })}
                                    >
                                        <div className={'row'}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Name
                                                </label>
                                                {errors.name?.message && (
                                                <p className="text-red-600">
                                                    {errors.name?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("name", {
                                                    required: "Name title is required !"
                                                })}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Email
                                                </label>
                                                {errors.email?.message && (
                                                <p className="text-red-600">
                                                    {errors.email?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("email", {
                                                    required: "Email is required !"
                                                })}
                                                type="email"
                                                className="form-control"
                                                max={10}
                                                />
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="form-group mb-3 col-sm-12">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    Phone
                                                </label>
                                                {errors.phone?.message && (
                                                <p className="text-red-600">
                                                    {errors.phone?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("phone", {
                                                    required: "Phone Number is required !"
                                                })}
                                                type="text"
                                                className="form-control"
                                                min={0}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-end toolbar toolbar-bottom p-2">
                                            <button 
                                            type="submit"
                                            form="update-admin-form"
                                            className={`btn btn-primary sw-btn-next ms-1 ${updateAdminLoading ? "pointer-events-none opacity-50" : ""}`}
                                            >
                                                {
                                                    updateAdminLoading
                                                    ?
                                                    "loading.."
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </div>	
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}